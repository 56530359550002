export class PharmacyIndentDetail {
    pharmacyIndentHeaderId: number;
    pharmacyIndentDetailId: number;
    otIndentHeaderId: number;
    otIndentDetailId: number;
    pharmacyProductId?: number;
    quantity: number;
    status: string;
    productName: string;
    pharmacyIssueHeaderId?: number;
    approvedQuantity?: number;
    total: number;
    totalAmount: number;
    retailName: string;
    createdByName: string;
    createdDate: Date;
    batchNumber: string;
    availableQuantity: number;
    pharmacyRetailStockId: number;
    issuedQuantity: number;
}
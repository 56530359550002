import { Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Counselling, CounsellingPackage, ICounsellingPatientInfo, IResource, IUserAccount, Page } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { Callbacks } from "jquery";
import { finalize, takeUntil } from "rxjs/operators";

@Component({
    selector: "modify-counselling",
    templateUrl: "./modify-counselling.html",
    encapsulation: ViewEncapsulation.None
})
export class ModifyCounsellingComponent {
    @Input() patientId: number;
    @Input() counsellingId: number;
    @Input() active: boolean;
    @Input() isCancelled: boolean;
    @Output() added: EventEmitter<boolean> = new EventEmitter();

    page: Page;
    loadingCounselling: boolean;
    counselling: Counselling;
    counsellingPackages: Array<CounsellingPackage>;
    loadingPatientInfo: boolean;
    patientInfo: ICounsellingPatientInfo;
    loadingLocations: boolean;
    locations: Array<IResource>;
    loadingChargeModuleTemplates: boolean;
    chargeModuleTemplates: Array<IResource>;
    loadingInsuranceCompanies: boolean;
    insuranceCompanies: Array<IResource>;
    loadingTPAs: boolean;
    tpas: Array<IResource>;
    loadingPackageModules: boolean;
    packageModules: Array<IResource>;
    counsellingForm: FormGroup;
    selectedCounsellingPackage: CounsellingPackage;
    isSubmitted: boolean;
    isSubmitting: boolean;
    isSavingDraft: boolean;
    signatureType: string;
    signatureFor: string;
    isSaveSignature: boolean;
    signPad: any;
    signImage: any;
  
    private modalRef: NgbModalRef;
    statusName: string;

    constructor(
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly formBuilder: FormBuilder,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService
    ) {
        this.page = new Page();
        this.counsellingPackages = new Array<CounsellingPackage>();
    }

    ngOnInit(): void {       
        this.setUserAccount();
        this.fetchLocations();
        this.fetchInsuranceCompanies();
        this.fetchTPAs();
    }
    get form() {
        return this.counsellingForm.controls;
    }
    trackByCounsellingPackage(_: number, item: CounsellingPackage): number {
        return item.id;
    }
    onSignatureDone(event: string) {
        this.isSaveSignature = true;
        this.patchSignature(event);
        this.isSaveSignature = false;
        this.onCloseModal();
    }

    onOpenModel(content: TemplateRef<any>, signatureFor: string) {
        this.signatureType = "signature";
        this.signatureFor = signatureFor;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }

    onReceiveDrewSignature(signature: string) {
        this.patchSignature(signature);
    }

    onSaveUploadedSignature(blob: Blob) {
        this.isSaveSignature = true;
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const signature = reader.result as string;
            this.patchSignature(signature);
            this.isSaveSignature = false;
            this.onCloseModal();
        }
    }

    onChangePayType(payType: string): void {
        this.counsellingForm.get("insuranceCompanyId").setValue(null);
        this.counsellingForm.get("tpaId").setValue(null);
        this.counsellingForm.get("patientOrganization").setValue(null);

        this.loadingChargeModuleTemplates = false;
        this.chargeModuleTemplates = [];
        this.counsellingForm.get("chargeModuleTemplateId").setValue(null);

        const locationId = this.counsellingForm.get("locationId").value;
        payType && this.fetchChargeModuleTemplates(locationId, payType);
        this.fetchPackages(locationId, payType)
    }

    onChangeLocation(locationId: number): void {
        this.loadingChargeModuleTemplates = false;
        this.chargeModuleTemplates = [];
        this.counsellingForm.get("chargeModuleTemplateId").setValue(null);

        const payType = this.counsellingForm.get("payType").value;
        locationId && this.fetchChargeModuleTemplates(locationId, payType);
    }

    onChangeChargeModuleTemplate(chargeModuleTemplateId: number): void {
        this.loadingPackageModules = false;
        this.packageModules = [];
        
        const locationId = this.counsellingForm.get("locationId").value;
        const payType = this.counsellingForm.get("payType").value;
        chargeModuleTemplateId && this.fetchPackages(locationId, payType);
    }

    onChangePackageModule(counsellingPackage: CounsellingPackage) {
        counsellingPackage.loadingChargeCategories = false;
        counsellingPackage.chargeCategories = [];
        counsellingPackage.chargeCategory = undefined;
        counsellingPackage.chargeCategoryId = null;
        counsellingPackage.totalQuantity = 0;
        counsellingPackage.subTotal = 0;
        counsellingPackage.discountType = "P";
        counsellingPackage.discountAmount = null;
        counsellingPackage.discountPercentage = null;
        counsellingPackage.packageDiscount = 0;
        counsellingPackage.total = 0;
        if (this.isCancelled == false)
        {
            counsellingPackage.statusName = "R";
        }
        else {
            counsellingPackage.statusName ="C";
        }
        var id = this.counsellingForm.get("chargeModuleTemplateId")?.value ? this.counsellingForm.get("chargeModuleTemplateId")?.value : this.counselling.chargeModuleTemplateId
        const chargeModuleTemplateId = id;

         const locationId = this.counsellingForm.get("locationId")?.value;
        counsellingPackage.packageModuleId && this.fetchChargeCategories(counsellingPackage, chargeModuleTemplateId, locationId);
      
    }

    onChangeChargeCategory(counsellingPackage: CounsellingPackage, chargeCategoryId: number) {
        var chargeCategorys = this.counsellingPackages.filter(m => (m.packageModuleId === counsellingPackage.packageModuleId) && (m.chargeCategoryId === counsellingPackage.chargeCategoryId))
        if (chargeCategorys.length>1) {
            this.notifyService.warning("This package and charge category is already present. Please select a package and charge category that has not been added.")
            return;
        }
        counsellingPackage.chargeCategoryId = chargeCategoryId;
        const chargeCategory = counsellingPackage.chargeCategories?.find(m => m.id === counsellingPackage.chargeCategoryId);
        counsellingPackage.chargeCategory = chargeCategory;
        counsellingPackage.subTotal = chargeCategory?.subTotal || 0;
        counsellingPackage.packageDiscount = chargeCategory?.discount || 0;
        counsellingPackage.discountType = "P";
        counsellingPackage.discountAmount = null;
        counsellingPackage.discountPercentage = null;
        counsellingPackage.discount = 0;
        counsellingPackage.total = chargeCategory?.total || 0;
    }

    onChangeDiscount(counsellingPackage: CounsellingPackage, type: string, value?: any): void {
        value = value ? parseFloat(value) : 0;
        let subTotal = parseFloat('' + (counsellingPackage.subTotal || 0));
        switch (type) {
            case "discountType": {
                counsellingPackage.discountPercentage = null;
                counsellingPackage.discountAmount = null;
                counsellingPackage.discount = 0;
                let total = subTotal - counsellingPackage.packageDiscount;
                counsellingPackage.total = total;
                break;
            }
            case "discountPercentage": {
                let discountPercentage = value || 0;
                let discountAmount = parseFloat((((subTotal * discountPercentage) / 100) || 0).toFixed(2));
                let discount = isNaN(discountAmount) ? 0 : discountAmount;
                let total = subTotal - discount - counsellingPackage.packageDiscount;
                counsellingPackage.discountPercentage = discountPercentage > 0 ? discountPercentage : null;
                counsellingPackage.discountAmount = null;
                counsellingPackage.discount = discount;
                counsellingPackage.total = total > 0 ? total : 0;
                break;
            }
            case "discountAmount": {
                let discountAmount = isNaN(value) || value <= 0 ? null : value;
                let discount = isNaN(discountAmount) ? 0 : discountAmount;
                let total = subTotal - discount - counsellingPackage.packageDiscount;
                counsellingPackage.discountPercentage = null;
                counsellingPackage.discountAmount = discountAmount;
                counsellingPackage.discount = discount;
                counsellingPackage.total = total > 0 ? total : 0;
                break;
            }
        }
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.selectedCounsellingPackage = undefined;
        this.signatureType = undefined;
        this.signatureType = undefined;
        this.isSaveSignature = undefined;
    }

    onAddCounsellingPackage(): void {
        this.counsellingPackages.push(new CounsellingPackage());
    }

    onRemoveCounsellingPackage(counsellingPackage: CounsellingPackage): void {
        const isCounsellingPackageSelected = counsellingPackage.packageModuleId || counsellingPackage.chargeCategoryId;
        if (isCounsellingPackageSelected) {
            this.notifyService.confirm("Are you sure to remove the selected package?", () => {
                const index = this.counsellingPackages.findIndex(m => m.id === counsellingPackage.id);
                this.counsellingPackages.splice(index, 1);
                !this.counsellingPackages?.length && this.counsellingPackages.push(new CounsellingPackage());
            }, () => { });
        } else {
            const index = this.counsellingPackages.findIndex(m => m.id === counsellingPackage.id);
            this.counsellingPackages.splice(index, 1);
            !this.counsellingPackages?.length && this.counsellingPackages.push(new CounsellingPackage());
        }
    }

    onViewCounsellingPackage(counsellingPackage: CounsellingPackage, content: TemplateRef<any>): void {
        this.selectedCounsellingPackage = counsellingPackage;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onSaveAsDraft(): void {
        this.isSavingDraft = true;
        if (this.counsellingForm.invalid) return;
        this.saveCounsellingDetails(true);
    }

    onSubmit(): void {
        this.isSubmitted = true;
        if (this.counsellingForm.invalid) return;
        this.saveCounsellingDetails();
    }

    onCancel(): void {
        this.added.emit(false);
    }

    ngOnDestroy(): void {
        this.page.unsubscribeAll();
        this.onCloseModal();
    }

    private saveCounsellingDetails(isDraft?: boolean): void {
        this.isSubmitted=true
        const counselling = this.counsellingForm.getRawValue();
        this.active = true;
        if (!isDraft && !((counselling.patientSignature && counselling.counsellorSignature) || (counselling.patientBase64Signature && counselling.counsellorBase64Signature))) {
            this.notifyService.warning("The detailed description of the counselling must be signed by the patient and the counselor in order to be published; otherwise, save counselling as a draft.");
            return;
        }

        if (!this.counsellingPackages?.length || this.counsellingPackages?.some(m => !m.packageModuleId || !m.chargeCategoryId)) {
            this.notifyService.warning("To proceed, please select added packages.");
            return;
        }

        if (counselling.counsellingId) counselling.modifiedBy = this.page.userAccount.accountId;
        else counselling.createdBy = this.page.userAccount.accountId;

        counselling.createdByRoleId = this.page.userAccount.roleId;
        counselling.createdByName = this.page.userAccount.fullName;
        counselling.umrNo = this.counselling ? this.counselling.umrNo : this.patientInfo.umrNo;
        counselling.isDraft = isDraft;
        counselling.active = true;
        
        isDraft ? this.isSavingDraft = true : this.isSubmitting = true;
        this.httpService
            .post(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.modify), { counselling: counselling, counsellingPackages: this.counsellingPackages })
            .pipe(finalize(() => { this.isSubmitting = false; this.isSubmitted = false; this.isSavingDraft = false }), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (message: string) => {
                    this.notifyService.success(message);
                    this.added.emit(true);
                },
                error: () => this.notifyService.defaultError()
            });
    }

    private patchSignature(signature: string): void {
        if (this.signatureFor === "Counselling Given To")
            this.counsellingForm.get("patientBase64Signature").setValue(signature);
        else
            this.counsellingForm.get("counsellorBase64Signature").setValue(signature);
    }

    private setUserAccount(): void {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (userAccount: IUserAccount) => {
                    this.page.userAccount = userAccount;
                    this.patientId && this.findPatientInfo();
                    this.counsellingId && this.findCounselling();
                   
                },
                error: () => this.page.userAccount = undefined
            });
    }

    private fetchLocations(): void {
        this.loadingLocations = true;
        this.resourceService.locations(true)
            .pipe(finalize(() => this.loadingLocations = false), takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => this.locations = response);
    }

    private findCounselling(): void {
        this.loadingCounselling = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.find), { counsellingId: this.counsellingId })
            .pipe(finalize(() => this.loadingCounselling = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: { counselling: Counselling, counsellingPackages: Array<CounsellingPackage> }) => {
                    this.counselling = response.counselling;
                    this.buildForm();
                    this.counsellingPackages = response.counsellingPackages || new Array<CounsellingPackage>();
                    this.buildForm(); 
                    if (this.counselling?.counsellingId) {
                        this.fetchChargeModuleTemplates(this.counselling.locationId, this.counselling.payType);
                       this.fetchPackages(this.counselling.locationId, this.counselling.payType);
                    }

                    if (this.counsellingPackages?.length) {                    
                        this.counsellingPackages.forEach((counsellingPackage: CounsellingPackage) => {
                            this.fetchChargeCategories(counsellingPackage, this.counselling.chargeModuleTemplateId, this.counselling.locationId);
                        });
                    }
                    if (this.counselling && this.counselling.active == true) {
                        this.updateForm();

                    } else {
                        this.buildForm();
                    }

                   
                }
            });
    }

    private findPatientInfo(): void {
        this.loadingPatientInfo = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.patientInfo), { patientId: this.patientId || this.counselling.patientId })
            .pipe(finalize(() => this.loadingPatientInfo = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (patientInfo: ICounsellingPatientInfo) => {
                    this.patientInfo = patientInfo;
                    this.buildForm();
                },
                error: () => this.patientInfo = undefined
            });
    }

    private fetchChargeModuleTemplates(locationId: number, payType: string): void {
        const chargeModuleTemplateIdControl = this.counsellingForm.get("chargeModuleTemplateId");
        chargeModuleTemplateIdControl.enable();

        this.loadingChargeModuleTemplates = true;
        this.resourceService.chargeModuleTemplates(locationId, payType)
            .pipe(finalize(() => { this.loadingChargeModuleTemplates = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((chargeModuleTemplates: Array<IResource>) => {
                this.chargeModuleTemplates = chargeModuleTemplates;
            
                    const chargeModuleTemplateId = this.chargeModuleTemplates.find(m => m.status)?.id || 0;
                    if (chargeModuleTemplateId) {
                        chargeModuleTemplateIdControl.setValue(chargeModuleTemplateId);
                        chargeModuleTemplateIdControl.disable();
                        this.onChangeChargeModuleTemplate(chargeModuleTemplateId);
                    }
                
            });
    }

    private fetchInsuranceCompanies(): void {
        this.loadingInsuranceCompanies = true;
        this.resourceService.insuranceCompanies()
            .pipe(finalize(() => { this.loadingInsuranceCompanies = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((insuranceCompanies: Array<IResource>) => this.insuranceCompanies = insuranceCompanies);
    }

    private fetchTPAs(): void {
        this.loadingTPAs = true;
        this.resourceService.tpaCompanies()
            .pipe(finalize(() => { this.loadingTPAs = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((tpas: Array<IResource>) => this.tpas = tpas);
    }

    private fetchPackages(locationId: number, payType: string) {
        this.loadingPackageModules = true;
        this.resourceService.packageModules(locationId, null, payType)
            .pipe(finalize(() => { this.loadingPackageModules = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((packageModules: Array<IResource>) => {
                this.packageModules = packageModules
             });
    }

    private fetchChargeCategories(counsellingPackage: CounsellingPackage, chargeModuleTemplateId: number, locationId: number): void {
        counsellingPackage.loadingChargeCategories = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchChargeCategoriesWithTotal), { packageModuleId: counsellingPackage.packageModuleId, chargeModuleTemplateId, locationId })
            .pipe(finalize(() => counsellingPackage.loadingChargeCategories = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (chargeCategories: Array<IResource>) => {
                    const packageModule = this.packageModules?.find(m => m.id === counsellingPackage.packageModuleId);
                    if (chargeCategories?.length && packageModule) {
                        chargeCategories.forEach((chargeCategory: IResource) => {
                            let subTotal = chargeCategory.value ? parseFloat(chargeCategory.value) : 0, discount = 0, total = subTotal;

                            if (packageModule.discountType === "P") {
                                discount = parseFloat((((subTotal * (packageModule.discountPercentage || 0)) / 100) || 0).toFixed(2));
                                discount = isNaN(discount) ? 0 : discount;
                                total = subTotal - discount;
                                total = total > 0 ? total : 0;
                            } else if (packageModule.discountType === "A") {
                                discount = packageModule.discountAmount || 0;
                                discount = isNaN(discount) ? 0 : discount;
                                total = subTotal - discount;
                                total = total > 0 ? total : 0;
                            }

                            chargeCategory.subTotal = subTotal;
                            chargeCategory.discount = discount;
                            chargeCategory.total = total;
                        });
                    }

                    counsellingPackage.chargeCategories = chargeCategories;
                },
                error: () => counsellingPackage.chargeCategories = []
            });
    }

    private buildForm(): void {
        this.counsellingForm = this.formBuilder.group({
            counsellingId: 0,
            patientId: this.patientId,
            counsellingType: ["E", [Validators.required]],
            payType: [null, [Validators.required]],
            locationId: [this.page.userAccount.locationId, [Validators.required]],
            chargeModuleTemplateId: [null, [Validators.required]],
            insuranceCompanyId: null,
            tpaId: null,
            patientOrganization: null,
            patientBase64Signature: null,
            counsellorBase64Signature: null,
            patientSignature: null,
            counsellorSignature: null
        });
        if (this.patientId) {
            this.counsellingPackages.push(new CounsellingPackage());
        }
    }
    private updateForm(): void {
        this.counsellingForm.patchValue({
            counsellingId: this.counselling.counsellingId,
            patientId: this.counselling.patientId,
            counsellingType: this.counselling.counsellingType,
            payType: this.counselling.payType,
            locationId: this.counselling.locationId ? this.counselling.locationId : this.page.userAccount.locationId,
            chargeModuleTemplateId: this.counselling.chargeModuleTemplateId,
            insuranceCompanyId: this.counselling.insuranceCompanyId,
            tpaId: this.counselling.tpaId,
            patientOrganization: this.counselling.patientOrganization,
            patientSignature: this.counselling?.patientSignature,
            counsellorSignature: this.counselling?.counsellorSignature
        });
        this.onChangePayType(this.counselling.payType);
    }

    private resetCounsellingPackages(): void {
        this.counsellingPackages = [];
        this.counsellingPackages.push(new CounsellingPackage());
    }
}
export class RetailStore {
    retailPharmacyId: number;
    retailName: string;
    allowedUser: string;
    userNames: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByName: string;
    modifiedByName: string;
    allowedAccountId: string;
    active: boolean;
    wareHouseNames: string;
    pharmacyWareHouseIds: string;
    pharmacyWareHouse: string;
    retailWareHouseLinkIdIds: string;
    fullName: string;
    wareHouseName: string;
    locationName: string;
    modifiedByRole: string;
    createdByRole: string;
    status: boolean;
}
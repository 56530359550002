
export class UserAlerts {
    comments: string;
    alertTypeCode: string;
    createdDate: Date;
    userAlertId: number;
    loading = false;
    referenceId?: number;
    acknowledgeBy: number;
    acknowledgeDate: Date;
    acknowledgeByName: string;
    admissionNo: string;
    patientName: string;
    createdByName: string;
    isActionRequired: boolean;
    status: string;
    productName: string;
}

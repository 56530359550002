

<div class="bg-white">
    <div class="card-body">
        <div class="modal-header col-12 primary">
            <h4 class="modal-title ">
                Consent Forms
            </h4>
            <div>
                <button class="btn btn-sm btn-primary" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm btn-primary" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
                <button [useExistingCss]="true"
                        printSectionId="counsellingId"
                        ngxPrint
                        class="btn btn-sm btn-primary">
                    <i class="fe-printer mr-1"></i>Print
                </button>
                <button type="button"
                        class="btn btn-sm btn-primary"
                        data-dismiss="modal"
                        aria-hidden="true"
                        (click)="onCloseModal();">
                    ×
                </button>
            </div>
        </div>
    </div>
    <div class="margin_style bg-white">
        <div class="col-12">
            <select class="form-control" placeholder="Please Select Consent Form" (change)="selectForm($event.target.value)">
                <option></option>
                <option *ngFor="let x of consents" [value]="x.consentFormsId"> {{x.name}}</option>
            </select>
        </div>
    </div>
    <div class="col-12 " id="counsellingId">
        <div class="mx-4 overflow-hidden position-relative">
            <div *ngIf="view != null && htmlView != null ">
                <ng-template *ngIf="view != null">
                    <style>
                        @media print {

                            body, div, td, th, span, p, h5 {
                                color: #000 !important;
                                font-size: 14px !important;
                                line-height: 30px !important;
                            }

                                body strong {
                                    font-size: 20px !important;
                                }

                            .ivf-icsi-summary table td {
                                padding: 2px 5px !important;
                            }
                        }
                    </style>
                </ng-template>
                <div>
                    <div class="justify-content-center d-flex" *ngIf="isPrintLogo">
                        <banner-setting></banner-setting>
                    </div>
                    <div [innerHTML]="htmlView"></div>
                </div>
            </div>
        </div>

    </div>
</div>



<div data-simplebar class="h-100">
    <div class="filters-header">
        <h4>Timeline</h4>
        <a href="javascript:;" (click)="onCloseModel()"><i class="fe-x"></i></a>
    </div>
    <div class="filters-body">
        <div class="card p-2">
            <div class="row p-2" *ngIf="loadingTimeline">
                <div class="col-12">
                    <div class="card-box">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Please wait while loading ...</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row p-1" *ngIf="!loadingTimeline && timeline.length === 0">
                <div class="col-12">
                    <div class="card-box">
                        <div class="alert alert-warning">
                            <i class="mdi mdi-alert-octagon-outline mr-1"></i> No record found.
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!loadingTimeline && timeline.length > 0">
                <div>
                    <div class="track-order-list">
                        <ul class="list-unstyled">
                            <li class="" *ngFor="let item of timeline; let i = index; let last = last;" [ngClass]="{ 'completed' : !last }">
                                <span *ngIf="last" class="active-dot dot"></span>
                                <h5 class="mt-0 mb-1"><span [textContent]="item.status"></span></h5>
                                <p class="mb-0 mt-0">
                                    <span [textContent]="item.comment"></span>
                                </p>
                                <p class="mb-0 mt-0">
                                    <b>By:</b>&nbsp;<span [textContent]="item.commentedByName"></span>&nbsp;at&nbsp;<span class="text-muted">
                                        <small [textContent]="item.createdDate |date: 'dd-MM-yyyy, hh:mm a'"></small>
                                    </span>&nbsp;&nbsp;
                                    <span [textContent]="item.locationName || '----'"></span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { ViewEncapsulation, Component, OnInit, OnDestroy, EventEmitter, Input, Output,} from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { LabStatusModel } from "../../../areas/admin/labs/pages/models";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources} from "@shared/helpers";





@Component({
    templateUrl: "./new-lab-timeline.html",
    selector: "new-lab-timeline",
    encapsulation: ViewEncapsulation.None
})


export class NewLabTimelineWidget implements OnInit, OnDestroy {
    page: Page;
    @Input() newLabBookingHeaderId: number;
    @Output() onClose = new EventEmitter<any>();
    loadingTimeline: boolean;
    timeline: Array<LabStatusModel>;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.timeline = new Array<LabStatusModel>();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchTimeline();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    onCloseModel() {
        this.onClose.emit();
    }
    private fetchTimeline() {
        const request = {
            newLabBookingHeaderId: this.newLabBookingHeaderId,
            newLabBookingDetailId: ""
        };
        this.loadingTimeline = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabTimeline), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTimeline = false))
            .subscribe((response: Array<LabStatusModel>) => {
                this.timeline = response;
            }, () => {
                this.notifyService.warningToast("Unable to fetch lab timeline.");
            });
    }


}

<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while Provider details....</span>
    </div>
</div>
<div class="modal-body" *ngIf="!loading && data.length === 0">
    <div class="d-flex justify-content-center">
        <div class="no-data-container">
            <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
            <h4 class="mt-1">No  Data found.Nothing to show you.</h4>
        </div>
    </div>
</div>

<div class="p-1 font-weight-bold" >
    <table class="table m-0 p-1 overflow-auto scroll table-responsive" *ngIf="totalObservedValues && totalObservedValues.length >0">
        <tbody>
            <tr>
                <td class="w-10px"><h5>ParaMeterName</h5></td>
                <td class="w-10px"></td>
            </tr>

            <tr *ngFor="let item of totalObservedValues;let j=index;">
                <td class="w-10px"><div>{{item.parameterName}}</div></td>
                <td class="w-10px" *ngFor="let value of item.observedValue; let i=index;">
                    <span *ngIf="j==0">{{value.date|date:'dd/MM/yyyy'}}</span>
                    <div>{{value.value}}</div>
                </td>
            </tr>
            <tr>
                <td>Verified By</td>
                <td *ngFor="let item of verifiedDate;">
                    <div class="font-weight-bold">
                        <span class="white-space-nowrap"><i class="mdi mdi-account text-primary mr-1"></i>{{item.fullName}}</span>
                    </div>
                    <div class="white-space-nowrap"><i class="mdi mdi-calendar text-primary mr-1"></i><small class="white-space-nowrap" [textContent]="item.date |date: 'dd-MM-yyyy'"></small></div>
                    <div class="white-space-nowrap"><i class="mdi mdi-clock text-primary mr-1"></i><small [textContent]="item.date |date: 'hh:mm a'"></small></div>

                </td>
            </tr>
        </tbody>
    </table>
</div>





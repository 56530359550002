export class PediatricPatientMeasurementHistoryModel {
    createdDate: Date;
    createdBy: number;
    measurements: string;
    createdByName: string;
    monthAtMeasurement: number;
    realData: any;
    
}


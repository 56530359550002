import { Time } from "@angular/common";
import { PatientQueue } from "./patient-queue.entity";
import { PayTypes } from "./paytype.entity";
export class Appointment {
    locationName: string;
    encryptedAppointmentId: string;
    appointmentId: number;
    providerLocationId: number;
    providerId: number;
    providerName: string;
    providerGender: string;
    providerAge?: number;
    providerThumbnailUrl: string;
    practiceName: string;
    providerLocation: string;
    patientId: number;
    encryptedPatientId: string;
    patientAccountId: number;
    patientName: string;
    patientAge?: number;
    patientGender: string;
    patientMobile: string;
    patientEmail: string;
    patientThumbnailUrl: string;
    patientCountryId: number;
    patientCountryCode: string;
    visitType?: string;
    patientType?: string;
    appointmentNo: string;
    previousAppointmentNo: string;
    previousAdmissionNo: string;
    umrNo: string;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentPreviousDate: Date;
    appointmentPreviousTime: object;
    appointmentTimeString: string;
    previousAppointmentTimeString: string;
    admissionDischargeDate: Date;
    admissionDischargeTime: object;
    previousAdmissionDischargeTimeString: string;
    appointmentNotes: string;
    couponId: number;
    amount: number;
    discount: number;
    currencySymbol: string;
    appCharges: number;
    total: number;
    status: string;
    active: boolean;
    expired: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    totalItems: number;
    encounterType: string;
    isOnline: boolean;
    reason: string;
    departmentId: string;
    departmentName: string;
    patientMaritalStatus: string;
    walletAmount: number;
    chargeType?: string;
    paymentType: string;
    paymentNumber: string;
    isActiveAdmissionExists?: boolean;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    followUpDays: number;
    followUpForAppointmentId: number;
    followUpForAdmissionId: number;
    fullAddress: string;
    aadharNo: string;
    appointmentType: string;
    referredBy: string;
    referredByName: string;
    paymentStatus?: boolean;
    queue: PatientQueue;
    isActive: boolean;
    callType?: number;
    isRequireEmergencyAppointment: boolean;
    isCallInitiating: boolean;
    isPatientWaiting: boolean;
    activeUsers: string;
    waiting: string;
    activeUsersList: Array<ActiveUser>;
    enableMobileAudioCall: boolean;
    isPrescriptionBlink: boolean;
    telemedicineIds: string;
    telemedicineList: Array<number>;
    chargeTypeDb: string;
    visitTypeDb: string;
    visitTypeId: number;
    admissionVisitTypeId: number;
    chargeTypesId: number;
    chargeTypesName: string;
    fatherOrHusband: string;
    payType: number;
    payTypeId: number;
    checkedIn?: Date;
    checkedOut?: Date;
    tat?: number;
    queueNumber?: number;
    queueStatus?: string;
    isCheckingIn?: boolean;
    isAccepting?: boolean;
    isCompleting?: boolean;
    isQueueCalling?: boolean;
    isCancelling?: boolean;
    tokenNumber?: number;
    patientReferredName: string;
    //queue: PatientQueue;
    payTypeName: string;
    idProofName: string;
    idProofValue: string;
    appointmentTypeName: string;
    minutes: number;
    queueEndDate: Date;
    queueStartDate: Date;
    isAdmission: Boolean;
    appointmentTypeId: number;
    isCc: boolean;
    isFullTranscript: boolean;
    locationId: number;
    isPrescription: boolean;
    logFrom: number;
    providerAccountId: number;
    providerCountryId: number;
    statusName: string;
    patientPaymentStatus?: boolean;
    patientRegistrationAmount: number;
    visitorName: string;
    specializationId: number;
    consultationTypeId: number;
    specializationName: string;
    providerAvailabilityId: number;
    isEncounter: boolean;
    patientDateOfBirth?: Date;
    receiptDate?: Date;
    receiptCreatedByName: string;
    receiptNo: string;
    aptRefundAmt: number;
    freeFollowUpDays: number;
    freeFollowUpDaysLimit: number;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    freeVisitsCount: number;
    receiptId: number;
    messageCount: number;
    appointmentEndTime: object;
    isSalucroAppointment: boolean;
    referenceId: number;
    patientSalutation: string;
    patientRelation: string;
    area: string;
    lastConsultedDate: Date;
    lastConsultedTime: string;
    lastConsultedDoctor: string;
    lastPaidAmount: number;
    nextAppointment: string;
    hwcCategory: string;
    followUpValidityDate: Date;
    salutation: string;
    relationName: string;
    encounterName: string;
    isSelected: boolean;
    isEmergency: boolean;
    MapUrl: string;
    transactionCount: number;
    consultationName: string;
    isAppointmentClosed: boolean;
    counsellingId?: number;
    isDraftCounselling: boolean;
    isHealthCard = false;
    addressLine2: string;
    sessionId: number;
    providerEmail: string;
    isEncounterClosed: boolean;
    roomName: string;
    bedId: string;
    admissionTime: string;
    createdByName: string;
    modifiedByName: string;
    ancNo: string;
    signature: string;
    admTime: string;
    husbandName: string;
    caseTypeId: number;
    gynNo: string;
    readyforDischarge: boolean;
    payTypes: Array<PayTypes>;
    isOnlineConsultation: boolean;
    amountInWord: string;
    amountFinal: number;
    aptAmount: number;
    aptDiscount: number;
    aptTotal: number;
    patAmount?: number;
    patDiscount?: number;
    patTotal: number;
    packageModuleDetailId?: number
    admissionPackageId?: number;
    slotCount: number;
    nursingERForm: string;
    emergencyTriage: string;
    doctorName: string;
    isFollowUpCompleted: boolean;
    specializationsList: Array<string>;
    specializationNames: string;
    encounterTypes: string;
    constructor() {
        this.queue = new PatientQueue();
        this.payTypes = new Array<PayTypes>();
        this.specializationsList = new Array<string>();
    }
}

export class ActiveUser {
    name: string;
    roleName: string;
    date: Date;
}

export class AppointmentFollowUpCase {
    patientId: number;
    providerId: number;
    specializationId: number;
    consultationTypeId: number;
    locationId: number;
    appointmentId: number;
    isFollowUpCompleted: boolean;
    isTodaysAppointment: boolean;
    followUpFromDate: string;
    followUpToDate: string;
    providerName: string;
    specializationName: string;
    appointmentDate: Date;
    appointmentTime: Time;
    specializationUnitIds: string;
}
import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount, ProgressData } from "../../models";
import { AppData, HttpService, PrintOptionService } from "../../services";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources, UtilHelper, LinqHelper } from "../../helpers";
import { forkJoin } from "rxjs";
import { LabTransferModel, LabParameterInputModel, LabTemplateHeaderForInput, LabParameterInputHeaderModel, LabComponentHeaderForInput, LabBillDetailModel } from "../../../areas/admin/labs/pages/models";
import { LabReportSignature } from "../../../areas/admin/labs/pages/models/lab-report-signature";
import moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { Setting } from "../../entities";

class Age {
    year: number;
    month: number;
    day: number;
}

@Component({
    templateUrl: "./interim-report.html",
    selector: "interim-report",
    styleUrls: ["./interim-report.css"],
    encapsulation: ViewEncapsulation.None
})

export class InterimReportWidget implements OnInit, OnDestroy {
    @Input() newLabBookingDetailId: number;
    @Input() encryptedNewLabBookingDetailId: string;
    @Output() onClose = new EventEmitter<any>();
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    demographData: Array<LabTransferModel>;
    age: Age;
    parameters: LabParameterInputModel;
    reportSignatures: Array<LabReportSignature>;
    reports: LabParameterInputModel;
    checkExternal: LabBillDetailModel;
    isExternalLoading: boolean;
    isExternalReport: boolean;
    reportData: any;
    reportDataWithoutAuth: any;
    QrCode: string = null;
    printIframe: any;
    blobUrls: any;
    headerUrl: string;
    footerUrl: string;
    nablUrl: string;
    careAxesUrl: string = "assets/images/careaxesLogo.png";

    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly sanitizer: DomSanitizer,
        private readonly printOptionService: PrintOptionService
    ) {
        this.page = new Page();
        this.age = new Age();
        this.checkExternal = new LabBillDetailModel();
        this.demographData = new Array<LabTransferModel>();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.getLabReportImage('Header', 'LabReportHeader');
                    this.getLabReportImage('Footer', 'LabReportFooter');
                    this.fetLabReport();
                } else {
                    this.page.userAccount = undefined;
                    this.fetLabReport(); 
                }
            });
    }

    ngOnDestroy() {
        function cleanUp() {
            try {
                let doc = document.getElementById("myDOC");
                doc.remove();
            } catch (e) {
                console.log(e);
                console.clear();
            }
        }
        cleanUp();
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    onProgress(progressData: ProgressData) {
        this.isExternalLoading = true;
        if (progressData.loaded === progressData.total) {
            this.isExternalLoading = false;
        }
    }

    private checkExternalReport() {
        if (this.checkExternal && UtilHelper.isEmpty(this.checkExternal.reportUrl)) {
            this.loading = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { documentUrl: this.checkExternal.reportUrl })
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    (response: any) => {
                        this.reportData = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                        this.reportDataWithoutAuth = `data:application/pdf;base64, ${response.base64}`;
                        this.loading = false;
                        this.isExternalLoading = true;
                    }
                );
         
        } 
    }

    onPrintPdf() {
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }

    private fetLabReport() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                const request = {
                    newLabBookingDetailId: this.newLabBookingDetailId,
                    encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId,
                    fromTech: "tech"
                };
                this.loading = true;
                const demographApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchDemographData), request);
                const inputParameters = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labParameterForInput), request);
                const doctorSignature = this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchDoctorSignature), { encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId, newLabBookingDetailId: this.newLabBookingDetailId });
                const checkExternal = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabBookingDetail), request);


                forkJoin([demographApi, inputParameters, doctorSignature, checkExternal])
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => this.loading = false))
                    .subscribe((response: Array<any>) => {
                        this.demographData = new Array<LabTransferModel>();
                        this.parameters = new LabParameterInputModel();
                        this.reportSignatures = new Array<LabReportSignature>();
                        if (response[3]) {
                            this.checkExternal = response[3] as LabBillDetailModel;
                            if (this.checkExternal.transferredToExternal) {
                                this.isExternalReport = true;
                            }
                            else {
                                this.isExternalReport = false;
                            }
                            this.checkExternalReport();
                        }
                        if (response[0] && this.isExternalReport == false) {
                            this.demographData = response[0] as Array<LabTransferModel>;
                            if (this.demographData && this.demographData.length > 0 && UtilHelper.isEmpty(this.demographData[0].dateOfBirth)) {
                                let mdob = moment(this.demographData[0].dateOfBirth, "YYYY-MM-DD");
                                let age = moment().diff(mdob, "months");
                                let yrs = (age / 12).toFixed(1);
                                let months = age % 12;
                                let days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                                this.age.day = days;
                                this.age.year = parseInt(yrs, 10);
                                this.age.month = months;
                                let date1 = moment(this.demographData[0].dateOfBirth, "YYYY-MM-DD");
                                let monthsa = moment().diff(date1, 'months');
                                date1.add(monthsa, 'months');
                                let daysa = moment().diff(date1, 'days');
                                this.age.day = daysa;
                            } else {
                                this.age = new Age();
                            } if (this.demographData[0].nablRequired && this.demographData[0].isNablRequired) {
                                this.getLabReportImage('Nabl', 'LabReportNabl');
                            }
                            this.QrCode = `${location.origin}${location.pathname}#/new-lab-reports/${this.newLabBookingDetailId}`;
                        }
                        if (response[1]) {
                            this.parameters = response[1] as LabParameterInputModel;
                            this.reports = new LabParameterInputModel();
                            this.reports = UtilHelper.clone(this.parameters);
                            this.reports.templates = new Array<LabTemplateHeaderForInput>();
                            this.parameters.templates.forEach((template: LabTemplateHeaderForInput) => {
                                let parameterList = new Array<LabParameterInputHeaderModel>();
                                template.components.forEach((component: LabComponentHeaderForInput) => {
                                    component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                        if (param.observedValue != null && param.type == 'Regular') {
                                            let observedValue;
                                            if (param.observedValue &&(param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue.length > 1) {
                                                var value = param.observedValue[0];
                                                observedValue = +param.observedValue.substring(1)
                                                if (value == ">") {
                                                    observedValue = observedValue + 1;
                                                } else if (value == "<") {
                                                    observedValue = observedValue - 1;
                                                }
                                            } else {
                                                observedValue = +param.observedValue;
                                            }
                                            let range = param.selected;
                                            if (range != null) {
                                                let exactRange = range;
                                                if (exactRange.minValue > observedValue) {
                                                    param.showText = "L";
                                                    param.isBold = true;
                                                }
                                                else if (observedValue > +exactRange.maxValue) {
                                                    param.showText = "H";
                                                    param.isBold = true;
                                                }
                                            }

                                            parameterList.push(param);
                                        }
                                        if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                            param.antibioticsCount = param.antibiotics.filter(item => item.observedValue != null).length;
                                        }
                                        //else if (param.type == 'Microbiology') {
                                        //    var tempAntibiotics = new Array<LabParameterInputHeaderModel>();
                                        //    param.antibiotics.forEach((antibiotic)=>{
                                        //        if (antibiotic.observedValue != null && antibiotic.observedValue != ""  && antibiotic.observedValue != undefined) {
                                        //            antibiotic.type = "Microbiology"
                                        //            tempAntibiotics.push(antibiotic);
                                        //        }
                                        //    });
                                        //    if (tempAntibiotics.length > 0) {
                                        //        parameterList.push(param);
                                        //        tempAntibiotics.forEach((item) => {
                                        //            parameterList.push(item);
                                        //        });
                                        //    }
                                        //}
                                    });
                                });
                                template.parameters.forEach((param: LabParameterInputHeaderModel) => {                                 
                                    if (param.observedValue !=null) {                                   
                                        let observedValue;
                                        if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue.length > 1) {
                                            var value = param.observedValue[0];
                                            observedValue = +param.observedValue.substring(1)
                                            if (value == ">") {
                                                observedValue = observedValue + 1;
                                            } else if (value == "<") {
                                                observedValue = observedValue - 1;
                                            }
                                        } else {
                                            observedValue = +param.observedValue;
                                        }
                                        let range = param.selected;
                                        if (range != null) {
                                            let exactRange = range;
                                            if (exactRange.minValue > observedValue) {
                                                param.showText = "L";
                                                param.isBold = true;
                                            }
                                            else if (observedValue > +exactRange.maxValue) {
                                                param.showText = "H";
                                                param.isBold = true;
                                            }
                                            parameterList.push(param);                                       

                                        }
                                    } 
                                    if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                        param.antibioticsCount = param.antibiotics.filter(item => item.observedValue != null).length;
                                    }
                                    //else if (param.type == 'Microbiology') {
                                    //    var tempAntibiotics = new Array<LabParameterInputHeaderModel>();
                                    //    param.antibiotics.forEach((antibiotic) => {
                                    //        if (antibiotic.observedValue != null && antibiotic.observedValue != "" && antibiotic.observedValue != undefined) {
                                    //            antibiotic.type = "Microbiology";
                                    //            tempAntibiotics.push(antibiotic);
                                    //        }
                                    //    });
                                    //    if (tempAntibiotics.length > 0) {
                                    //        parameterList.push(param);
                                    //        tempAntibiotics.forEach((item) => {
                                    //            parameterList.push(item);
                                    //        });
                                    //    }
                                    //}
                                });
                                //let templateToAssign = UtilHelper.clone(template) as LabTemplateHeaderForInput;

                                //templateToAssign.components = new Array<LabComponentHeaderForInput>();
                                //templateToAssign.parameters = new Array<LabParameterInputHeaderModel>();
                                //LinqHelper.sort(parameterList, "displayOrder");
                                //templateToAssign.parameters = parameterList;                           
                                this.reports.templates.push(template);
                            });
                        }
                        if (response[2]) {
                            this.reportSignatures = response[2] as Array<LabReportSignature>;
                        }
                    }, );
            }
        }, 10);
    }

    private getLabReportImage(position: string, type: string) {

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: type, active: true }, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                            if (position == 'Header') {
                                this.headerUrl = response[0].imageUrl;
                            }
                            else if (position == 'Footer') {
                                this.footerUrl = response[0].imageUrl;
                            }
                            else if (position == 'Nabl') {
                                this.nablUrl = response[0].imageUrl;
                            }
                        }
                    }
                },
                () => {
                    this.headerUrl = null;
                    this.footerUrl = null;
                    this.nablUrl = null;
                }
            );
    }

}
import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef, Input } from "@angular/core";
import { Location, DatePipe } from '@angular/common';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, of } from "rxjs";
import { finalize, takeUntil, debounceTime, distinctUntilChanged, switchMap, catchError } from "rxjs/operators";
import { GenericResponse, GenericStatus, Page, IUserAccount } from "../../../../../shared/models";
import { HttpService, ResourceService, AppData, NotifyService } from "../../../../../shared/services";
import { ApiResources, DateHelper, UtilHelper } from "../../../../../shared/helpers";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { Lab, LabStatusType, INewLabAsync, NewLab } from "./helpers/helper";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AdmissionFetchHelper, TimelineCommunicationService } from "../../shared/helper";
import { LabDetail, LabReportParameter } from "../../../../../shared/entities";
import { LabComponentHeaderForInput, LabParameterInputHeaderModel, LabParameterInputModel, LabTemplateHeaderForInput } from "../../../labs/pages/models";

class PatientParameter {
    labDetailId: number;
    testParamResult: string;
    testParameter: string;
    referenceRange: string;
    name: string;
    labBookingDetailId: number;
    typedBy: number;
    labPatientParameterId?: number;
    accountId: number;
    loginRoleId: number;
    labName: string;
    createdByName: string;
    billNumber: string;
    roleId: number;
}
@Component({
    selector: 'progress-report-labs',
    templateUrl: "./labs.html",
    styleUrls: ["./labs.css"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportLabsPage implements OnInit, OnDestroy {
    @Input() inputAdmissionId?: number;
    admissionId: number;
    isAdmission: boolean;
    page: Page;
    modalRef: NgbModalRef;
    loading: boolean;
    labStatusType = LabStatusType;

    labsAsync: Observable<Array<INewLabAsync>>;
    labsInput = new Subject<string>();
    loadingLabsAsync: boolean;

    addLabForm: FormGroup;

    records: Array<NewLab>;
    admission: IAdmissionModel;
    imgOrigin: string;

    //: ILabAsync;
    selectedLab: INewLabAsync;
    labSubmitted: boolean;
    submitting: boolean;

    selectedLabBookingDetail: NewLab;
    loadingDetail: boolean;
    loadingParam: boolean;
    inputParams: Array<PatientParameter>;
    details: Array<LabDetail>;
    reportParam: Array<LabReportParameter>;
    isPrintLogo: boolean = false;
    protected datePipe: DatePipe = new DatePipe('en-US');
    minDate = DateHelper.ngbToday;
    accepting: boolean;
    index: number = null;
    parameters: LabParameterInputModel;
    constructor(
        private readonly httpService: HttpService,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly locationService: Location,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly timelineCommunicationService: TimelineCommunicationService,
        private readonly router: Router,
    ) {
        UtilHelper.addOrRemoveLogoStyle(false, () => {
            this.isPrintLogo = false;
        })
        this.page = new Page();
        this.buildAddLabForm();

        this.imgOrigin = location.origin + location.pathname;
        this.records = new Array<any>();

        this.selectedLabBookingDetail = new NewLab();
        this.details = new Array<LabDetail>();
        this.reportParam = new Array<LabReportParameter>();
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            var id = params["params"]["id"];
                            this.inputAdmissionId = parseInt(window.atob(id));
                            this.admissionId = this.inputAdmissionId || +params["params"]["id"];
                            this.isAdmission = this.inputAdmissionId ? true : params["params"]["type"] === "a";
                            this.loading = true;
                            this.fetchLabsAsync();
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                            });
                            this.fetch();
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {

    }

    // FORM
    get labForm() {
        return this.addLabForm.controls;
    }

    get isAnyChange() {
        return this.records.map(x => x.newLabBookingHeaderId).filter(x => !x).length;
    }

    private buildAddLabForm() {
        this.addLabForm = this.formBuilder.group({
            id: [null, [Validators.required]],
            date: [DateHelper.todayInFormat, [Validators.required]],
            instructions: [null]
        });
    }

    private fetchLabsAsync() {
        this.labsAsync = this.labsInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) =>
                term && term.length >= 2
                    ? (this.loadingLabsAsync = true, this.resourceService.newLabsAsync(term, this.admission.chargeCategoryId).pipe(
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingLabsAsync = false)
                    ))
                    : of([])
            )
        );
    }

    resetAddLabForm = () => {
        this.addLabForm.reset();
        this.addLabForm.patchValue({
            id: null,
            date: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        });
    }

    // MODEL
    onOpenAddModel = (content: TemplateRef<any>) => {
        this.selectedLab = null;
        this.resetAddLabForm();
        this.openModelHelper(content);
    }

    onOpenModel(content: TemplateRef<any>) {
        this.openModelHelper(content);
    }

    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }

    onLabSelect = ($event) => {
        this.selectedLab = $event as INewLabAsync;
        this.addLabForm.patchValue({
            id: this.selectedLab.labMainDetailId
        });
    }

    onAddLab = () => {
        this.labSubmitted = true;
        if (this.addLabForm.invalid) return;

        const record = new NewLab();
        record.labMainDetailId = this.addLabForm.value.id;
        record.testCode = this.selectedLab.testCode;
        record.amount = this.selectedLab.amount;
        record.testName = this.selectedLab.testName;
        record.date = this.addLabForm.value.date;
        record.createdDate = new Date();
        record.createdByName = this.page.userAccount.fullName;
        record.instructions = this.addLabForm.value.instructions;
        this.records.unshift(record);

        this.onCloseModal();
    }

    onBack = () => {
        this.onCloseModal();
        this.locationService.back();
    }

    fetch = () => {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportLab.base, ApiResources.progressReportLab.fetch),
                { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data as Array<NewLab>;
                        //Need to Check and Change the Functionality Here.

                        //data.forEach(x => {
                        //    x.status = x.verifiedBy != null
                        //        ? LabStatusType.Verified : x.isParameterAdded
                        //            // ? LabStatusType.Verified : x.isReportGenerated
                        //            ? LabStatusType.NotVerified : x.isSampleCollected
                        //                ? LabStatusType.Collected
                        //                : LabStatusType.NotCollected;
                        //})
                        this.records = data;
                        this.records.forEach((item) => {
                            this.onFetchInputParameters(item.newLabBookingDetailId);
                        })
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    onOpenViewModel(content: TemplateRef<any>, item: NewLab) {
        this.selectedLab = item;
        this.openModelHelper(content);
    }

    submit = () => {
        this.submitting = true;

        var data = {
            id: this.admissionId.toString(),
            isAdmission: this.isAdmission,
            createdBy: this.page.userAccount.accountId,
            notes: this.addLabForm.value.instructions,
            labServices: [],
            records: [],
            packages: [],
            roleId: this.page.userAccount.roleId,
            roleName: this.page.userAccount.roleName,
            fullName: this.page.userAccount.fullName,
            receiptSaving: false
        }

        this.records.filter(x => !x.newLabBookingHeaderId).forEach((item: NewLab) => {
            data.labServices.push(
                {
                    labMainDetailId: item.labMainDetailId,
                    unit: 1,
                    cost: item.amount || 0,
                    notes: item.instructions,
                    labDate: item.date,
                    chargeCategoryId: this.admission.chargeCategoryId
                });
        });

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.insertLabOnly), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.timelineCommunicationService.set(true);
                        this.fetch();
                    } else {
                        this.notifyService.defaultError();
                    }
                },
                () => {
                    this.notifyService.defaultError();

                }
            );

    }

    onChangeStatusForSingleLab(item: Lab) {
        const request = {
            labBookingHeaderId: item.labBookingHeaderId,
            labBookingDetailId: item.labBookingDetailId,
            accountId: this.page.userAccount.accountId,
            roleId: this.page.userAccount.roleId.toString(),
            loginRoleId: this.page.userAccount.roleId,
            // billNumber: billNumber,
            labName: item.name,
            createdByName: item.createdByName,
            status: "Sample collected",
            updateAll: ""
        };

        this.notifyService.confirm("Is the sample collected for this lab?", () => {
            this.updateStatus(request);
        });
    }

    private updateStatus(request: any) {
        this.httpService.post(ApiResources.getURI(ApiResources.laboratory.base, ApiResources.laboratory.updateLabStatus), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Updated successfully.")
                } else {
                    this.notifyService.warningToast("Error occured in updating record");
                }
                this.fetch();
            });
    }
    //
    onParameterEdit(content: TemplateRef<any>, item: NewLab) {
        this.selectedLabBookingDetail = item;
        this.onOpenModel(content);
        this.inputParams = new Array<PatientParameter>();
        if (this.selectedLabBookingDetail.isReportGenerated || this.selectedLabBookingDetail.status == LabStatusType.NotVerified) {
            this.fetchReportTestParameter();
        } else {
            this.fetchLabDetails();
        }
    }

    private fetchLabDetails() {
        this.loadingDetail = true;
        const request = {
            id: this.selectedLabBookingDetail.labMainDetailId //labHeaderId
        };
        this.httpService
            .get(ApiResources.getURI(ApiResources.laboratory.base, ApiResources.laboratory.fetchDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingDetail = false))
            .subscribe((response: Array<LabDetail>) => {

                this.details = response;
                this.details.forEach((item) => {
                    let input = new PatientParameter();
                    input.labDetailId = item.labDetailId;
                    input.referenceRange = item.referenceRange;
                    input.testParameter = item.testParameter;
                    input.testParamResult = null;
                    input.name = item.name;
                    input.typedBy = this.page.userAccount.accountId;
                    input.labBookingDetailId = this.selectedLabBookingDetail.newLabBookingDetailId;
                    input.labPatientParameterId = 0;
                    input.accountId = this.page.userAccount.accountId;
                    input.loginRoleId = this.page.userAccount.roleId;
                    input.labName = this.selectedLabBookingDetail.testName;
                    input.createdByName = this.page.userAccount.fullName;
                    /* input.billNumber = this.bookingHeader[0].billNumber*/
                    this.inputParams.push(input);
                });
            }, () => {
                this.notifyService.error("Error occured in fetching lab details.");
            });
    }

    fetchReportTestParameter() {
        this.loadingParam = true;
        this.loadingDetail = true;
        const request = {
            labBookingDetailId: this.selectedLabBookingDetail.newLabBookingDetailId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.laboratory.base, ApiResources.laboratory.fetchLabReportParameter), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingParam = this.loadingDetail = false))
            .subscribe((response: Array<LabReportParameter>) => {
                this.reportParam = response;

                this.reportParam.forEach((item) => {
                    let input = new PatientParameter();
                    input.labDetailId = item.labDetailId;
                    input.referenceRange = item.referenceRange;
                    input.testParameter = item.testParameter;
                    input.testParamResult = item.testParamResult;
                    input.name = item.refrenceRangeUnitName;
                    input.typedBy = this.page.userAccount.accountId;
                    input.labPatientParameterId = item.labPatientParameterId;
                    input.labBookingDetailId = this.selectedLabBookingDetail.newLabBookingDetailId;
                    input.loginRoleId = this.page.userAccount.roleId;
                    input.labName = this.selectedLabBookingDetail.testName;
                    input.createdByName = this.page.userAccount.fullName;
                    //input.billNumber = this.bookingHeader[0].billNumber
                    this.inputParams.push(input);
                });
            }, () => {
                this.reportParam = new Array<LabReportParameter>();
                this.notifyService.warning("Unable to fetch report now.Please try again later..!");
            });
    }

    onSubmitLabInput() {
        var record = this.inputParams.filter((item) => { return (item.testParamResult === undefined || item.testParamResult === null || item.testParamResult === "") });
        if (record.length > 0) {
            this.notifyService.warning(`<b>${record[0].testParameter}</b> observed range is required.`);
            return;
        }
        this.submitting = true;

        const request = this.inputParams;
        this.httpService
            .post(ApiResources.getURI(ApiResources.laboratory.base, ApiResources.laboratory.addBulkLabParameters), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitting = false))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Lab parameters added successfully.");
                }
                this.onCloseModal();
                this.fetch();
            }, () => {
                this.notifyService.errorToast("Error occured in uploading lab records.");
            });
    }
    //
    onVerifyingReport(item: NewLab) {
        this.selectedLabBookingDetail = item;
        this.notifyService.confirm("Do you really want to verify this report?", () => {
            this.submitting = true;
            const request = {
                labBookingDetailId: this.selectedLabBookingDetail.newLabBookingDetailId,
                accountId: this.page.userAccount.accountId,
                loginRoleId: this.page.userAccount.roleId,
                verifiedByName: this.page.userAccount.fullName,
                labName: this.selectedLabBookingDetail.testName,
                // billNumber: this.bookingHeader[0].billNumber
            };

            this.httpService.post(ApiResources.getURI(ApiResources.laboratory.base, ApiResources.laboratory.verifyLabReport), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.submitting = false))
                .subscribe((response: number) => {
                    this.onCloseModal();
                    if (response > 0) {
                        this.notifyService.successToast("Report verified successfully.");
                    }
                    if (response === 0) {
                        this.notifyService.warningToast("Unable to verify the report.");
                    }
                    this.selectedLabBookingDetail = new NewLab();
                    this.fetch();
                }, () => {
                    this.notifyService.defaultError();
                });
        }, () => {
            this.selectedLabBookingDetail = new NewLab();
        });
    }
    //
    onViewLabReport(content: TemplateRef<any>, item: NewLab) {
        this.selectedLabBookingDetail = item;
        this.onOpenModel(content);
    }


    onChangePrintType(type: boolean) {
        UtilHelper.addOrRemoveLogoStyle(type, () => {
            this.isPrintLogo = type;
        })
    }

    onCloseExtraPopup() {
        //this.showLabReport = false;
        this.selectedLabBookingDetail = new NewLab();
        try {
            this.modalRef.close();
            this.modalRef = undefined;

        } catch (e) {
        }
    }


    onAcceptRequest(item: NewLab, index: number) {
        this.index = index;
        var data = {
            id: item.admissionId.toString(),
            isAdmission: this.isAdmission,
            createdBy: this.page.userAccount.accountId,
            labServices: [],
            roleId: this.page.userAccount.roleId,
            roleName: this.page.userAccount.roleName,
            fullName: this.page.userAccount.fullName,
            Records: [],
            SurgeryServices: [],
            ScanServices: [],
            packages: [],
            AdmissionId: item.admissionId,
            receiptSaving: false,
            patientLabHeaderId: item.patientLabHeaderId
        }
        data.labServices.push({
            labMainDetailId: item.labMainDetailId,
            unit: 1,
            cost: item.amount || 0,
            notes: item.instructions,
            labDate: item.date,
            chargeCategoryId: this.admission.chargeCategoryId,
            patientLabDetailId: item.patientLabDetailId
        });

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.insertLabOnly), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.accepting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        item.isAccepted = false;
                        this.fetch();
                    } else {
                        this.notifyService.defaultError();
                        
                    }
                },
                () => {
                    this.notifyService.defaultError();

                }
            );
    }

    onClickSampleCollect(records: NewLab) {
        var newlLabBookingHeaderId = window.btoa(records.newLabBookingHeaderId.toString());
        var encryptedPatientId = window.btoa(records.patientId.toString());
        this.router.navigate(['app/labs/sample-collection/', newlLabBookingHeaderId, encryptedPatientId ])
    }
    private onFetchInputParameters(newLabBookingDetailId: number) {
        const request = {
            newLabBookingDetailId: newLabBookingDetailId,
            fromTech: "tech"
        };
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labParameterForInput), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: LabParameterInputModel) => {
                this.parameters = response;
                this.parameters.templates.forEach((template: LabTemplateHeaderForInput) => {
                    template.components.forEach((component: LabComponentHeaderForInput) => {
                        component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                            let observedValue = +param.observedValue;
                            let range = param.selected;
                            if (range != null) {
                                let exactRange = range;
                                if (exactRange.minValue > observedValue || observedValue > +exactRange.maxValue) {
                                    this.records = this.records.map((item) => {
                                        if (item.newLabBookingDetailId == newLabBookingDetailId) {
                                            item.isAbnormal = true;
                                        }
                                        return item;
                                    })
                                }
                            }
                        });
                    });
                    template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                        let observedValue = +param.observedValue;
                        let range = param.selected;
                        if (range != null) {
                            let exactRange = range;
                            if (exactRange.minValue > observedValue || observedValue > +exactRange.maxValue) {
                                this.records = this.records.map((item) => {
                                    if (item.newLabBookingDetailId == newLabBookingDetailId) {
                                        item.isAbnormal = true;
                                    }
                                    return item;
                                })
                            }

                        }
                    });
                });
            });
    }

}

import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, AppData } from "@shared/services";
import { ImageReports, Appointment, PayTypes } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { takeUntil } from "rxjs/operators";
import * as numberToWords from 'number-to-words';
import * as converter from "number-to-words";



@Component({
    templateUrl: "./appointment-invoice.html",
    selector: "appointment-invoice",
    encapsulation: ViewEncapsulation.None
})
export class AppointmentInvoiceWidget implements OnInit, OnDestroy {
    // @Input() labBookingHeaderId: number;
    @Input() appointmentId: number;
     //@Output() onClose = new EventEmitter<any>();
    @Input() duplicateInvoiceId: boolean;
    @Input() isCancelInvoice: boolean;


    page: Page;
    loading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    @Input() isPrintLogo: boolean;
    appointments: Array<Appointment>;
    selectedAppointment: Appointment;
    noReceipt = false;
    currentDate: Date;

    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,

    ) {
        this.page = new Page();
        this.appointments = new Array<Appointment>();
        this.selectedAppointment = new Appointment();
        this.currentDate = new Date();

    }

    private fetch() {
        this.loading = true;
        const request = {
            appointmentId: this.appointmentId
        };
        if (this.isCancelInvoice) {
            request["status"] = 'Cancel';
        }

        this.httpService
            .post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetchForInvoice), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: Appointment) => {
                    this.selectedAppointment = new Appointment();
                    this.selectedAppointment.payTypes = new Array<PayTypes>();
                    this.selectedAppointment = response;
                   this.selectedAppointment.payTypes = response.payTypes;
                    let amountInWords = numberToWords.toWords(this.selectedAppointment.amount);
                    this.selectedAppointment["amountInWord"] = ' (Received Rupees ' +  amountInWords + ' rupees only.)';
                    if (response == null) {
                        this.noReceipt = true;
                    }
                    var cloneAppointment = JSON.parse(JSON.stringify(this.selectedAppointment));
                    this.selectedAppointment.fullAddress = [cloneAppointment.addressLine2, cloneAppointment.streetAddress, cloneAppointment.city, cloneAppointment.state, cloneAppointment.zipcode].filter(Boolean).join(", ");
                    this.selectedAppointment.aadharNo = [cloneAppointment.aadharNo].filter(Boolean).join(",")

                    if (response.receiptNo) {
                        response.receiptNo = String(response.receiptId).padStart(6, '0');

                    } 
                },
                () => {
                    this.selectedAppointment = new Appointment();
                }
            );
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
        this.fetch();
        this.printOptionService.get((is) => { this.isPrintLogo = is; });
        this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
        this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
            });

    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}

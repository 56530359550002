<div class="modal-header">
  <h4 class="modal-title"><i class="mdi mdi-receipt mr-1"></i>View Receipt</h4>
  <div class="d-inline-block d-flex-end" >
      <button class="btn btn-small" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
          <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
      </button>
      <button class="btn btn-small" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
          <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
      </button>
      <button [useExistingCss]="true"
              printSectionId="receipt-section"
              ngxPrint
              class="btn btn-light btn-small mr-1 d-print-none">
          <i class="fe-printer mr-1"></i>Print
      </button>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();"><i class="fe-x"></i></button>
  </div>
</div>
<div class="modal-body" id="receipt-section">
    <div class="card">
        <div class="card-body p-0">
            <div class="card-box m-0 d-flex-center" *ngIf="loading">
                <span class="spinner-grow spinner-border-sm mr-2"></span> Please wait while loading receipt
            </div>
            <div class="card-box m-0" *ngIf="record && !loading">
                <div class="row">
                    <div class="col-lg-12" *ngIf="isPrintLogo">
                        <!--<img src="assets/images/header.jpg" alt="Hospital Logo" class="img-responsive w-100">-->
                        <!--<div *ngIf="isPrintLogo" class="col-12" width="100%">
                            <img [src]="bannerBasics != null ? bannerBasics.imagePath : myImgUrl" [hidden]="loading" alt="Careaxes" style="width: 100%" />
                        </div>-->
                        <banner-setting> </banner-setting>
                    </div>
                    <div class="col-lg-12">
                        <h4 class="box-title mt-3">RECEIPT</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive table-responsive-lg text-black">
                            <table class="table table-sm table-borderless table-centered text-black">
                                <tbody>
                                    <tr>
                                        <th>Receipt No</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="'RN-' + record.receiptNo"></span>
                                        </td>
                                        <th>
                                            <span *ngIf="isAdmission">Admission No</span>
                                            <span *ngIf="!isAdmission">Appointment No</span>
                                        </th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.admissionNo"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Receipt Date</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="record.createdDate | date:'dd-MM-yyyy, h:mm:ss a'"></span>
                                        </td>
                                        <th>
                                            <span *ngIf="isAdmission">Admission Date</span>
                                            <span *ngIf="!isAdmission">Appointment Date</span>
                                        </th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span *ngIf="isAdmission" [textContent]="admission.admissionDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                            <span *ngIf="!isAdmission">
                                                <span [textContent]="admission.admissionDate | date: 'dd-MM-yyyy'"></span>
                                                <span [textContent]="' ' + admission.timeString"></span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Patient Name</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span>
                                                <span class="text-uppercase" [textContent]="admission.fullName + ', '"></span>
                                                <span [textContent]="(admission.gender === 'F' ? 'Female' : 'Male') + ', '"></span>
                                                <span [textContent]="admission.age + 'yrs'"></span>
                                            </span>
                                        </td>
                                        <th>Doctor Name</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span class="text-uppercase" [textContent]="admission.providerName"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>UMR No</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.umrNo"></span>
                                        </td>
                                        <th>Department</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.departmentName"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td>
                                            <span class="mr-1">:</span>
                                            <span [textContent]="admission.fullAddress"></span>
                                        </td>
                                    </tr>
                                   
                                </tbody>                              
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="table-responsive table-responsive-lg">
                            <table class="table table-bordered table-sm text-black border-dark" *ngIf="record">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>DESCRIPTION</th>
                                        <th>UNIT PRICE 
                                        <div *ngIf="waterMarkText != undefined">
                                             <div class="justify-content-center d-flex" >
                                             <p id="bg-bgStyle" class=" backgroundOfwaterMark">Duplicate </p>
                                             </div>
                                        </div>
                                        </th>
                                        <th>AMOUNT</th>
                                    </tr>                                  
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div *ngIf="record.receiptTypeId == receiptType.Refund">
                                                <div *ngIf="record.isRefunded">Refund</div>
                                            </div>
                                            <div *ngIf="record.receiptTypeId == receiptType.Cash">
                                                <div *ngIf="record.isAdvance">Advance Paid</div>
                                                <div *ngIf="!record.isAdvance">Paid</div>
                                            </div>
                                        </td>
                                        <td [textContent]="record.cost | currency:'INR'"></td>
                                        <td [textContent]="record.cost | currency:'INR'"></td>
                                    </tr>

                                </tbody>
                                <thead>
                                    <tr>
                                        <th colspan="3" class="text-right">TOTAL</th>
                                        <th [textContent]="record.cost | currency:'INR'"></th>
                                    </tr>
                                    
                                </thead>
                            </table>
                            <table class="table table-bordered table-sm text-black" *ngIf="record.subRecords && record.subRecords.length">

                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>NAME</th>
                                        <th>UNIT PRICE</th>
                                        <th>UNIT</th>
                                        <th>AMOUNT</th>
                                        <th>GST</th>
                                        <th>DISCOUNT</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of record.subRecords; let i = index; ">
                                        <td [textContent]="i + 1"></td>
                                        <td class="text-uppercase" [textContent]="item.name"></td>
                                        <td [textContent]="item.cost | currency:'INR'"></td>
                                        <td [textContent]="item.unit"></td>
                                        <td [textContent]="item.totalAmount | currency:'INR'"></td>
                                        <td>
                                            <span class="mr-1" [textContent]="item.gstAmount | currency:'INR'"></span>(<span [textContent]="item.gstPercentage"></span>%)
                                        </td>
                                        <td>
                                            <span class="mr-1" [textContent]="item.discountAmount | currency:'INR'"></span><span *ngIf="item.discountAmount">(<span [textContent]="item.discountPercentage"></span>%)</span>
                                        </td>
                                        <td [textContent]="item.netAmount | currency:'INR'"></td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th colspan="7" class="text-right">GROSS TOTAL</th>
                                        <th [textContent]="record.costModel.total | currency:'INR'"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="7" class="text-right"><span class="small mr-1">(included in GROSS TOTAL)</span>GST</th>
                                        <th [textContent]="record.costModel.gst | currency:'INR'"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="7" class="text-right">DISCOUNT</th>
                                        <th [textContent]="record.costModel.discount | currency:'INR'"></th>
                                    </tr>
                                    <tr>
                                        <th colspan="7" class="text-right">NET AMOUNT</th>
                                        <th [textContent]="record.costModel.netAmount | currency:'INR'"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-6 text-black">
                        <div class="mb-1">
                            <span class="font-weight-bold">Created By: </span>
                            <span [textContent]="record.createdByName"></span>
                        </div>
                        <div>
                            <span class="font-weight-bold">Created Date: </span>
                            <span [textContent]="record.createdDate | date:'dd-MM-yyyy, h:mm:ss a'"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content top">
    <div class="">
        <div>
            <div class="col-12 p-0">
                <ol class="breadcrumb m-0 justify-content-end p-1">
                    <li class="breadcrumb-item"><a href="javascript:;" routerLink="/app/patients">Patients</a></li>
                    <li class="breadcrumb-item active">Documents ssdsadasdasda</li>
                </ol>
                <div class="m-0 page-title-box">
                    <div class="page-title-right">
                        <button *ngIf="!loading && documents[0] && documents[0].patientDocumentId" type="button" class="btn btn-primary btn-sm" (click)="onOpenModal(templateUploadDocument, modalType.Add)"><i class="fe-plus mr-1"></i> Upload Document</button>
                    </div>
                    <h4 class="page-title">Health Documents</h4>
                </div>
            </div>
        </div>
        <div  *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading Documents ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!documents[0] || !documents[0].patientDocumentId)">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <h4 class="title">Oops! No Documents found .</h4>
                    <p class="sub-title">There is no data to show you right now.</p>
                    <button type="button" class="btn btn-primary btn-sm" (click)="onOpenModal(templateUploadDocument, modalType.Add)"><i class="fe-plus mr-1"></i> Upload Document</button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!loading && documents[0] && documents[0].patientDocumentId">
            <div class="row" *ngIf="!loading && documents[0] && documents[0].patientDocumentId">
                <div class="modifying-content" *ngIf="modifying">
                    <p>Please wait while {{modifyingContent}}.</p>
                </div>
                <div class="col-lg-4 mb-3" *ngFor="let item of documents; trackBy:trackBy.document">
                    <div class="document-container">
                        <div class="document-thumbnail">
                            <a href="javascript:;" (click)="onOpenModal(templateViewDocument, modalType.View, item)"><img src="assets/images/documentImage1.png" [alt]="item.documentName" /></a>
                        </div>
                        <div class="document-body">
                            <h4><a href="javascript:;" (click)="onOpenModal(templateViewDocument, modalType.View, item)" class="text-dark" [textContent]="item.documentName"></a></h4>
                            <h6 [textContent]="item.documentType"></h6>
                            <p class="text-lowercase"><span [textContent]="item.size | formatBytes"></span> - <span class="text-primary" [textContent]="item.uploadedDate | utcToLocal"></span></p>
                        </div>
                        <div class="document-actions">
                            <a href="javascript:;" placement="left" ngbTooltip="Edit" (click)="onOpenModal(templateEditDocument, modalType.Edit, item)"><i class="mdi mdi-file-document-        -outline"></i></a>
                            <a href="javascript:;" placement="left" ngbTooltip="Delete" (click)="onDelete(item)"><i class="mdi mdi-trash-can-outline"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <nav class="d-flex align-items-center justify-content-between p-2" *ngIf="pagination.totalPages > 1">
                <p class="mb-0 font-13">
                    <span class="text-dark">Page <span [textContent]="pagination.currentPage"></span> of <span [textContent]="pagination.totalPages"></span></span>
                    <span class="ml-1">
                        <span>(Showing <span [textContent]="pagination.currentItems - documents.length + 1"></span> - <span [textContent]="pagination.currentItems"></span> of <span [textContent]="pagination.totalItems"></span> Documents)</span>
                    </span>
                </p>
                <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="false" [ellipses]="false" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                    <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                    <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                </ngb-pagination>
            </nav>
        </ng-container>
    </div>
</div>
<ng-template #templateUploadDocument>
    <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Upload Documents</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row appointment-form bg-pattern bg-light mb-3">
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label class="mb-1">Document Name <code>*</code></label>
                        <input type="text" autofocus formControlName="documentName" maxlength="100" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.documentName.errors) }" placeholder="Enter document name" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label class="mb-1">Document Type <code>*</code></label>
                        <select class="form-control custom-select" formControlName="documentType" [ngClass]="{ 'is-invalid': submitted && form.documentType.errors }">
                            <option [ngValue]="null" *ngIf="!loadingCategories">Select</option>
                            <option selected *ngIf="loadingCategories">Loading...</option>
                            <option *ngFor="let item of categories" [textContent]="item.value" [ngValue]="item.value"></option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label class="mb-1">Description</label>
                        <textarea rows="1" cols="20" formControlName="description" block maxlength="250" autocomplete="nope" class="form-control" placeholder="Enter document description"></textarea>
                    </div>
                </div>
            </div>
            <file-dropper [expandable]="true"
                          [maxFiles]="maxFiles"
                          [maxFileSize]="10000"
                          [accept]="'application/pdf, image/jpeg, image/pjpeg, image/png, image/gif, video/mp4'"
                          (onSelectEmitter)="onFileSelect($event)">
            </file-dropper>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateEditDocument>
    <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Edit Documents</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row appointment-form bg-pattern bg-light mb-3">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Document Name <code>*</code></label>
                        <input type="text" autofocus formControlName="documentName" maxlength="100" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.documentName.errors) }" placeholder="Enter document name" />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Document Type <code>*</code></label>
                        <select class="form-control custom-select" formControlName="documentType" [ngClass]="{ 'is-invalid': submitted && form.documentType.errors }">
                            <option [ngValue]="null" *ngIf="!loadingCategories">Select</option>
                            <option selected *ngIf="loadingCategories">Loading...</option>
                            <option *ngFor="let item of categories" [textContent]="item.value" [ngValue]="item.value"></option>
                        </select>
                    </div>
                    <div class="alert alert-info" *ngIf="uploadForm.value && uploadForm.value.patientDocumentId != null &&  uploadForm.value.patientDocumentId > 0">
                        <i class="mdi mdi-information mr-1"></i> Type can not be updated.
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group mb-0">
                        <label class="mb-1">Description</label>
                        <textarea rows="1" cols="20" formControlName="description" block maxlength="250" autocomplete="nope" class="form-control" placeholder="Enter document description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateViewDocument>
    <div class="modal-header">
        <h4 class="modal-title"><span [textContent]="document.documentName"></span><small class="ml-2" [textContent]="document.documentType"></small></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <a href="javascript:;" (click)="onPrevious()" class="previous-document" placement="left" ngbTooltip="Previous document" *ngIf="showPrevious"><i class="mdi mdi-arrow-left-bold"></i></a>
        <a href="javascript:;" (click)="onNext()" class="next-document" placement="left" ngbTooltip="Next document" *ngIf="showNext"><i class="mdi mdi-arrow-right-bold"></i></a>
        <div class="p-3" *ngIf="loadingDocument">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Document ...</span>
            </div>
        </div>
        <div *ngIf="!loadingDocument && documentError">
            <div class="position-relative mh-300">
                <no-data title="Document"></no-data>
            </div>
        </div>
        <ng-container *ngIf="document && document.isImage">
            <img [src]="document.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="document.documentName" />
        </ng-container>
        <ng-container *ngIf="document.isVideo">
            <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                <source [src]="document.formedUrl" (load)="onDocumentLoad()" [type]="document.contentType">
            </video>
        </ng-container>
        <ng-container *ngIf="document && !document.isImage && !document.isVideo">
            <pdf-viewer [src]="document.formedUrl['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgress($event)"
                        (error)="onDocumentError()"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </ng-container>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICubicle } from '../entities';
import { IResource } from '../models';

@Injectable()
export class SettingService {

    private source = new Subject<boolean>();
    get: Observable<boolean> = this.source.asObservable();
    set(isRefresh: boolean) {
        this.source.next(isRefresh);
    }

    cubicles: Array<ICubicle> = new Array<ICubicle>();
    selectedCubicleId?: number = null;
    selectedConsultantDoctor?: IResource;
}

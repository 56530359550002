export class IssueStockHeader {
    totalItems: number;
    pharmacyIssuedStockHeaderId: number;
    inventoryIssuedStockHeaderId: number;
    issuedBy: number;
    handOverTo?: number;
    retailWareHouseLinkId?: number;
    pharmacyDepartmentId?: number;
    receivedBy?: number;
    issueHeaderId: number;
    issuedDate: Date;
    indentCreatedDate: Date;
    receivedDate?: Date;
    comment: string;
    issueNumber: string;
    indentRaisedByName: string;
    handOverName: string;
    raisedFromLocation: string;
    raisedForRetail: string;
    approvedFromWareHouse: string;
    reasonForRequirement: string;
    raisedForDepartment: string;
    receivedByName: string;
    issuedByName: string;
    productName: string;
    requestedQuantity: string;
    approvedQuantity: string;
    batchNumber: string;
    genericName: string;
    expiryDate: string;
    wareHouseName: string;
    issueDate: string;
    products: Array<IssueStockDetail>;
    category: string;
    quantityIn: number;
    status: string;

    constructor() {
        this.products = new Array<IssueStockDetail>();
    }

}

export class IssueStockDetail {
    pharmacyIssuedStockDetailId: number;
    pharmacyIssuedStockHeaderId: number;
    inventoryIssuedStockDetailId: number;
    inventoryIssuedStockHeaderId: number;
    pharmacyProductId: number;
    inventoryProductId: number;
    taxId: number;
    quantityIn: number;
    pharmacyStockId: number;
    inventoryStockId: number;
    barcode: string;
    batchNumber: string;
    productName: string;
    genericName: string;
    category: string;
    taxPercentage: string;
    expiryDate?: Date;
    purchaseRate: number;
    mrp: number;
}

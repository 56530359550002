import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewEncapsulation, TemplateRef } from "@angular/core";
import { Admission, PharmacyIndentHeader, PharmacyIndentDetail, PharmacyInPatientReturnModel, ImageReports } from "@shared/entities";
import { Page, IUserAccount, IResource, GenericResponse } from "@shared/models";
import { AppData, ResourceService, HttpService, NotifyService, BannerService } from "@shared/services";
import { ApiResources, DateHelper, UtilHelper } from "@shared/helpers";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FinalBillBasicModel } from "@admin/services/models/final-bill-basic.model";
import { takeUntil, finalize, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { NgbTypeaheadSelectItemEvent, NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";


@Component({
    templateUrl: "./pharmacy-indent.html",
    selector: "pharmacy-indent",
    encapsulation: ViewEncapsulation.None
})

export class PharmacyIndentWidget implements OnInit, OnDestroy {
    @Input() admission: Admission;
    @Output() response = new EventEmitter<number>();
    @Output() closeModal = new EventEmitter<any>();
    @Input() totalNetAmount: number;
    today = DateHelper.ngbToday;
    isAdmission: boolean;
    page: Page;

    searching = false;
    searchFailed = false;

    modalRef: NgbModalRef;
    indentForm: FormGroup;
    submitted: boolean;
    submitting: boolean;
    // resultsType: string;
    loading: boolean;
    selectedProduct: Array<PharmacyIndentHeader>;
    selectedAppointment: any;
    headers: Array<PharmacyIndentHeader>;
    // pagination: Pagination;
    loadingDetail: boolean;
    isFinalBillLoading: boolean;
    details: Array<PharmacyIndentDetail>;
    finalBillBasics: FinalBillBasicModel;
    category: Array<IResource>;
    loadingCategory: boolean;
    isPrintLogo = false;

    loadingdata: boolean;
    productdetail: Array<PharmacyIndentHeader>;

    selectedDetail: PharmacyIndentHeader;
    returnForm: FormGroup;
    returnModel: Array<PharmacyInPatientReturnModel>;
    returnLoading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";

    constructor(
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly bannerService: BannerService,
    ) {
        this.page = new Page();
        if (this.admission === undefined || this.admission === null) {
            this.isAdmission = true;
        }
        this.headers = new Array<PharmacyIndentHeader>();
        this.details = new Array<PharmacyIndentDetail>();

        this.productdetail = new Array<PharmacyIndentHeader>();
        this.selectedDetail = new PharmacyIndentHeader();
        this.returnModel = new Array<PharmacyInPatientReturnModel>();
    }

    onCloseModal = () => {
        this.closeModal.emit();
    }

    private fetchCategory() {
        this.loadingCategory = true;
        this.resourceService.pharmacyCategories()
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingCategory = false))
            .subscribe((response: Array<IResource>) => {
                this.category = response;
            });
    }

    getFinalBillBasics = () => {
        this.isFinalBillLoading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.getBasics), { id: this.admission.admissionId.toString() })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.isFinalBillLoading = false; }))
            .subscribe(
                (response: GenericResponse) => {
                    this.finalBillBasics = response.data as FinalBillBasicModel;
                },
                () => {
                    this.finalBillBasics = new FinalBillBasicModel();
                }
            );
    }

    private buildForm() {
        this.indentForm = this.formBuilder.group({
            admissionId: this.admission.admissionId,
            departmentId: this.admission.departmentId,
            pharmacyProductId: [null],
            productName: [null, [Validators.required]],
            quantity: [null, [Validators.required]],
            requiredDate: [{ value: null, disabled: true }, [Validators.required]],
            reason: [null],
            createdBy: this.page.userAccount.accountId,
            categoryId: [null]
        });
        this.indentForm.patchValue({
            requiredDate: DateHelper.todayInFormat
        });
    }

    get form() {
        return this.indentForm.controls;
    }

    onSelectProduct(event: NgbTypeaheadSelectItemEvent) {
        if (event.item) {
            var data = event.item as IResource;
            this.indentForm.patchValue({
                pharmacyProductId: data.id,
                productName: data.value,
                categoryId: data.optionalId
            });
        } else {
            event.preventDefault();
        }
    }

    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }

    onGetPrescription(content: TemplateRef<any>, product?: PharmacyIndentHeader) {
        this.selectedAppointment = this.admission;
        if (product) {
            this.selectedProduct = new Array<PharmacyIndentHeader>();
            this.selectedProduct.push(product);
        }
        this.onOpenModal(content, "lg");
    }

    onOpenModal(content: TemplateRef<any>, size: string) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal invoice-modal effect-scale"
        });
    }

    onCloseTicketModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.submitting = undefined;
        this.submitted = undefined;
        this.selectedProduct = undefined;
        this.isPrintLogo = false;
    }

    onSubmitIndentRequest() {
        if (this.totalNetAmount > 10000000) {
            this.notifyService.info("For One Admission Limit One Crore Only");
            return;
        }
        if (this.finalBillBasics.finalAmount !== 0) {
            this.notifyService.infoToast("Pharmacy indent can not be made once the final bill is generated, please cancel the Final Bill and try again.");
            return;
        }

        this.submitted = true;
        if (!this.indentForm.valid) {
            return;
        }
        this.submitting = true;

        const request = this.indentForm.getRawValue();
        request["quantity"] = parseInt(request["quantity"]);
        request["createdBy"] = this.page.userAccount.accountId;
        request["createdByName"] = this.page.userAccount.fullName;
        request["roleId"] = this.page.userAccount.roleId;
        request["roleName"] = this.page.userAccount.roleName;
        
        if ((request["productName"] as IResource).value) {
            request["productName"] = request["productName"].value;
        }
        request["admissionId"] = this.admission.admissionId;
        request["doctorUnitMasterId"] = this.admission.doctorUnitMasterId;

        this.httpService
            .post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.addIndent),
                request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitted = this.submitting = false;
            }))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Indent raised successfully.");
                }
                this.buildForm();
                // this.onFetchHeaders();
                this.onFetchProductDetails();
            });
    }

    onFetchProductDetails() {
        this.loadingdata = true;
        const request = {
            admissionId: this.admission.admissionId,
        };

        request["requestDate"] = null;
        this.httpService.post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.fetchIndent), request) ///fetchng
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingdata = false;
            }))
            .subscribe({
                next: (response: Array<PharmacyIndentHeader>) => {
                    this.productdetail = response.filter(x => x.status != 'C');

                },
                error:() => {
                this.productdetail = Array<PharmacyIndentHeader>();
            }
            });
    }

    onDeleteRequest(item: PharmacyIndentHeader) {
        this.notifyService.delete(() => {
            const request = {
                pharmacyIndentHeaderId: item.pharmacyIndentHeaderId,
                admissionId: this.admission.admissionId,
                createdBy: this.page.userAccount.accountId,
                roleId: this.page.userAccount.roleId,
                createdByName: this.page.userAccount.fullName,
                quantity: item.quantity,
                PharmacyProductId: item.pharmacyProductId,
                pharmacyIndentDetailId: item.pharmacyIndentDetailId,
            }
            this.httpService.post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.deleteIndent), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe((response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast("Cancelled successfully");
                    } else {
                        this.notifyService.warning("Unable to Cancel indent request.");
                    }
                    //this.onFetchHeaders();
                    this.onFetchProductDetails();
                }, () => {
                    this.notifyService.defaultError();
                });
        })
    }

    onOpenReturnForm(content: TemplateRef<any>, product: PharmacyIndentHeader) {
        this.selectedDetail = product;
        this.buildReturnForm();
        this.fetchReturn();
        this.onOpenModal(content, "lg");
    }

    private buildReturnForm() {
        let returnQty = 0;
        if (UtilHelper.isEmpty(this.selectedDetail.returnQuantity)) {
            returnQty = this.selectedDetail.returnQuantity;
        }

        this.returnForm = this.formBuilder.group({
            pharmacyInPatientReturnId: 0,
            pharmacyIssueDetailId: this.selectedDetail.pharmacyIssueDetailId,
            available: this.selectedDetail.issueQuantity - returnQty,
            returnQuantity: [null, [Validators.required, Validators.min(1), Validators.max(this.selectedDetail.issueQuantity - returnQty)]],
            createdBy: this.page.userAccount.accountId
        });
    }

    get formReturn() {
        return this.returnForm.controls;
    }

    onSubmitReturn() {
        this.submitted = true;
        if (this.finalBillBasics.finalAmount !== 0) {
            this.notifyService.infoToast("Pharmacy return can not be made once the final bill is generated, please cancel the Final Bill and try again.");
            return;
        }

        if (this.returnForm.invalid) {
            return;
        }

        const request = this.returnForm.getRawValue();

        this.submitting = true;

        this.httpService.post(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.addReturnRequest), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitted = this.submitting = false;
            }))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Return request raised successfully.");
                }
                this.onCloseTicketModal();
                this.onFetchProductDetails();
            });
    }

    private fetchReturn() {
        const request = {
            id: this.selectedDetail.pharmacyIssueDetailId
        };

        this.returnLoading = true;

        this.httpService.get(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.fetchReturn), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.returnLoading = false;
            }))
            .subscribe((response: Array<PharmacyInPatientReturnModel>) => {
                this.returnModel = response;
            }, (error: HttpErrorResponse) => {
                this.returnModel = new Array<PharmacyInPatientReturnModel>();
                let err = UtilHelper.handleError(error);
                if (err) {
                    this.notifyService.errorToast(err);
                }
                else {
                    this.notifyService.defaultErrorToast();
                }
            });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchCategory();
                    this.getFinalBillBasics();
                    this.buildForm();
                    this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
                    //this.onFetchHeaders();
                    this.onFetchProductDetails();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    formatMatches = (item: IResource) => item.value || "";
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap((term: string) =>
                term.length < 1 ? of([]) : this.resourceService
                    .pharmacyProducts(this.indentForm.value.categoryId, term)
                    .pipe(
                        tap(() => this.searchFailed = false),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        })
                    )
            ),
            tap(() => this.searching = false)
        );

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { takeUntil, finalize } from "rxjs/operators";
import { IUserAccount, Page, GenericResponse, GenericStatus, Pagination } from "@shared/models";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { ApiResources, UtilHelper } from "../../../../../shared/helpers";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IShiftsModel, IShiftSlotModel } from "../../../services/models/shifts.model";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    templateUrl: "./shifts.html",
    styleUrls: ['../../nurse-module.css']
})
export class ShiftsPage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    pagination: Pagination;
    loadingShiftSlots: boolean;
    submitting: boolean;
    submitted: boolean;
    slotSubmitting: boolean;
    slotSubmitted: boolean;
    selectedRecord: IShiftsModel;
    shiftSlots: Array<IShiftSlotModel>;
    shifts: Array<IShiftsModel>
    modalRef: NgbModalRef;
    slotModalRef: NgbModalRef;

    addShiftForm: FormGroup;
    addShiftSlotForm: FormGroup;

    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly notifyService: NotifyService,
        private readonly modalService: NgbModal
    ) {
        this.loading = true;
        this.page = new Page();
        this.initPagination();
        this.loading = true;
        this.loadingShiftSlots = true;
        this.shiftSlots = new Array<IShiftSlotModel>();
        this.shifts = new Array<IShiftsModel>();

        this.buildaddShiftForm();
        this.buildaddShiftSlotForm();
    }

    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 10;
    }

    private buildaddShiftForm() {
        this.addShiftForm = this.formBuilder.group({
            shiftId: [0],
            name: [null, [Validators.required]],
            slots: this.formBuilder.array([])
        });
    }

    private buildaddShiftSlotForm() {
        this.addShiftSlotForm = this.formBuilder.group({
            startTime: [null, [Validators.required]],
            endTime: [null, [Validators.required]]
        });
    }

    private fetchShifts() {
        const data = {
            pageIndex: this.pagination.pageIndex,
            pageSize: this.pagination.pageSize
        };

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.fetchShift), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    var records = response.data as Array<IShiftsModel>;
                    records.forEach(x => {
                        x.slots.forEach(y => {
                            y.startDate = new Date(0, 0, 0, y.startTime.hours, y.startTime.minutes, 0);
                            y.endDate = new Date(0, 0, 0, y.endTime.hours, y.endTime.minutes, 0);
                        })
                    });

                    this.shifts = records;
                    UtilHelper.applyPagination(this.shifts, this.pagination);
                },
                () => {
                    this.shifts = new Array<IShiftsModel>();
                }
            );
    }

    get forms() {
        return {
            addShift: this.addShiftForm ? this.addShiftForm.controls : {},
            addShiftSlot: this.addShiftSlotForm ? this.addShiftSlotForm.controls : {}
        }
    }

    onOpenModel(content: TemplateRef<any>, shift?: IShiftsModel) {
        if (shift) {
            this.selectedRecord = shift;
            this.openWithCondition(content);
            this.addShiftForm.patchValue({
                shiftId: shift.shiftId,
                name: shift.name
            });
            this.fetchShiftSlots(() => { }, shift);
        } else {
            this.addShiftForm.reset();
            this.selectedRecord = null;
            this.openWithCondition(content);
        }
    }

    onOpenSlotModel(content: TemplateRef<any>) {
        this.addShiftSlotForm.reset();
        this.openSlotModalHelper(content);
    }

    openModalHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal extra-large-modal effect-scale"
        });
    }

    openSlotModalHelper = (content: TemplateRef<any>) => {
        this.slotModalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "md",
            windowClass: "custom-modal effect-scale"
        });
    }

    openWithCondition = (content: TemplateRef<any>) => {
        this.loadingShiftSlots = true;
        this.fetchShiftSlots(() => {
            this.openModalHelper(content);
        });
    }

    fetchShiftSlots = (callback: Function, shift: IShiftsModel = null) => {
        this.httpService
            .get<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.fetchShiftSlot))
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingShiftSlots = false))
            .subscribe(
                (response: GenericResponse) => {
                    var records = response.data as Array<IShiftSlotModel>;

                    var recordForm = this.addShiftForm.get("slots") as FormArray;
                    recordForm.clear();

                    records.forEach(x => {
                        x.startDate = new Date(0, 0, 0, x.startTime.hours, x.startTime.minutes, 0);
                        x.endDate = new Date(0, 0, 0, x.endTime.hours, x.endTime.minutes, 0);

                        var is = shift ? shift.slots.map(y => y.shiftSlotId).includes(x.shiftSlotId) : null;
                        recordForm.push(
                            this.formBuilder.group({
                                shiftSlotId: [x.shiftSlotId],
                                value: [shift ? is : null]
                            }));
                    });

                    this.shiftSlots = records;
                    callback();
                },
                () => {
                    this.shiftSlots = new Array<IShiftSlotModel>();
                    callback();
                }
            );
    }

    onModifyStatus(item: IShiftsModel, status: boolean) {
        this.notifyService.confirm(`You want to ${status ? 'Activate' : 'Deactivate'} this Shift?`, () => {
            const request = {
                createdBy: this.page.userAccount.accountId,
                active: status,
                shiftId: item.shiftId,
                modifiedBy: this.page.userAccount.accountId,
                modifiedByName: this.page.userAccount.fullName,
                name: item.name,
                logFrom: this.page.userAccount.roleId

            };

            this.httpService.post(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.modifyStatus), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loading = false }))
                .subscribe((response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast(`${status ? 'Activated' : 'Deactivated'} successfully.`);
                        this.fetchShifts();
                    }
                    this.fetchShifts();
                }, (error: HttpErrorResponse) => {
                    this.notifyService.errorToast(error.error);
                });
        });
    }

    onNextPage() {
        this.fetchShifts();
    }

    onAddShift() {
        this.submitted = true;
        if (!this.addShiftForm.valid) {
            return;
        }

        var shiftSlotIds = this.addShiftForm.value.slots.filter(x => x.value).map(x => x.shiftSlotId);
        if(shiftSlotIds.length > 1) {
            this.notifyService.warning("Please select only 1 slot");
            return;
        }
        this.submitting = true;
        var data = {
            name: this.addShiftForm.value.name,
            shiftId: this.addShiftForm.value.shiftId,
            slots: shiftSlotIds,
            createdBy: this.page.userAccount.accountId,
            createdByName: this.page.userAccount.fullName,
            logFrom: this.page.userAccount.roleId
        }

        if (this.selectedRecord) {
            data["modifiedBy"] = this.page.userAccount.accountId;
            data["modifiedByName"] = this.page.userAccount.fullName;
        }

        const subUrl = this.selectedRecord
            ? ApiResources.nurseModule.updateShift
            : ApiResources.nurseModule.addShift;

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, subUrl), data)
            .pipe(finalize(() => { this.submitting = false; }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    this.onCloseInsertModal();
                    this.notifyService.success(`Shift has been ${data["shiftId"] ? 'Updated' : 'Added'} successfully`, () => {
                        this.selectedRecord = null;
                        this.fetchShifts();
                    });

                } if (response.status === GenericStatus[GenericStatus.Error]) {
                    this.notifyService.defaultError();
                }

                if (response.status === GenericStatus[GenericStatus.Info]) {
                    this.notifyService.warning(`Shift with name '${data["name"]}' is already exists, please enter different name and try again`);
                }

                if (response.status === GenericStatus[GenericStatus.Warning]) {
                    this.notifyService.warning(response.message);
                }
            },
                () => {
                    this.notifyService.defaultError();
                });

    }

    onAddShiftSlot() {
        this.slotSubmitted = true;
        if (!this.addShiftSlotForm.valid) {
            return;
        }

        this.slotSubmitting = true;

        var startTimeTokens = this.addShiftSlotForm.value.startTime.split(":");
        var endTimeTokens = this.addShiftSlotForm.value.endTime.split(":");
        var data = {
            startHour: +startTimeTokens[0],
            startMinute: +startTimeTokens[1],
            endHour: +endTimeTokens[0],
            endMinute: +endTimeTokens[1],
            createdBy: this.page.userAccount.accountId,
            createdByName: this.page.userAccount.fullName,
            logFrom: this.page.userAccount.roleId
        }

        this.httpService
            .post(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.addShiftSlot), data)
            .pipe(finalize(() => { this.slotSubmitting = false; }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.onCloseSlotModal();
                    this.fetchShiftSlots(() => { });
                } else {
                    this.notifyService.defaultError();
                }
            },
                () => {
                    this.notifyService.defaultError();
                });
    }

    onSelectAppointmentSlot(item: any) {
        return item
    }

    onCloseInsertModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {

        }

        this.addShiftForm.reset();
        this.submitting = undefined;
        this.submitted = undefined;
        this.selectedRecord = null;
    }

    onCloseSlotModal() {
        try {
            this.slotModalRef.close();
            this.slotModalRef = undefined;
        } catch (e) {

        }
        this.addShiftSlotForm.reset();
        this.slotSubmitting = undefined;
        this.slotSubmitted = undefined;
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchShifts();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}
export class LabSampleHandlerModel {
    newLabBookingHeaderId: number;
    patientId?: number;
    doctorId?: number;
    employeeId?: number;
    locationId: number;
    overallTotalAmount: number;
    overallDiscount?: number;
    overallNetAmount: number;
    overallDiscountPercentage?: number;
    payTypeId: number;
    createdBy: number;
    modifiedBy?: number;
    age?: number;
    requisitionNumber: string;
    type: string;
    paymentNumber: string;
    createdByName: string;
    modifiedByName: string;
    patientName: string;
    mobile: string;
    doctorName: string;
    patientThumbnailUrl: string;
    employeeName: string;
    payTypeName: string;
    locationName: string;
    umrNo: string;
    gender: string;
    createdDate: Date;
    modifiedDate?: Date;
    newLabBookingDetailId: number;
    labMainDetailId: number;
    chargeCategoryId: number;
    labBookingStatusId: number;
    discountPercentage?: number;
    discountAmount?: number;
    totalAmount: number;
    netAmount: number;
    status: string;
    testName: string;
    testCode: string;
    departmentName: string;
    chargeCategoryName: string;
    typeName: string;
    totalItems: number;
    labSampleCollectionId?: number;
    sampleCollectedBy?: number;
    barcodeGeneratedBy: number;
    collectionDate?: Date;
    barcodeDate?: Date;
    isBarcodeGenerated?: boolean;
    sampleCollectedByName: string;
    barcodeGeneratedByName: string;
    isLoading: boolean;
    isSelected: boolean;
    isDisabled: boolean;
    rowColor: string;
    comment: string;
    sampleUsage: string;
    roleId: number;
    consentFormRequired: boolean;
    labVacutainerName: string;
    emergency: boolean;
    bookingType: string;
    labSampleCollectionDetailId?: number;
    subSampleCollectedBy?: number;
    subCollectionDate?: Date;
    subBarcodeDate?: Date;
    subIsBarcodeGenerated: boolean;
    subBarcodeGeneratedBy?: number;
    sampleName: string;
    subSampleCollectedByName: string;
    subBarcodeGeneratedByName: string;
    subSampleCollectionLocation: string;
    noOfSamplesCollectText: string;
    noOfSamplesCollect: number;
    subStatus: string;

    prevLocationId?: number;
    prevLocationName: string;
    consentFormUrl: string;
    uncollectComment: string = null;
    homeSample?: boolean;
    agencyName?: string;
    agencyAmount?: number;
    messageCount: number;
    subSampleCollectedByRole?: string;
    sampleCollectedByRole?: string;
    sampleID: string;
    quickReport: boolean;
}
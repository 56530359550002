export class Supplier {
    supplierId: number;
    address: string;
    name: string;
    mobile: string;
    pinCode: string;
    city: string;
    supplierOf: string;
    email: string;
    createdDate: Date;
    totalItems?: number;
    supplierEncryptId?: string;
    domain?: string;
    link: string;
    bankName: string;
    ifscCode: string;
    accountNumber: string;
    accountHolderName: string;
    emails: Array<any>;
    supplierIds: string;
    isLoading: boolean;
    userId?: number;
    dueDays?: number;
    contactPersonName: string;
    drugLicense: string;
    gstNo: string;
    pan: string;
    deliverDays?: number;
    createdByRole: string;
    modifiedByRole: string;
    supplierProductHeaderId?: number;
    amount: number;
    supplierName: string;
    createdByName: string;
    supplierDocumentId: number;

    patientDocumentId: number;
    patientId: number;
    documentName: string;
    documentType: string;
    contentType: string;
    size: number;
    description?: string;
    documentUrl: string;
    thumbnailUrl: boolean;
    uploadedDate: Date;
    active: boolean;
    isImage: boolean;
    isVideo: boolean;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    maxFileSize: string;
    base64: string;
    formedUrl: any;
    uploadedBy: number;
    supplierCreditNoteId: number;
    creditNumber: number;
}
<ng-template #templateTransferedLabs>
    <div class="modal-header d-flex justify-content-between">
        <div>
            <h4 class="modal-title">Transferred Labs</h4>
        </div>
        <div>
            <div class="btn-group d-print-none right5">
                <button class="btn btn-sm"
                        [class.btn-light]="!isPrintLogo"
                        [class.btn-outline-light]="isPrintLogo"
                        type="button"
                        (click)="onChangePrintType(false)">
                    <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
                </button>
                <button class="btn btn-sm"
                        [class.btn-light]="isPrintLogo"
                        [class.btn-outline-light]="!isPrintLogo"
                        type="button"
                        (click)="onChangePrintType(true)">
                    <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
                </button>
            </div>
            <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
    </div>
    <div class="modal-body" Id="invoiceId">
        <div class="row p-2" *ngIf="loadingTransfer">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading ...</span>
                </div>
            </div>
        </div>
        <div class="row p-1" *ngIf="!loadingTransfer && !transferedSeparateRecords">
            <div class="col-12 mh-300">
                <no-data [applied]="false" [title]="'Transferred Labs'"></no-data>
            </div>
        </div>
        <div class="col-12" *ngIf="!loadingTransfer && transferedSeparateRecords">
            <div class="row" *ngIf="isPrintLogo">
                <banner-setting class="w-100"></banner-setting>
            </div>
            <div class="card-box borderbottom" *ngIf="transferedSeparateRecords.length > 0">
                <table width="100%" class="heading2 mb-2">
                    <tr>
                        <td class="headingText transferredHead" colspan="4" >
                            Transferred Labs
                        </td>
                    </tr>
                    <tr>
                        <th class="locationstyle">
                            <span *ngIf="transferedSeparateRecords[0].transferNumber">Transfer Number</span>
                        </th>
                        <td class="transferNumberStyle">
                            <span class="mr5">: </span>
                            <span *ngIf="transferedSeparateRecords[0].transferNumber" [textContent]="transferedSeparateRecords[0].transferNumber"></span>
                        </td>
                        <th class="locationstyle">
                            <span *ngIf="transferedSeparateRecords[0].fromLocation">From Location</span>
                        </th>
                        <td class="locationpc">
                            <span class="mr5">: </span>
                            <span *ngIf="transferedSeparateRecords[0].fromLocation" [textContent]="transferedSeparateRecords[0].fromLocation"></span>
                        </td>
                    </tr>
                    <tr>
                        <th class="locationstyle">
                            Transferred Location
                        </th>
                        <td class="locationpc">
                            <span class="mr5">: </span>
                            <span [textContent]="transferedSeparateRecords[0].transferedToLocation || '-----'"></span>
                        </td>

                        <th class="locationstyle">
                            <span *ngIf="transferedSeparateRecords[0].transferByName">Transferred By</span>
                        </th>
                        <td class="locationpc">
                            <span class="mr5">: </span>
                            <span *ngIf="transferedSeparateRecords[0].transferByName" [textContent]="transferedSeparateRecords[0].transferByName"></span>
                        </td>

                    </tr>
                    <tr>
                        <th class="w-200p locationstyle">
                            Transferred Date&Time
                        </th>
                        <td class="locationpc">
                            <span class="mr5">: </span>
                            <span [textContent]="transferedSeparateRecords[0].transferedDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                        </td>
                        <th class="locationstyle">
                            <span *ngIf="transferedSeparateRecords[0].transferredTemperature">Transferred Temperature</span>
                        </th>
                        <td class="locationpc">
                            <span class="mr5">: </span>
                            <span>{{transferedSeparateRecords[0].transferredTemperature || '--'}}°C</span>
                        </td>
                    </tr>
                    <tr>
                        <th class="w-200p comments">
                            Transferred Comments
                        </th>
                        <td class="locationpc" colspan="3">
                            <div class="d-flex">
                                <span class="mr5">: </span>
                                <div class="break-all" [textContent]="transferedSeparateRecords[0].transferredTemperatureComments || '-----'"></div>
                            </div>

                        </td>
                    </tr>
                </table>
            </div>
            <div class="overflow-auto">
                <table class="table table-sm">
                    <tbody>
                        <ng-template ngFor let-record [ngForOf]="records" let-i="index">
                            <tr class="grey">
                                <td class="bordertopstyle text-center"><h5 class="mb-0 mt-0" [textContent]="record?.departmentName"></h5></td>
                            </tr>
                            <tr>
                                <td class="bordertopstyle" colspan="5">
                                    <div class="table table-sm overflow-auto">
                                        <div>
                                            <div class="d-flex border-bottom border-info">
                                                <div class="col-1">S.no</div>
                                                <div class="col-1 min-width100px">Req No</div>
                                                <div class="col-3">TestDetail</div>
                                                <div class="col-2">PatientDetails</div>
                                                <div class="col-2">BookedDate</div>
                                                <div class="col-3">CollectedBy</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="d-flex" *ngFor="let item of record.transferedSeparateRecords;index as i;">
                                                <div class="col-1 py-1">
                                                    <h6 class="mb-0 mt-0" [textContent]="i+1"></h6>
                                                </div>
                                                <div class="col-1 min-width100px py-1">
                                                    <h5 class="mb-0 mt-0" [textContent]="item.requisitionNumber"></h5>
                                                </div>
                                                <div class="col-3 py-1">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.testName"></h5>
                                                    <small class="d-block" [textContent]="item.testCode || '--'"></small>
                                                </div>
                                                <div class="col-2 py-1">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.patientName"></h5>
                                                    <small class="d-block" [textContent]="item.umrNo || '--'"></small>
                                                    <small class="d-block" [textContent]="item.mobile || '--'"></small>
                                                </div>
                                                <div class="col-2 py-1">
                                                    <span [textContent]="item.bookedDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                                </div>
                                                <div class="col-3 py-1">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.sampleCollectedByName"></h5>
                                                    <small class="d-block" [textContent]="item.collectionDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>



<style>

    .invalid-input {
        border: 2px solid red;
        border-color: red;
    }

    .valid-input {
        border-color: green;
    }

    .btn-primary1 {
        color: #fff;
        background-color: #0a67eb;
        border-color: #6658dd;
    }

    .red-asterisk {
        
        color: red;
        display: inline-block;
        margin-right: 3px;
        
    }

    canvas {
        touch-action: none;
    }
</style>
<div class="m-auto col-12 col-md-10 col-lg-8 col-xl-6">
    <div>
        <div class="col-12">
            <h2>OPD Registration Form</h2>
            <img src="assets/images/opdLogo2.png" class="height-100px">
        </div>
    </div>

    <div>
        <div class="col-12 pb-3">
            <h4><mark class="bg-warning">  PATIENT DETAILS  </mark></h4>
            <hr class="border-dark">
            <form [formGroup]="opd" (ngSubmit)="submit(opd)">

                <div class="row">
                    <div class="col-sm-2">
                        <label for="locationName">Location<span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">

                        <div class="form-group mb-3">
                            <div class="input-group password-group mb-0">
                                <select class="form-control" tabindex="0" formControlName="locationId" [ngClass]="{'invalid-input': (submitted && opd.get('locationId')?.errors)}">
                                    <option selected hidden [ngValue]="null">
                                        Select Location
                                    </option>
                                    <option *ngFor="let location of locations" [ngValue]="location.id" [textContent]="location.name"></option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-2">
                        <label for="patientName">Patient Name <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" class="form-control my-1" placeholder="Enter Patient Name" formControlName="patientName" [ngClass]="{ 'is-invalid': submitted && opd.get('patientName').errors }">
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-2">
                        <label>Date of Birth <span class="red-asterisk">*</span> </label>
                    </div>
                    <div class="col-sm-4">
                        <input type="date" class="form-control my-1" formControlName="dateOfBirth" (change)="calculateAge($event,'age')" [ngClass]="{'invalid-input': (submitted && opd.get('dateOfBirth')?.errors)}">
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-2">
                        <label>Age</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control my-1 bg-soft-dark" formControlName="age" [readonly]="true" [ngClass]="{'invalid-input': (submitted && opd.get('age')?.errors)}">
                    </div>

                </div>


                <div class="row">
                    <div class="col-sm-2">
                        <label> Age at Marriage </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" numberOnly class="form-control my-1" maxlength="3"
                               numbersOnly formControlName="ageAtMarriage">
                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label>Martial Status <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">

                        <select class="form-control custom-select"
                                formControlName="maritalStatus"
                                [ngClass]="{ 'is-invalid':  (submitted && opd.get('maritalStatus')?.errors) }">
                            <option [ngValue]="null">SELECT</option>
                            <option *ngFor="let maritalStatus of maritalStatues" [ngValue]="maritalStatus.value">
                                {{maritalStatus.name}}
                            </option>
                        </select>


                    </div>

                </div>



                <div class="row my-1">
                    <div class="col-sm-2">
                        <label>Gender <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">

                        <select class="form-control custom-select text-uppercase" formControlName="gender" [ngClass]="{'invalid-input': (submitted && opd.get('gender')?.errors)}">
                            <option [ngValue]="null">Select</option>
                            <option *ngFor="let gender of genders" [ngValue]="gender.value">
                                {{gender.name}}
                            </option>
                        </select>

                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="religion">Religion <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">

                        <select class="form-control custom-select text-uppercase"
                                formControlName="religion"
                                [ngClass]="{'invalid-input': (submitted && opd.get('religion')?.errors)}">
                            <option [ngValue]="null">Select</option>
                            <option *ngFor="let religion of religions" [textContent]="religion.name" [ngValue]="religion.name">
                            </option>
                        </select>

                    </div>

                </div>

                <div class="row my-1">
                    <div class="col-sm-2">
                        <label>Qualification <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">

                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="education"
                                   [loading]="loadingEducation"
                                   bindLabel="value"
                                   bindValue="value"
                                   autocomplete="nope"
                                   placeholder="-Select-"
                                   formControlName="qualification"
                                   [ngClass]="{'invalid-input': (submitted && opd.get('qualification')?.errors)}">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    Nothing found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>

                    </div>

                </div>

                <div class="row my-2">

                    <div class="col-sm-2">
                        <label>Occupation <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">
                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="occupationList"
                                   [loading]="loadingOccupation"
                                   bindLabel="value"
                                   bindValue="value"
                                   autocomplete="nope"
                                   placeholder="-Select-"
                                   formControlName="occupation"
                                   [ngClass]="{'invalid-input': (submitted && opd.get('occupation')?.errors)}">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    Nothing found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>

                    </div>
                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label>Nationality <span class="red-asterisk">*</span></label>
                    </div>
                    <div class="col-sm-4">
                        <select class="form-control custom-select text-uppercase"
                                formControlName="nationality"
                                [ngClass]="{'invalid-input': (submitted && opd.get('nationality')?.errors)}">
                            <option [ngValue]="null">Select</option>
                            <option *ngFor="let nationality of nationalities" [textContent]="nationality.name" [ngValue]="nationality.name">
                            </option>
                        </select>
                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="phoneNumber" class=" my-1">Phone Number<span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">

                        <input type="text"
                               class="form-control"
                               formControlName="phoneNumber"
                               [ngClass]="{'invalid-input': (submitted && opd.get('phoneNumber')?.errors)}"
                               maxlength="10"
                               numbersOnly
                               block
                               autocomplete="nope"
                               placeholder="Enter mobile number" />
                    </div>

                </div>

                <div class="row my-1">
                    <div class="col-sm-2">
                        <label for="alternateMobileNumber">Alternate Mobile Number</label>
                    </div>

                    <div class="col-sm-4">

                        <input type="text"
                               [ngClass]="{'invalid-input': (submitted && opd.get('alternateMobileNumber')?.errors)}"
                               class="form-control"
                               formControlName="alternateMobileNumber"
                               maxlength="10"
                               numbersOnly
                               block
                               autocomplete="nope"
                               placeholder="Enter alternate mobile number" />

                    </div>

                </div>

                <div class="row my-1">
                    <div class="col-sm-2">
                        <label for="patientEmail">Patient Email <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" name="patientEmail" placeholder="Enter Patient Email" autocomplete="off" formControlName="patientEmail" class="form-control" [ngClass]="{'invalid-input': (submitted && opd.get('patientEmail')?.errors)}">

                    </div>

                </div>


                <br>
                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="patient photo">PATIENT PHOTO <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">
                        <input type="file" accept="image/*" name="patientPhoto" (change)="onFileSelected($event,'patientPhoto')" [ngClass]="{'invalid-input': (submitted && opd.get('profileImageUrl').value=='')}">
                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="idProof">ID Proof <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">


                        <select name="idProof" id="idProof" class="form-control" formControlName="idProof" [ngClass]="{'invalid-input': (submitted && opd.get('idProof')?.errors)}">
                            <option value="null">select</option>
                            <option value="Aadhar Card">Aadhar Card</option>
                            <option value="Voter Id">Voter Id</option>
                            <option value="Voter Id">PAN Card</option>

                        </select>
                    </div>

                </div>
                <br>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="patientIdProofPhoto">Upload Id <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">
                        <input type="file" accept="image/*" name="patientPhoto" (change)="onFileSelected($event,'patientIdProofPhoto')" [ngClass]="{'invalid-input': (submitted && opd.get('idProofUrl').value=='')}">

                    </div>

                </div>



                <br>
                <br>
                <h4><mark class="bg-warning"> RELATIVES DETAILS </mark></h4>
                <hr class="border-dark">

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relativeName">Name of the Relative <span class="red-asterisk">*</span>    </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" class="form-control" placeholder="Enter Relative Name" autocomplete="off" formControlName="relativeName" [ngClass]="{'invalid-input': (submitted && opd.get('relativeName')?.errors)}">

                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relation">Relation <span class="red-asterisk">*</span>  </label>
                    </div>

                    <div class="col-sm-4">

                        <select class="form-control custom-select"
                                [ngClass]="{'invalid-input': (submitted && opd.get('relation')?.errors)}"
                                formControlName="relation">
                            <option [ngValue]="null" *ngIf="!loadingRelations">
                                Select relation
                            </option>
                            <option selected *ngIf="loadingRelations">Loading...</option>
                            <option *ngFor="let item of relations"
                                    [textContent]="item.value"
                                    [ngValue]="item.value"></option>
                        </select>

                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relativeDob">Date Of Birth <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="date" class="form-control" name="relativeDob" (change)="calculateAge($event,'relativeAge')" formControlName="relativeDob" [ngClass]="{'invalid-input': (submitted && opd.get('relativeDob')?.errors)}">

                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relativeAge">Age </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" class="form-control bg-soft-dark" name="relativeAge" [readonly]="true" formControlName="relativeAge">

                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relativeReligion">Religion <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">


                        <select class="form-control custom-select text-uppercase"
                                formControlName="relativeReligion"
                                [ngClass]="{'invalid-input': (submitted && opd.get('relativeReligion')?.errors)}">
                            <option [ngValue]="null">Select</option>
                            <option *ngFor="let religion of religions" [textContent]="religion.name" [ngValue]="religion.name">
                            </option>
                        </select>

                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label>Qualification <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">



                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="education"
                                   [loading]="loadingEducation"
                                   bindLabel="value"
                                   bindValue="value"
                                   autocomplete="nope"
                                   placeholder="-Select-"
                                   formControlName="relativeQualification"
                                   [ngClass]="{'invalid-input': (submitted && opd.get('relativeQualification')?.errors)}">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    Nothing found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>

                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label>Occupation <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">
                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="occupationList"
                                   [loading]="loadingOccupation"
                                   bindLabel="value"
                                   bindValue="value"
                                   autocomplete="nope"
                                   placeholder="-Select-"
                                   formControlName="relativeOccupation"
                                   [ngClass]="{'invalid-input': (submitted && opd.get('relativeOccupation')?.errors)}">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    Nothing found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label>Nationality <span class="red-asterisk">*</span></label>
                    </div>
                    <div class="col-sm-4">
                        <select class="form-control custom-select text-uppercase"
                                formControlName="relativeNationality"
                                [ngClass]="{'invalid-input': (submitted && opd.get('nationality')?.errors)}">
                            <option [ngValue]="null">Select</option>
                            <option *ngFor="let nationality of nationalities" [textContent]="nationality.name" [ngValue]="nationality.name">
                            </option>
                        </select>
                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relativePhoneNumber">Phone Number <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text"
                               class="form-control"
                               formControlName="relativePhoneNumber"
                               [ngClass]="{'invalid-input': (submitted && opd.get('relativePhoneNumber')?.errors)}"
                               maxlength="10"
                               numbersOnly
                               block
                               autocomplete="nope"
                               placeholder="Enter mobile number" />
                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="relativeAlternateMobileNo">Alternate Mobile No</label>
                    </div>

                    <div class="col-sm-4">



                        <input type="text"
                               [ngClass]="{'invalid-input': (submitted && opd.get('relativeAlternateMobileNo')?.errors)}"
                               class="form-control"
                               formControlName="relativeAlternateMobileNo"
                               maxlength="10"
                               numbersOnly
                               block
                               autocomplete="nope"
                               placeholder="Enter alternate mobile number" />

                    </div>

                </div>

                <div class="row my-1">
                    <div class="col-sm-2">
                        <label for="reltiveEmail">Email <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" name="reltiveEmail" formControlName="relativeEmail" placeholder="Enter Relative Email" autocomplete="off" class="form-control" [ngClass]="{'invalid-input': (submitted && opd.get('relativeEmail').invalid)}">

                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="idProof">ID Proof <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">




                        <select name="idProof" id="idProof" class="form-control" formControlName="relativeIdProof" [ngClass]="{'invalid-input': (submitted && opd.get('relativeIdProof')?.errors)}">
                            <option value="null">select</option>
                            <option value="Aadhar Card">Aadhar Card</option>
                            <option value="Voter Id">Voter Id</option>
                            <option value="PAN Card">PAN Card</option>

                        </select>
                    </div>

                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label>
                            Upload Id <span class="red-asterisk">*</span>
                        </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="file" accept="image/*" (change)="onFileSelected($event,'relativeIdProof')" [ngClass]="{'invalid-input': (submitted && opd.get('relativeIdProofUrl').value=='')}">

                    </div>

                </div>


                <br>
                <br>
                <h4><mark class="bg-warning"> ADDRESS </mark></h4>
                <hr>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="address">Building/Street Address <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" name="address" formControlName="address" placeholder="Enter Building/Street Address" autocomplete="off" class="form-control" [ngClass]="{'invalid-input': (submitted && opd.get('address')?.errors)}">

                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="state">State <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">


                        <div class="text-uppercase">
                            <ng-select class="ng-select-sm text-uppercase"
                                       [items]="states"
                                       bindLabel="value"
                                       bindValue="value"
                                       autocomplete="nope"
                                       placeholder="-Select-"
                                       formControlName="state"
                                       (change)="fetchCities($event.id)"
                                       [ngClass]="{'invalid-input': (submitted && opd.get('state').invalid)}">
                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                    <div class="ng-option disabled">
                                        No state found for '{{searchTerm}}'
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>



                    </div>
                </div>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="city">City <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">

                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="cities"
                                   bindLabel="value"
                                   bindValue="value"
                                   autocomplete="nope"
                                   placeholder="-Select-"
                                   formControlName="city"
                                   [ngClass]="{'invalid-input': (submitted && opd.get('city').invalid)}">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No city found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>


                    </div>

                </div>


                <br>

                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="area">Area <span class="red-asterisk">*</span></label>
                    </div>

                    <div class="col-sm-4">
                        <input type="text" formControlName="area" class="form-control" placeholder="Enter Area" autocomplete="off" [ngClass]="{'invalid-input': (submitted && opd.get('area').invalid)}">
                    </div>

                </div>

                <br>
                <!-- ng select for area -->


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="pincode">Pin Code <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">

                        <div class="input-group">
                            <input formControlName="pincode"
                                   maxlength="6"
                                   block
                                   numbersOnly
                                   autocomplete="nope"
                                   class="form-control"
                                   [ngClass]="{'invalid-input': (submitted && opd.get('pincode')?.errors)}"
                                   placeholder="PIN CODE" />
                        </div>
                    </div>

                </div>


                <div class="row my-2">
                    <div class="col-sm-2">
                        <label for="pincode">Sign <span class="red-asterisk">*</span> </label>
                    </div>

                    <div class="col-sm-4">
                        <canvas #canvas class="w-100 min-width-250-px min-height-182" [style.border]="'2px solid ' + borderColor"></canvas>
                        <br />
                        <button type="button" class="btn btn-danger" (click)="clearCanvas()">Clear</button>

                    </div>

                </div>





                <br>
                <br />
                <div class="row">
                    Declaration <span class="red-asterisk">*</span>
                </div>

                <hr class="border-dark" />
                <div class="row">
                    <div class="col-sm-6">
                        I hereby declare that all the information furnished above is true and can be used for any official purpose – BABY BIRTH CERTIFICATE. The hospital will not be responsible for any incorrect information given by me or my attendant.
                    </div>

                </div>
                <br />
                <div class="column-gap10px d-flex">

                    <div><div [ngClass]="{'invalid-input': (submitted && opd.get('acceptTerms').invalid)}"> <input type="checkbox" formControlName="acceptTerms" />  </div></div>
                    <div>I accept the Terms and Conditions.</div>

                </div>




                <br>





                <button type="submit"
                        [disabled]="isLoading && opd.valid"
                        class="btn btn-primary1 ml-1 white-space-nowrap">
                    <span *ngIf="isLoading && opd.valid">
                        <span class="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"></span>
                        Please wait..
                    </span>
                    <span id="submit" *ngIf="!isLoading || opd.invalid" placement="left" ngbTooltip="Alt + s"><i class="mr-1 mdi mdi-check-all"></i>Submit</span>
                </button>



            </form>

        </div>
    </div>



</div>
<!--<div class="w-100 font-16">
    <div class="row ">
        <div class="col-3">
            <i class="mdi mdi-account text-primary mr-1"></i><strong style="font-size:17px">Patient Name:</strong>&nbsp;
            <span *ngIf="appointment && appointment.patientName" [textContent]="appointment.patientName"></span>
        </div>
        <div class="col-3">
            <i class="text-primary mr-1"></i><strong style="font-size:17px">UMR No.:</strong>&nbsp;
            <span *ngIf="appointment && appointment.umrNo" [textContent]="appointment.umrNo"></span>
        </div>
        <div class="col-3" *ngIf="appointment && appointment.patientGender =='F'">
            <i class="mdi mdi-account text-primary mr-1"></i><strong style="font-size:17px">Husband Name:</strong>&nbsp;
            <span [textContent]="husbandName"></span>
        </div>
        <div class="col-3" *ngIf="appointment && appointment.patientGender =='M'">
            <i class="mdi mdi-account text-primary mr-1"></i><strong style="font-size:17px">Wife Name:</strong>&nbsp;
            <span [textContent]="wifeName"></span>
        </div>
    </div>
</div>-->

<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border d-flex justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">
            <div class="d-flex headerHeight">
                <button class="align-items-center d-flex m-1 p-0 rounded-circle encounterBtn width30px height30px" (click)="toggleSideNav()">
                    <i [ngClass]="{'d-none':sideNav}" class="mdi mdi-chevron-left font-26"></i><i [ngClass]="{'d-none':!sideNav}" class="mdi d-none mdi-chevron-right font-26"></i>
                </button>
            </div>
            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="textColorEncounter font-17 text-uppercase cursor-default" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
            </div>
            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment && appointment.providerName!==null">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="textColorEncounter font-17 text-uppercase cursor-default">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.appointmentTimeString}}</span></h4>
            </div>
        </div>
    </div>
</div>
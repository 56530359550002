export class LabStatusModel {
    labBookingTimeLineId: number;
    newLabBookingHeaderId: number;
    labBookingStatusId: number;
    commentedBy: number;
    comment: string;
    status: string;
    commentedByName: string;
    roleName: string;
    createdDate: Date;
    locationName: string;
}
<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ng-container *menuButton="'nurse_shifts_add'" >
                            <button type="button" class="btn btn-primary btn-sm ml-1" (click)="onOpenModel(templateAddModifyShift)"><i class="fe-plus mr-1"></i> Add Shift</button>
                        </ng-container>
                    </div>
                    <h4 class="page-title">Shifts</h4>
                </div>
            </div>
        </div>
        <!--<div class="col-12 p-0">
            <div class="card-box mb-1 p-1">
                <table class="table table-borderless table-sm mb-0">
                    <tr>
                        <td style="width:17%;">
                            <div class="form-group mb-0" *ngIf="isWidgetTypeFilters">
                                <label class="mb-1">Type</label>
                                <ng-select [items]="widgetTypes"
                                           [loading]="loadingWidgetTypes"
                                           [trackByFn]="trackBy.resource"
                                           bindLabel="value"
                                           bindValue="id"
                                           [typeahead]="widgetTypesInput"
                                           typeToSearchText="Search widget type"
                                           placeholder="Enter widget type name"
                                           [(ngModel)]="filters.options.widgetTypeId">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        No widget type found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item" let-i="index">
                                        <div class="media">
                                            <div class="avatar-xs mr-1">
                                                <img *ngIf="item.optionalText" [src]="item.optionalText" [alt]="item.value" class="img-fluid rounded-circle">
                                                <span *ngIf="!item.optionalText" class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.value | initials"></span>
                                            </div>
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </td>
                        <td style="width:17%;">
                            <div class="form-group mb-0" *ngIf="isRoleFilters">
                                <label class="mb-1">Role</label>
                                <ng-select [items]="roles"
                                           [loading]="loadingRoles"
                                           [trackByFn]="trackBy.resource"
                                           bindLabel="value"
                                           bindValue="id"
                                           [typeahead]="rolesInput"
                                           typeToSearchText="Search role"
                                           placeholder="Enter role name"
                                           [(ngModel)]="filters.options.roleId">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        No role found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item" let-i="index">
                                        <div class="media">
                                            <div class="avatar-xs mr-1">
                                                <img *ngIf="item.optionalText" [src]="item.optionalText" [alt]="item.value" class="img-fluid rounded-circle">
                                                <span *ngIf="!item.optionalText" class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.value | initials"></span>
                                            </div>
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </td>
                        <td style="width: 12%;">
                            <div style="margin-top: 25px;">
                                <button type="button" (click)="onApplyFilters()" class="btn btn-sm btn-outline-primary">Filter</button>
                                <button type="button" (click)="onResetFilters()" class="btn btn-sm btn-outline-danger ml-1">Reset</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>-->
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading shifts ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!shifts || !shifts.length)">
            <div class="col-12 mh-300">
                <no-data [title]="'Shifts'"></no-data>
            </div>
        </div>
        <div class="row" *ngIf="!loading && shifts && shifts.length">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm table-striped table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th width="50">#</th>
                                        <th>Name</th>
                                        <th>Slots</th>
                                        <th width="100" class="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of shifts; let i = index;">
                                        <td [textContent]="((pagination.pageIndex - 1) * pagination.pageSize) + (i + 1)"></td>
                                        <td [textContent]="item.name"></td>
                                        <td>
                                            <ng-container *ngFor="let slot of item.slots">
                                                <span class="font-14 font-weight-bold mr-1">
                                                    <span [textContent]="slot.startTime?.slice(0, 5)"></span>
                                                    <span> - </span>
                                                    <span [textContent]="slot.endTime?.slice(0, 5)"></span>

                                                </span>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <a (click)="onOpenModel(templateAddModifyShift, item)" href="javascript:;" class="action-icon text-primary mr-1" placement="left" ngbTooltip="Edit">
                                                    <i *ngIf="!item.loadingMetaData" class="mdi mdi-pencil"></i>
                                                    <i *ngIf="item.loadingMetaData" class="mdi mdi-spin mdi-loading"></i>
                                                </a>
                                                <a href="javascript:;" class="text-danger" *ngIf="item.active" placement="left" ngbTooltip="Inactive" (click)="onModifyStatus(item,false)">
                                                    <i class="mdi mdi-close-thick
"></i>
                                                </a>
                                                <a href="javascript:;" class="text-success" *ngIf="!item.active" placement="left" ngbTooltip="Active" (click)="onModifyStatus(item,true)">
                                                    <i class="mdi mdi-check-bold"></i>
                                                </a>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot *ngIf="pagination.totalPages > 1">
                                    <tr>
                                        <td colspan="9">
                                            <nav class="d-flex align-items-center justify-content-between p-2">
                                                <p class="mb-0 font-13">
                                                    <span class="text-dark">Page <span [textContent]="pagination.currentPage"></span> of <span [textContent]="pagination.totalPages"></span></span>
                                                    <span class="ml-1">
                                                        <span>(Showing <span [textContent]="pagination.currentItems - shifts.length + 1"></span> - <span [textContent]="pagination.currentItems"></span> of <span [textContent]="pagination.totalItems"></span>  shifts)</span>
                                                    </span>
                                                </p>
                                                <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="true" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                                                    <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                                                    <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                                                </ngb-pagination>
                                            </nav>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateAddModifyShift>
    <form [formGroup]="addShiftForm" (submit)="onAddShift()">
        <div class="modal-header">
            <h4 class="modal-title">
                <span *ngIf="selectedRecord">Update</span>
                <span *ngIf="!selectedRecord">Add New</span> Shift
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseInsertModal();">×</button>
        </div>
        <div class="modal-body p-0">
            <div class="card-box m-0">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label class="mb-1">Name <code>*</code></label>
                            <input type="text" [ngClass]="{ 'is-invalid': (submitted && forms.addShift.name.errors) }" class="form-control" formControlName="name" maxlength="50" titleOnly block autocomplete="nope" placeholder="Enter shift name">
                            <div *ngIf="submitted && forms.addShift.name.errors" class="invalid-feedback show-must">
                                <div *ngIf="forms.addShift.name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header p-2">
                        <span class="font-14 font-weight-bold"><i class="mdi mdi-arrow-down mr-1"></i>Select Slot</span>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="!loadingShiftSlots && !shiftSlots.length">
                            <div class="col-lg-12">
                                <span class="font-weight-bold">No Slots Found.</span>
                            </div>
                        </div>
                        <div class="row" *ngIf="!loadingShiftSlots && shiftSlots.length">
                            <div class="col-lg-3" formArrayName="slots" *ngFor="let item of shiftSlots; let i = index;">
                                <ng-container [formGroupName]="i">
                                    <div class="custom-control custom-checkbox p-0">
                                        <div class="d-flex align-items-center">
                                            <input type="checkbox" class="form-control w-20-px mr-2" formControlName="value">
                                            <span class="white-space-nowrap">
                                                <span [textContent]="item.startTime?.slice(0, 5) "></span>
                                                <span> - </span>
                                                <span [textContent]="item.endTime?.slice(0, 5)"></span>
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-success btn-sm" (click)="onOpenSlotModel(templateAddShiftSlot)"><i class="fe-plus mr-1"></i> Add New Slot</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseInsertModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>


<ng-template #templateAddShiftSlot>
    <form [formGroup]="addShiftSlotForm" (submit)="onAddShiftSlot()">
        <div class="modal-header">
            <h4 class="modal-title"> 
                <span>Add Shift Slot</span>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseSlotModal();">×</button>
        </div>
        <div class="modal-body p-0">
            <div class="card-box">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-1">Start Time <code>*</code></label>
                            <input type="time" [ngClass]="{ 'is-invalid': (slotSubmitted && forms.addShiftSlot.startTime.errors) }" class="form-control" formControlName="startTime" />
                            <div *ngIf="slotSubmitted && forms.addShiftSlot.startTime.errors" class="invalid-feedback show-must">
                                <div *ngIf="forms.addShiftSlot.startTime.errors.required">Start Time is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-1">End Time <code>*</code></label>
                            <input type="time" [ngClass]="{ 'is-invalid': (slotSubmitted && forms.addShiftSlot.endTime.errors) }" class="form-control" formControlName="endTime" />
                            <div *ngIf="slotSubmitted && forms.addShiftSlot.endTime.errors" class="invalid-feedback show-must">
                                <div *ngIf="forms.addShiftSlot.endTime.errors.required">End Time is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseSlotModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span *ngIf="slotSubmitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!slotSubmitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>


import { Component, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { Page, IUserAccount, IEncounterResource } from "../../../../../shared/models";
import { FormGroup, FormBuilder } from "@angular/forms";
import { AppData } from "../../../../../app.data";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MenuService, HttpService, NotifyService, EncounterCommunication } from "../../../../../shared/services";
import { takeUntil, finalize } from "rxjs/operators";
import { DietEncounterType } from "../../../../../shared/enums";
import { ApiResources, DateHelper, UtilHelper } from "../../../../../shared/helpers";
import { Appointment, EncounterValue, PreviousAppointment } from "../../../../../shared/entities";
import { Diet } from "../../diet.namespace";
/*import { WeightValidator } from "../../../../../shared/validators";*/
import { DietEncounter } from "../../../../../shared/models/diet-encounter.model";



@Component({
    templateUrl: "./op-nutritional-assesment.html",
    encapsulation: ViewEncapsulation.None
})

export class OPNutritionalAssessmentPage implements OnInit, OnDestroy {

    /*
    Using this component in two situation ,
    1 ) from web site where doctor fills form at url =>  app/diet-plan-encounter/:id/b/op-nutritional-assessment
    2) Patient fills the form from mobile at url => patientForm/diet-plan-encounter/:id/b/nutritionalDiet

    condition : 
    if patientForm exists in route then it's mobile 
    else : web 

    */
    page: Page;
    submitting: boolean;
    submitted: boolean;
    redirecting: boolean;
    routingValue: string;
    encryptedAppointmentId: string;
    opNutritionalAssessment: FormGroup;
    appointmentId: string;
    section: string;
    nextRoute: string;
    morningMilkOpts = ["", "nill", "150 ml", "200 ml", "250 ml", "100 ml"];
    sugarQty = ["", "nill", "1 tsp", "2 tsp", "<1 tsp"];
    breakfastOpts = ["Idli", "Dosa", "Roti", "Bread/Sanwich/Toast", "Oats with milk"];
    breakfastDalOpts = ["nill", "1/4 cup", "1/2 cup", "1 cup"];
    idliOpts = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "Nill"];
    dosaOpts = ["1", "2", "3", "4", "nill"];
    rotiOpts = ["1", "2", "3", "nill"];
    breadOpts = ["1 slice", "2 slice", "3 slice", "4 slice", "others", "nill"];
    fruitjuiceOpts = ["", "Nill", "100", "200", "250", "Others"];
    carealsOpts = ["nill", "Yes", "Rice", "Brown Rice (cooked 200 ml)", "Jowar Roti", "Wheat rava (cooked 200 ml)", "Millets (cooked 200 ml)", "Chapathi", "Phulkas"]
    riceOpts = ["", "Nill", "1 cup", "2 cups", "3 cups", "1.5 cups", "2.5 cups", "3.5 cups"];
    rawaOpts = ["", "Nill", "1", "2", "3", "1.5 cups", "2.5 cups", "3.5 cups"];
    milletsOpts = ["", "Nill", "Yes", "1 cup", "2 cups", "3 cups", "1.5 cups", "2.5 cups", "3.5 cups"];
    vegCurryOpts = ["", "nill", "1/4 cup", "1/2 cup", "1 cup", "1/3 cup", "3/4 cup"];
    dinLeafyOpts = ["", "nill", "1/4 cup", "1/2 cup", "1 cup"];
    paneerOpts = ["", "nill", "2 pcs", "3 pcs", "4 pcs", "5 pcs", "6 pcs", "Others"];
    curdOpts = ["", "nill", "100 ml", "200 ml", "Less than 100 ml"];
    dinnerCurdOpts = ["nill", "100 ml", "200 ml", "Less than 100 ml", "No"];
    dalOpts = ["", "nill", "1/4 Cups", " 1/2 Cups", "1 Cups"];
    lunchNonVegOpts = ["2-3", "4-5", "Others"];
    qualityOfDal = ["Once / day", "twice / week", "thrice / week", "nill", "Once / week", "Rarely"];
    qualityOfDiet = ["", "nill", "Once / week", "twice / week", "Once / fortNight", "No", "Once / month", "thrice / week", "Daily"];
    inclusionOpts = ["nill", "Once / week", "twice / week", "Alternative days", "Daily", "Once / month", "Once / fortNight"]
    corbonatedOpts = ["", "nill", "Once/ day", "twice / week", "alternate day", "monthly / once", "Rarely"];
    intakeFruitOpts = ["Less than 2 fruit", "More than 2 fruit", "1 / Day", "2 / Day", "3 / Day", "twice / week", "thrice / week", "None"];
    waterIntakeOpts = ["1 ltr/ Day", "2 ltrs/ Day", "3 ltrs/ Day", "nill", " < 1 ltr", "others", "3.5 letre", "4 litre"];
    totalOilUsedOpts = ["nill", "1 L", "2 L", "3 L", "4 L", "5 L", "others", "Not aware"];
    //typeOfOilOpts = ["nill", "Sunflower", "Safflower", "Rice Brand", "Ground Nuts", "Mustard", "Sesame", "cold Pressed", "Olive","others", "Not Aware"];
    gheeButterOpts = ["1 Tbsp", "2 Tbsp", "3 Tbsp", "4 Tbsp", "5 Tbsp", "others", "nill"];
    beansGramsOpts = ["Once / Day", "twice / week", "thrice / week", "nill", "Once / week", "Once / month", "Once / fortNight", "Rarely", "Daily", "Twice a day"];
    walkingOpts = ["", "60 Mins / Day", "40 Mins / Day", "30 Mins / Day", "Less than 15 Mins / Day", "120 Mins / Day", "Less than 30 Mins / Day", "No"];
    timeSpendOpts = ["", "30 Min", "60 Min", "90 Min", "120 Min", "Others", "Yes", "No","Nil"];
    qualityMutton = ["Yes", "Others", "Rarely", "No"];
    qualityMuttonOpts = ["Once / Day", "twice / week", "thrice / week", "nill", "Once / week", "Once / fortNight", "Rarerly", "twice a day"];
    qualityeggwholeOpts = ["Once / Day", "twice / week", "thrice / week", "nill", "Once / week", "No"];
    qualityeggWhiteOpts = ["Once / Day", "twice / week", "thrice / week", "nill", "Once / week", "Once / fortNight", "Rarerly", "No"];
    cookingHouseholdWorkOpts = ["", "30 Min", "60 Min", "90 Min", "120 Min", "Others", "Yes", "No"];
    dietRecommenedOpts = ["", "Normal(Balanced) marco-micro nutrients", "Diabetic (Low GI CHO,Moderate Protien)", "PIH/HIN(Low Fat(SFA),Moderate Protein)", "SoftDiet(Easy to digest CHO with optional fluid)", "Anemia(High Biological value Protien,Fe,Vit C)", "Rest wt Gain(Complex CHO,Moderate Protein)", "Low BMI(High Calorie,High Protein)", "Others", "RWG + GDM", "Weight Loss", "TLC 1", "TLC 2", "TLC 3", "Pre Diabetic", "High BMI", "PCOS", "Yes","Nil"];
    roleName: string;
    isAdmission: boolean;
    dietEncounter: IEncounterResource;
    presentRiskFactors = ['Pre Diabetic', 'Hyperemesis', 'Anemia', 'GDM', 'DM', 'HTN', 'GHTN', 'History of LGA', 'Hypothyroid', 'High BMI + GDM', 'High BMI', 'Multi Fetal', 'PGDM', 'PCOS', 'Low BMI', 'Increased weight gain'];
    dietPreferenceWith = ['Egg', 'Use of Alcohol', 'Use of Caffeine'];
    typeOfMilkCurd = ['1 %', '3 %', 'Skim', 'Whole', 'Not Consuming'];
    yesOrNo = ['No', 'Yes', 'nill'];
    qualityGLVOpts = ["Once / week", "Twice / week", "Thrice / week", "Rarely", "nill", "Once / Day", "Daily"];
    mottonOpts = ["Nill", "Yes", "Others", "Rarely", "No"];
    today = DateHelper.ngbToday;
    gpla: any;
    gplaData: any;
    measureData: any;
    dietData: any;
    dietEncounters: DietEncounter;
    dietPlanPrescribed: Diet.DietPlanPrescribed;
    measure: Diet.Measures;

    opNutritionAssesmentData: Diet.OPNutritionalAssessment;
    presentweight: any;
    prePregnancyWt: any;
    specialFeature: any;
    patientForm: boolean;


    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly menuservice: MenuService,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly encounterCommunication: EncounterCommunication
    ) {
        this.page = new Page();
        this.buildForm();
    }

    private buildForm() {
        this.opNutritionalAssessment = this.formBuilder.group({
            titles: [null],
            deptType: "OBS",
            visitType: [null],
            gpla: [null],
            gestationage: [null],
            specialFeatures: [null],
            occupation: [null],
            heightInFeet: [null],
            heightInMeter: [null],
            presentWt: [null],
            prePregnancybmi: [null],
            prePregBmiCategory: [null],
            wtLossIn1stTrimester: [null],
            presentWeight: [null],
            presentBmi: [null],
            wtLoss: [null],
            wtGain: [null],
            pastMedicalHistory: [null],
            pastSurgicalHistory: [null],
            visitNo: [null],
            familyHistory: [null],
            presentRiskFactors: [[]],
            otherRiskFactors: [null],
            haemoglobin: [null],
            hMGBNDate: DateHelper.todayInFormat,
            oGTT: [null],
            oGTTDate: DateHelper.todayInFormat,
            outsideTest: [null],
            dietPreference: [null],
            giHealth: [null],
            dental: [null],
            anyOtherConerns: [null],
            weighttLossIn1stTrimester: [null],
            milk: [null],
            nuts: [null],
            likes: [null],
            dislikes: [null],
            other1: [null],
            wakeUpTime: [null],
            sleepingTime: [null],
            morningTime: [null],
            morningMilk: [null],
            dryfruitsNuts: [null],
            breakfasttime: [null],
            breakfast: [null],
            vegCurry: [null],
            cooked: [null],
            dal: [null],
            breakfastOther: [null],
            midMorningtime: [null],
            fruits: [null],
            fruitsJuice: [null],
            tea: [null],
            midMorningOther: [null],
            lunchtime: [null],
            carealsMilletS: [null],
            lunchvegCurry: [null],
            greenLeafy: [null],
            lunchdal: [null],
            panner: [null],
            lunchNonVeg: [null],
            curd: [null],
            lunchOther: [null],
            eveningtime: [null],
            eveningfruits: [null],
            eveningfruitsJuice: [null],
            eveningSamosa: [null],
            eveningBakery: [null],
            eveningOther: [null],
            dinnertime: [null],
            dinnercarealsMilletS: [null],
            dinnerRice: [null],
            dinnervegCurry: [null],
            dinnergreenLeafy: [null],
            dinnerdal: [null],
            dinnerpanner: [null],
            ldinnerNonVeg: [null],
            dinnercurd: [null],
            dinnerOther: [null],
            bedtime: [null],
            bedTimeMilk: [null],
            bedtimeDryFruits: [null],
            bedTimeOther: [null],
            oftenyouEatSamosa: [null],
            typeOfFood: [null],
            inclusionOfFruitJuice: [null],
            qualityFruitJuice: [null],
            qualityFruitJuiceMl: [null],
            carbonatedDrink: [null],
            carbonated: [null],
            highSugarDrinkMore: [null],
            highSugarDrink: [null],
            inclusionOfProcessedJunk: [null],
            moreThanOfDiet: [null],
            intakeOfFruits: [null],
            typeOfMilkCurd: [null],
            waterIntake: [null],
            totalOilUsed: [null],
            typeOfOil: [null],
            gheeButter: [null],
            beansGrams: [null],
            qualitydal: [null],
            qualityGlv: [null],
            qualityMutton: [null],
            qualityChicken: [null],
            qualityFish: [null],
            qualityEgg: [null],
            activityPattern: [null],
            physicalActivity: [null],
            walking: [null],
            cookingHouseholdWork: [null],
            sittingOrReclining: [null],
            exercise: [null],
            chutney: [null],
            quantity: [null],
            coconutchutney: [null],
            Veg: [null],
            peanut: [null],
            giHealthYes: [null],
            morningMilkSugar: [null],
            morningMilkBoost: [null],
            breakfastIdli: [null],
            breakfastDosa: [null],
            breakfastRoti: [null],
            breakfastBread: [null],
            breakfastSliceOther: [null],
            fruitType: [null],
            fruitJuiceQua: [null],
            fruitJuiceOther: [null],
            tesQua: [null],
            lunceBrownRice: [null],
            lunceMillets: [null],
            lunceWheatrawa: [null],
            lunceChapathi: [null],
            luncePulka: [null],
            lunchvegCooked: [null],
            greenLeafyCooked: [null],
            lunchdalCooked: [null],
            pannerPcs: [null],
            pannerOther: [null],
            lunchNonVegPcs: [null],
            lunchNonVegOther: [null],
            lunchcurd: [null],
            eveningfruitsOther: [null],
            eveningfruitJuice: [null],
            eveningSamosaName: [null],
            eveningBakeryName: [null],
            totalOilUsedOthers: [null],
            otherOil: [null],
            anyOther: [null],
            gheeButterYes: [null],
            gheeButterOthers: [null],
            dinnergreenLeafOpts: [null],
            dinnerWheatRava: [null],
            nutritionalScreening: [null],
            pamphaletOfHealthyFood: [null],
            nutritionalRisk: [null],
            counselledByNutritionist: [null],
            dietRecommened: [null],
            leafletGivenDietPlan: [null],
            prescribedCalories: [null],
            prescribedProtien: [null],
            signature: [null],
            date: DateHelper.todayInFormat,
            prePregnancyWt: [null],
            dietPreferenceWith: [[]],
            teaQua: [null],
            breakfastOats: [null],
            breakfastSlice: [null],
            lunceRice: [null],
            dinnerBrownRice: [null],
            dinnerMillets: [null],
            dinnerWheatrawa: [null],
            dinnerdalopts: [null],
            dinnerpannerPCs: [null],
            ldinnerNonVegPcs: [null],
            ldinnerNonVegOther: [null],
            bedTimeMilkOpts: [null],
            dinnerChapathi: [null],
            dinnervegCooked: [null],
            dinnerPulka: [null],
            bedTimeMilkSugar: [null],
            bedTimeMilkBoost: [null],
            intakeOfWholeGrains: [null],
            intakeOfWholeGrainsQuantity: [null],
            lowVegetableIntake: [null],
            intakeFruits: [null],
            waterIntakeOther: [null],
            others: [null],
            qualityMuttontake: [null],
            eggWhite: [null],
            eggWhole: [null],
            qualityFishtake: [null],
            waterIntakeOthers: [null],
            datee: DateHelper.today,
            qualityChickentake: [null],
            bmiCategorization: [null],
            morningMilkOpt: [null],
            qualitydalYes: [null],
            sittingOthers: [null],
            specialFeaturesForCare: [null],
            lunceroti: [null],
            dinnerRoti: [null],
            dinnerpannerOthers: [null],
            qualityPaneerYes: [null],
            qualityPaneer: [null],
            dietRecommenedOthers: [null],
            qualityMuttonOther: [null],
            cookingHouseholdWorkOthers: [null],
            eveningfruitsJuiceOther: [null],
            beansGramsYes: [null]
        })

    }

    onChangeDeptType() {
        this.opNutritionalAssessment.patchValue({
            gpla: null,
            gestationage: null,
            specialFeatures: null,
            occupation: null,
            heightInFeet: null,
            heightInMeter: null,
            prePregnancyWt: null,
            prePregnancybmi: null,
            prePregBmiCategory: null,
            wtLossIn1stTrimester: null,
            presentWeight: null,
            presentBmi: null,
            bmiCategorization: null,
            wtLoss: null,
            wtGain: null
        })
    }

    onChangeWtLoss() {
        this.opNutritionalAssessment.patchValue({
            weighttLossIn1stTrimester: null
        })

    }

    onChangeGiHealth() {
        this.opNutritionalAssessment.patchValue({
            giHealthYes: null
        })
    }
    onChangeMrgMilk() {
        this.opNutritionalAssessment.patchValue({
            morningMilkOpt: null,
            morningMilkSugar: null,
            morningMilkBoost: null,
        })
    }

    onChangebreakfast() {
        this.opNutritionalAssessment.patchValue({
            breakfastIdli: null,
            breakfastDosa: null,
            breakfastRoti: null,
            breakfastSlice: null,
            breakfastSliceOther: null
        })
    }
    changeBreakfastVeg() {
        this.opNutritionalAssessment.patchValue({
            cooked: null
        })
    }
    changeBreakfastDal() {
        this.opNutritionalAssessment.patchValue({
            dal: null
        })
    }
    changeMidMrgFruits() {
        this.opNutritionalAssessment.patchValue({
            fruitType: null
        })
    }

    changeMidMrgJuice() {
        this.opNutritionalAssessment.patchValue({
            fruitJuiceQua: null,
            fruitJuiceOther: null
        })
    }
    onChangeTea() {
        this.opNutritionalAssessment.patchValue({
            teaQua: null

        })
    }
    onChangeType() {
        this.opNutritionalAssessment.patchValue({
            quantity: null

        })
    }
    changeWholeGrains() {
        this.opNutritionalAssessment.patchValue({
            intakeOfWholeGrainsQuantity: null

        })
    }
    onChangeCarealsMillet() {
        this.opNutritionalAssessment.patchValue({
            lunceRice: null,
            lunceBrownRice: null,
            lunceroti: null,
            lunceWheatrawa: null,
            lunceMillets: null,
            lunceChapathi: null,
            luncePulka: null

        })
    }

    onChangeLunchVeg() {
        this.opNutritionalAssessment.patchValue({
            lunchvegCooked: null
        })
    }
    onChangeLunchLeafy() {
        this.opNutritionalAssessment.patchValue({
            greenLeafyCooked: null
        })
    }
    onChangeLunchDal() {
        this.opNutritionalAssessment.patchValue({
            lunchdalCooked: null
        })
    }
    onChangeLunchPaneer() {
        this.opNutritionalAssessment.patchValue({
            pannerPcs: null,
            pannerOther: null
        })
    }
    onChangeLunchnonVeg() {
        this.opNutritionalAssessment.patchValue({
            lunchNonVegPcs: null,
            lunchNonVegOther: null
        })
    }
    changeEvgFruit() {
        this.opNutritionalAssessment.patchValue({
            eveningfruitsOther: null
        })
    }
    changeEvgFruitJuice() {
        this.opNutritionalAssessment.patchValue({
            eveningfruitsJuice: null,
            eveningfruitsJuiceOther: null,
        })
    }
    changeEvgSamosa() {
        this.opNutritionalAssessment.patchValue({
            eveningSamosaName: null
        })
    }
    changeEvgBakery() {
        this.opNutritionalAssessment.patchValue({
            eveningBakeryName: null
        })
    }
    changeDinnerCereals() {
        this.opNutritionalAssessment.patchValue({
            eveningBakeryName: null,
            dinnerRice: null,
            dinnerBrownRice: null,
            dinnerRoti: null,
            dinnerMillets: null,
            dinnerWheatRava: null,
            dinnerChapathi: null,
            dinnerPulka: null,
        })
    }
    changeDinnerVeg() {
        this.opNutritionalAssessment.patchValue({
            dinnervegCooked: null,
        })
    }
    changeDinnerLeafy() {
        this.opNutritionalAssessment.patchValue({
            dinnergreenLeafOpts: null,
        })
    }
    changeDinnerDal() {
        this.opNutritionalAssessment.patchValue({
            dinnerdalopts: null,
        })
    }
    changeDinnerPaneer() {
        this.opNutritionalAssessment.patchValue({
            dinnerpannerPCs: null,
            dinnerpannerOthers: null
        })
    }
    changeDinnerNonVeg() {
        this.opNutritionalAssessment.patchValue({
            ldinnerNonVegPcs: null,
            ldinnerNonVegOther: null
        })
    }
    changeBedTimeMilk() {
        this.opNutritionalAssessment.patchValue({
            bedTimeMilkOpts: null,
            bedTimeMilkSugar: null,
            bedTimeMilkBoost: null
        })
    }
    changeQualityFruit() {
        this.opNutritionalAssessment.patchValue({
            qualityFruitJuiceMl: null
        })
    }
    changeCarbonated() {
        this.opNutritionalAssessment.patchValue({
            carbonated: null
        })
    }
    changeHighSugarDrink() {
        this.opNutritionalAssessment.patchValue({
            highSugarDrink: null
        })
    }
    changeintakeOfFruits() {
        this.opNutritionalAssessment.patchValue({
            intakeFruits: null
        })
    }
    changeGhee() {
        this.opNutritionalAssessment.patchValue({
            gheeButterYes: null,
            gheeButterOthers: null
        })
    }
    changeBeans() {
        this.opNutritionalAssessment.patchValue({
            beansGramsYes: null
        })
    }
    changeQualityDal() {
        this.opNutritionalAssessment.patchValue({
            qualitydal: null
        })
    }
    changeQualityPaneer() {
        this.opNutritionalAssessment.patchValue({
            qualityPaneer: null
        })
    }
    changeQualityMutton() {
        this.opNutritionalAssessment.patchValue({
            qualityMuttontake: null,
            qualityMuttonOther: null
        })
    }
    changeQualityChicken() {
        this.opNutritionalAssessment.patchValue({
            qualityChickentake: null,

        })
    }
    changeQualityFish() {
        this.opNutritionalAssessment.patchValue({
            qualityFishtake: null,
        })
    }
    changeEggWhite() {
        this.opNutritionalAssessment.patchValue({
            eggWhite: null,
            eggWhole: null
        })
    }


    positiveHeight() {
        let val = this.opNutritionalAssessment.get("heightInFeet").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            heightInFeet: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                heightInFeet: null
            })
        }
    }

    onCalculateWeight(value: any) {
        let weight = value.target.value;
        let parsedWeight = parseInt(weight);

        if (parsedWeight < 0) {
            this.opNutritionalAssessment.patchValue({
                weight: null
            });
            this.notifyService.warningToast("Please enter weight.");
            return;
        }
        if (this.opNutritionalAssessment.get("heightInFeet").value == null) {
            this.opNutritionalAssessment.patchValue({
                weight: null
            });
            this.notifyService.warningToast("Please enter height");
            return;
        }
        let height = this.opNutritionalAssessment.get("heightInMeter").value;
        //let converted = (height / 3.281).toFixed(2);
        //var con = parseFloat(converted);
        let bmi = (weight / (height * height)).toFixed(2);
        let category = bmi < "18.5" ? "Underweight" : bmi >= "18.5" && bmi < "24.9" ? "Normal weight" : bmi > "25" && bmi < "29.9" ? "Overweight" : "Obesity ";
        if (bmi != "NaN") {
            this.opNutritionalAssessment.patchValue({
                presentBmi: bmi,
                bmiCategorization: category
            })
        }       
            if (this.opNutritionalAssessment.get("prePregnancyWt").value != null) {
                let preWeight = this.opNutritionalAssessment.get("prePregnancyWt").value;
                preWeight = parseInt(preWeight);
                let diffWeight = preWeight - parsedWeight;
                if (diffWeight > 0) {
                    this.opNutritionalAssessment.patchValue({
                        wtLoss: Math.abs(diffWeight),
                        wtGain: 0
                    });
                }
                else {
                    this.opNutritionalAssessment.patchValue({
                        wtGain: Math.abs(diffWeight),
                        wtLoss: 0
                    });
                }            
        }
    }


    onCalculateOldBMI(value: any) {
        let weight = value.target.value;
        let parsedWeight = parseInt(weight);

        if (parsedWeight < 0) {
            this.opNutritionalAssessment.patchValue({
                prePregnancy: null
            });
            this.notifyService.warningToast("Please enter pre preg weight.");
            return;
        }
        if (this.opNutritionalAssessment.get("heightInFeet").value == null) {
            this.opNutritionalAssessment.patchValue({
                prePregnancy: null
            });
            this.notifyService.warningToast("Please enter height");
            return;
        }
        let height = this.opNutritionalAssessment.get("heightInMeter").value;
        //let converted = (height / 3.281).toFixed(2);
        //var con = parseFloat(converted);
        let bmi = (weight / (height * height)).toFixed(2);
        let category = bmi < "18.5" ? "Underweight" : bmi >= "18.5" && bmi < "24.9" ? "Normal weight" : bmi > "25" && bmi < "29.9" ? "Overweight" : "Obesity ";
        if (bmi != "NaN") {
            this.opNutritionalAssessment.patchValue({
                prePregnancybmi: bmi,
                prePregBmiCategory: category
            })
        }
    }


    positiveHeightMtr() {
        let val = this.opNutritionalAssessment.get("heightInMeter").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            heightInMeter: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                heightInMeter: null
            })
        }
    }
    positivePrePregWt() {
        let val = this.opNutritionalAssessment.get("prePregnancyWt").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            prePregnancyWt: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                prePregnancyWt: null
            })
        }
    }

    positiveWtlossTrimster() {
        let val = this.opNutritionalAssessment.get("weighttLossIn1stTrimester").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            weighttLossIn1stTrimester: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                weighttLossIn1stTrimester: null
            })
        }
    }
    positiveWt() {
        let val = this.opNutritionalAssessment.get("presentWeight").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            presentWeight: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                presentWeight: null
            })
        }
    }
    positiveWtloss() {
        let val = this.opNutritionalAssessment.get("wtLoss").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            wtLoss: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                wtLoss: 0
            })
        }
    }
    positiveWtgain() {
        let val = this.opNutritionalAssessment.get("wtGain").value;
        let replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            wtGain: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                wtGain: 0
            })
        }
    }



    isSelectedRisk(value: string) {
        const selectedpresentRiskFactors = this.opNutritionalAssessment.get("presentRiskFactors").value || [];
        return selectedpresentRiskFactors.indexOf(value) >= 0;
    }
    onChangeRisk(value: string) {
        const selectedpresentRiskFactors = this.opNutritionalAssessment.get("presentRiskFactors").value || [];
        const index = selectedpresentRiskFactors.indexOf(value);

        if (index === -1) {
            selectedpresentRiskFactors.push(value);
        } else {
            selectedpresentRiskFactors.splice(index, 1);
        }

        this.opNutritionalAssessment.patchValue({
            presentRiskFactors: selectedpresentRiskFactors
        });
    }

    isSelectedtypeOfMilkCurd(value: string) {
        const selectedtypeOfMilkCurd = this.opNutritionalAssessment.get("typeOfMilkCurd").value || [];
        return selectedtypeOfMilkCurd.indexOf(value) >= 0;
    }
    onChangetypeOfMilkCurd(value: string) {
        const selectedtypeOfMilkCurd = this.opNutritionalAssessment.get("typeOfMilkCurd").value || [];
        const index = selectedtypeOfMilkCurd.indexOf(value);

        if (index === -1) {
            selectedtypeOfMilkCurd.push(value);
        } else {
            selectedtypeOfMilkCurd.splice(index, 1);
        }

        this.opNutritionalAssessment.patchValue({
            typeOfMilkCurd: selectedtypeOfMilkCurd
        });
    }




    isSelecteddietPreferenceWith(value: string) {
        const selecteddietPreferenceWith = this.opNutritionalAssessment.get("dietPreferenceWith").value || [];
        return selecteddietPreferenceWith.indexOf(value) >= 0;
    }
    onChangedietPreferenceWith(value: string) {
        const selecteddietPreferenceWith = this.opNutritionalAssessment.get("dietPreferenceWith").value || [];
        const index = selecteddietPreferenceWith.indexOf(value);

        if (index === -1) {
            selecteddietPreferenceWith.push(value);
        } else {
            selecteddietPreferenceWith.splice(index, 1);
        }

        this.opNutritionalAssessment.patchValue({
            dietPreferenceWith: selecteddietPreferenceWith
        });
    }

    onSubmit(isNext: boolean) {
        this.submitted = true;
        if (!this.opNutritionalAssessment.valid) {
            this.notifyService.info("Please fill the form.");
            return;
        }
        const request = {
            appointmentId: this.dietEncounter.appointmentId,
            dietEncounterId: this.dietEncounter.dietEncounterId ? this.dietEncounter.dietEncounterId : 0,
            type: DietEncounterType.opNutritionalAssessmentForm,
            jsonString: JSON.stringify(this.opNutritionalAssessment.getRawValue()),
            modifiedBy: this.page.userAccount && this.page.userAccount.accountId ? this.page.userAccount.accountId : this.dietEncounters.patientId,
            isAdmission: this.isAdmission,

        }
        if (isNext) {
            this.redirecting = true;
        }
        else {
            this.submitting = true;
        }
            this.httpService.post(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.modify), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => {
                    this.submitting = this.submitted = false;
                }))
                .subscribe(async (dietEncounterId: number) => {
                    this.dietEncounter.dietEncounterId = dietEncounterId;
                    this.notifyService.successToast(`Op nutritional assessment  has been ${this.dietEncounter.jsonString != null ? 'updated' : 'added'} successfully.`);
                    let encryptedId = null;
                    if (this.patientForm && dietEncounterId !=null) {
                        var url = this.router.url;
                        this.notifyService.successToast(`Op nutritional assessment  has been add Successfully`);
                        const currentUrl = this.router.url;
                        await this.router.navigate(['']);
                        await this.router.navigate([url]);
                    }
                    if (this.dietEncounter != undefined && this.dietEncounter != null) {
                        encryptedId = this.dietEncounter.encryptedAppointmentId;
                    } else {
                        encryptedId = this.encryptedAppointmentId;
                    }
                    if (isNext) {
                        this.encounterCommunication.dietSetEncounter({
                            encryptedAppointmentId: this.encryptedAppointmentId,
                            isSubmitandNext: true,
                            isAdmission: this.isAdmission,
                        } as EncounterValue);
                        this.router.navigateByUrl(this.nextRoute);
                    }
                    else {
                        this.router.navigate(['app/diet-plan-encounter', encryptedId, (this.isAdmission ? 'a' : 'b'), 'dashboard', 'opNutritionalAssessmentSection']);
                    }
                });
    }
    onCalculateHeight(value: any) {
        let height = value.target.value;
        let split = height.split('.');
        let feet = split[0]
        let feettometers = (parseInt(feet) * 0.3048)
        let total;
        if (split.length > 1) {
            let inch = split[1]
            let inchtometer = (parseInt(inch) * 0.0254);
            total = inchtometer + feettometers;
        }
        else {
            total = feettometers;
        }
        let fixed = total.toFixed(2);
        if (fixed != "NaN") {
            this.opNutritionalAssessment.patchValue({
                heightInMeter: fixed
            })
        }

    }
    positivePrePegWt() {
        var val = this.opNutritionalAssessment.get("prePregnancyWt").value;
        var replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            prePregnancyWt: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                prePregnancyWt: null
            })
        }
    }
    //onCalculateWeight(value: any) {
    //    var weight = value.target.value;
    //    var heightInMeters = this.opNutritionalAssessment.get("heightInMeter").value;
    //    var bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
    //    var category = bmi < "18.5" ? "Underweight" : bmi > "18.5" && bmi < "24.9" ? "Normal weight" : bmi > "25" && bmi < "29.9" ? "Overweight" : "Obesity ";
    //    this.opNutritionalAssessment.patchValue({
    //        presentBmi: bmi,
    //        bmiCategorization: category
    //    })
    //}
    positiveHeightInMts() {
        var val = this.opNutritionalAssessment.get("heightInMeter").value;
        var replaced = val.replace(/[^\d.]/g, '');//positive number with decimal and 0
        this.opNutritionalAssessment.patchValue({
            heightInMeter: replaced
        })
        if (val.length >= 5 || val == '..') {
            this.opNutritionalAssessment.patchValue({
                heightInMeter: null
            })
        }
    }
    private findDiet(appointmentId: string) {
        const request = Object.assign({
            encryptedAppointmentId: appointmentId,
            type: DietEncounterType.opNutritionalAssessmentForm,
            isAdmission: this.isAdmission,
        });
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.findDashboard), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.dietEncounter = response;
                if (this.dietEncounter.jsonString) {
                    const dietModel = JSON.parse(this.dietEncounter.jsonString);
                    this.dietData = dietModel;
                    this.updateForm(dietModel);

                }
                if (this.measureData != null && this.gpla != null) {
                    this.opNutritionalAssessment.patchValue({
                        //  gpla: this.opNutritionAssesment && this.opNutritionAssesment.gpla ? this.opNutritionAssesment.gpla : this.opnutritionalScreening && this.opnutritionalScreening.gpla ? this.opnutritionalScreening.gpla : null,
                        heightInFeet: this.opNutritionAssesmentData && this.opNutritionAssesmentData.heightInFeet ? this.opNutritionAssesmentData.heightInFeet : this.measureData.heightInFeet ? this.measureData.heightInFeet : 0,
                        heightInMeter: this.opNutritionAssesmentData && this.opNutritionAssesmentData.heightInMeter ? this.opNutritionAssesmentData.heightInMeter : this.measureData.heightInMeters,
                        prePregnancyWt: this.opNutritionAssesmentData && this.opNutritionAssesmentData.prePregnancyWt ? this.opNutritionAssesmentData.prePregnancyWt : this.measureData.weight,
                        // preBMICategtory: this.opNutrition && this.opNutrition.preBMICategtory ? this.opNutrition.preBMICategtory : this.dietPlanPrescribed && this.dietPlanPrescribed.prePregnancy ? this.dietPlanPrescribed.prePregnancy : null,
                        prePregnancybmi: this.opNutritionAssesmentData && this.opNutritionAssesmentData.prePregnancybmi ? this.opNutritionAssesmentData.prePregnancybmi : this.measureData.bodyMassIndex,
                        gestationage: this.opNutritionAssesmentData && this.opNutritionAssesmentData.gestationage ? this.opNutritionAssesmentData.gestationage : this.gplaData.ga ? this.gplaData.ga:null

                    })
                }
                if (!this.dietData) {
                    if (this.measureData != undefined && this.measure != undefined) {
                        if (this.measure.weight > this.measureData.weight) {
                            var weightDiff = Number(this.measureData.weight) - Number(this.measure.weight);
                            this.opNutritionalAssessment.patchValue({
                                wtLoss: weightDiff <= 0 ? Math.abs(weightDiff) : 0,
                                wtGain: weightDiff >= 0 ? weightDiff : 0,
                            })
                        }
                        var weightDiff = Number(this.measure.weight) - Number(this.measureData.weight);
                        this.opNutritionalAssessment.patchValue({
                            wtLoss: weightDiff <= 0 ? Math.abs(weightDiff) : 0,
                            wtGain: weightDiff >= 0 ? weightDiff : 0,
                        })
                    }
                }
            });
    }

    private updateForm(dietModel?: Diet.OPNutritionalAssessment) {
        this.opNutritionalAssessment.patchValue({
            titles: dietModel.titles,
            deptType: dietModel.deptType,
            visitType: dietModel.visitType,
            //gpla: dietModel.gpla,
            // gestationage: dietModel.gestationage,
            specialFeatures: dietModel.specialFeatures,
            occupation: dietModel.occupation,
            heightInFeet: dietModel.heightInFeet,
            heightInMeter: dietModel.heightInMeter,
            presentWt: dietModel.prePregnancybmi,
            prePregnancybmi: dietModel.prePregnancybmi,
            prePregBmiCategory: dietModel.prePregBmiCategory,
            wtLossIn1stTrimester: dietModel.wtLossIn1stTrimester,
            presentWeight: dietModel.presentWeight,
            presentBmi: dietModel.presentBmi,
            wtLoss: dietModel.wtLoss,
            wtGain: dietModel.wtGain,
            pastMedicalHistory: dietModel.pastMedicalHistory,
            pastSurgicalHistory: dietModel.pastSurgicalHistory,
            visitNo: dietModel.visitNo,
            familyHistory: dietModel.familyHistory,
            presentRiskFactors: dietModel.presentRiskFactors,
            otherRiskFactors: dietModel.otherRiskFactors,
            haemoglobin: dietModel.haemoglobin,
            hMGBNDate: dietModel.hMGBNDate,
            oGTT: dietModel.oGTT,
            oGTTDate: dietModel.oGTTDate,
            outsideTest: dietModel.outsideTest,
            dietPreference: dietModel.dietPreference,
            giHealth: dietModel.giHealth,
            dental: dietModel.dental,
            anyOtherConerns: dietModel.anyOtherConerns,
            weighttLossIn1stTrimester: dietModel.weighttLossIn1stTrimester,
            milk: dietModel.milk,
            nuts: dietModel.nuts,
            likes: dietModel.likes,
            dislikes: dietModel.dislikes,
            other1: dietModel.other1,
            wakeUpTime: dietModel.wakeUpTime,
            sleepingTime: dietModel.sleepingTime,
            morningTime: dietModel.morningTime,
            morningMilk: dietModel.morningMilk,
            dryfruitsNuts: dietModel.dryfruitsNuts,
            breakfasttime: dietModel.breakfasttime,
            breakfast: dietModel.breakfast,
            vegCurry: dietModel.vegCurry,
            cooked: dietModel.cooked,
            dal: dietModel.dal,
            breakfastOther: dietModel.breakfastOther,
            midMorningtime: dietModel.midMorningtime,
            fruits: dietModel.fruits,
            fruitsJuice: dietModel.fruitsJuice,
            tea: dietModel.tea,
            midMorningOther: dietModel.midMorningOther,
            lunchtime: dietModel.lunchtime,
            carealsMilletS: dietModel.carealsMilletS,
            lunchvegCurry: dietModel.lunchvegCurry,
            greenLeafy: dietModel.greenLeafy,
            lunchdal: dietModel.lunchdal,
            panner: dietModel.panner,
            lunchNonVeg: dietModel.lunchNonVeg,
            curd: dietModel.curd,
            lunchOther: dietModel.lunchOther,
            eveningtime: dietModel.eveningtime,
            eveningfruits: dietModel.eveningfruits,
            eveningfruitsJuice: dietModel.eveningfruitsJuice,
            eveningSamosa: dietModel.eveningSamosa,
            eveningBakery: dietModel.eveningBakery,
            eveningOther: dietModel.eveningOther,
            dinnertime: dietModel.dinnertime,
            dinnercarealsMilletS: dietModel.dinnercarealsMilletS,
            dinnervegCurry: dietModel.dinnervegCurry,
            dinnergreenLeafy: dietModel.dinnergreenLeafy,
            dinnerdal: dietModel.dinnerdal,
            dinnerpanner: dietModel.dinnerpanner,
            ldinnerNonVeg: dietModel.ldinnerNonVeg,
            dinnercurd: dietModel.dinnercurd,
            dinnerOther: dietModel.dinnerOther,
            bedtime: dietModel.bedtime,
            bedTimeMilk: dietModel.bedTimeMilk,
            bedtimeDryFruits: dietModel.bedtimeDryFruits,
            bedTimeOther: dietModel.bedTimeOther,
            oftenyouEatSamosa: dietModel.oftenyouEatSamosa,
            typeOfFood: dietModel.typeOfFood,
            inclusionOfFruitJuice: dietModel.inclusionOfFruitJuice,
            qualityFruitJuice: dietModel.qualityFruitJuice,
            qualityFruitJuiceMl: dietModel.qualityFruitJuiceMl,
            carbonatedDrink: dietModel.carbonatedDrink,
            carbonated: dietModel.carbonated,
            highSugarDrinkMore: dietModel.highSugarDrinkMore,
            highSugarDrink: dietModel.highSugarDrink,
            inclusionOfProcessedJunk: dietModel.inclusionOfProcessedJunk,
            moreThanOfDiet: dietModel.moreThanOfDiet,
            intakeOfFruits: dietModel.intakeOfFruits,
            typeOfMilkCurd: dietModel.typeOfMilkCurd,
            waterIntake: dietModel.waterIntake,
            totalOilUsed: dietModel.totalOilUsed,
            typeOfOil: dietModel.typeOfOil,
            gheeButter: dietModel.gheeButter,
            beansGrams: dietModel.beansGrams,
            beansGramsYes: dietModel.beansGramsYes,
            qualitydal: dietModel.qualitydal,
            qualityGlv: dietModel.qualityGlv,
            qualityMutton: dietModel.qualityMutton,
            qualityChicken: dietModel.qualityChicken,
            qualityFish: dietModel.qualityFish,
            qualityEgg: dietModel.qualityEgg,
            activityPattern: dietModel.activityPattern,
            physicalActivity: dietModel.physicalActivity,
            walking: dietModel.walking,
            cookingHouseholdWork: dietModel.cookingHouseholdWork,
            sittingOrReclining: dietModel.sittingOrReclining,
            exercise: dietModel.exercise,
            chutney: dietModel.chutney,
            quantity: dietModel.quantity,
            coconutchutney: dietModel.coconutchutney,
            Veg: dietModel.Veg,
            peanut: dietModel.peanut,
            giHealthYes: dietModel.giHealth,
            morningMilkSugar: dietModel.morningMilkSugar,
            morningMilkBoost: dietModel.morningMilkBoost,
            breakfastIdli: dietModel.breakfastIdli,
            breakfastDosa: dietModel.breakfastDosa,
            breakfastRoti: dietModel.breakfastRoti,
            breakfastBread: dietModel.breakfastBread,
            breakfastSliceOther: dietModel.breakfastSliceOther,
            fruitType: dietModel.fruitType,
            fruitJuiceQua: dietModel.fruitJuiceQua,
            fruitJuiceOther: dietModel.fruitJuiceOther,
            tesQua: dietModel.tesQua,
            lunceBrownRice: dietModel.lunceBrownRice,
            lunceMillets: dietModel.lunceMillets,
            lunceWheatrawa: dietModel.lunceWheatrawa,
            lunceChapathi: dietModel.lunceChapathi,
            luncePulka: dietModel.luncePulka,
            lunchvegCooked: dietModel.lunchvegCooked,
            greenLeafyCooked: dietModel.greenLeafyCooked,
            lunchdalCooked: dietModel.lunchdalCooked,
            pannerPcs: dietModel.pannerPcs,
            pannerOther: dietModel.pannerOther,
            lunchNonVegPcs: dietModel.lunchNonVegPcs,
            lunchNonVegOther: dietModel.lunchNonVegOther,
            lunchcurd: dietModel.lunchcurd,
            eveningfruitsOther: dietModel.eveningfruitsOther,
            eveningfruitJuice: dietModel.eveningfruitJuice,
            eveningSamosaName: dietModel.eveningSamosaName,
            eveningBakeryName: dietModel.eveningBakeryName,
            totalOilUsedOthers: dietModel.totalOilUsedOthers,
            otherOil: dietModel.otherOil,
            anyOther: dietModel.anyOther,
            gheeButterYes: dietModel.gheeButterYes,
            gheeButterOthers: dietModel.gheeButterOthers,
            dinnergreenLeafOpts: dietModel.dinnergreenLeafOpts,
            nutritionalScreening: dietModel.nutritionalScreening,
            pamphaletOfHealthyFood: dietModel.pamphaletOfHealthyFood,
            nutritionalRisk: dietModel.nutritionalRisk,
            counselledByNutritionist: dietModel.counselledByNutritionist,
            dietRecommened: dietModel.dietRecommened,
            leafletGivenDietPlan: dietModel.leafletGivenDietPlan,
            prescribedCalories: dietModel.prescribedCalories,
            prescribedProtien: dietModel.prescribedProtien,
            signature: dietModel.signature,
            date: dietModel.date,
            prePregnancyWt: dietModel.prePregnancyWt,
            dietPreferenceWith: dietModel.dietPreferenceWith,
            teaQua: dietModel.teaQua,
            dinnerRice: dietModel.dinnerRice,
            breakfastOats: dietModel.breakfastOats,
            breakfastOatsOther: dietModel.breakfastOatsOther,
            breakfastSlice: dietModel.breakfastSlice,
            lunceRice: dietModel.lunceRice,
            dinnerBrownRice: dietModel.dinnerBrownRice,
            dinnerMillets: dietModel.dinnerMillets,
            dinnerWheatrawa: dietModel.dinnerWheatrawa,
            dinnerdalopts: dietModel.dinnerdalopts,
            dinnerpannerPCs: dietModel.dinnerpannerPCs,
            ldinnerNonVegPcs: dietModel.ldinnerNonVegPcs,
            ldinnerNonVegOther: dietModel.ldinnerNonVegOther,
            bedTimeMilkOpts: dietModel.bedTimeMilkOpts,
            dinnerChapathi: dietModel.dinnerChapathi,
            dinnervegCooked: dietModel.dinnervegCooked,
            dinnerPulka: dietModel.dinnerPulka,
            bedTimeMilkSugar: dietModel.bedTimeMilkSugar,
            bedTimeMilkBoost: dietModel.bedTimeMilkBoost,
            intakeOfWholeGrains: dietModel.intakeOfWholeGrains,
            intakeOfWholeGrainsQuantity: dietModel.intakeOfWholeGrainsQuantity,
            lowVegetableIntake: dietModel.lowVegetableIntake,
            intakeFruits: dietModel.intakeFruits,
            waterIntakeOther: dietModel.waterIntakeOther,
            others: dietModel.others,
            qualityMuttontake: dietModel.qualityMuttontake,
            eggWhite: dietModel.eggWhite,
            qualityFishtake: dietModel.qualityFishtake,
            eggWhole: dietModel.eggWhole,
            waterIntakeOthers: dietModel.waterIntakeOthers,
            qualityChickentake: dietModel.qualityChickentake,
            bmiCategorization: dietModel.bmiCategorization,
            qualitydalYes: dietModel.qualitydalYes,
            sittingOthers: dietModel.sittingOthers,
            specialFeaturesForCare: dietModel.specialFeaturesForCare,
            lunceroti: dietModel.lunceroti,
            dinnerpannerOthers: dietModel.dinnerpannerOthers,
            qualityPaneerYes: dietModel.qualityPaneerYes,
            qualityPaneer: dietModel.qualityPaneer,
            dietRecommenedOthers: dietModel.dietRecommenedOthers,
            qualityMuttonOther: dietModel.qualityMuttonOther,
            cookingHouseholdWorkOthers: dietModel.cookingHouseholdWorkOthers,
            eveningfruitsJuiceOther: dietModel.eveningfruitsJuiceOther,
            dinnerRoti: dietModel.dinnerRoti,
            morningMilkOpt: dietModel.morningMilkOpt,
            dinnerWheatRava: dietModel.dinnerWheatRava


        })

    }

    getDateFormat(val: any) {
        const myarr = val.split("-")
        let year = myarr[0];
        let month = myarr[1];
        let day = myarr[2];
        let newdate = day + "/" + month + "/" + year;

        return newdate;
    }


    get form() {
        return this.opNutritionalAssessment.controls;
    }

    private fetchGPLA(appointmentId: any) {
        const request = Object.assign(UtilHelper.clone({ encryptedAppointmentId: appointmentId }));
        this.httpService.post(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.findgpla), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.gpla = response;
                if (this.gpla != null) {
                    if (this.gpla.gynHistory || this.gpla.measureCommonData) {
                        const gynHistory = JSON.parse(this.gpla.gynHistory);
                        this.measureData = JSON.parse(this.gpla.measureCommonData)
                        this.gplaData = gynHistory
                        this.opNutritionalAssessment.patchValue({
                            gpla: this.gplaData != null ? [this.gplaData.gravida, this.gplaData.para, this.gplaData.living, this.gplaData.abortion].filter(Boolean).join(", ") : null,
                            gestationage: this.gplaData != null ? this.gplaData.ga : null,
                            heightInFeet: this.measureData != null ? this.measureData.heightInFeet : null,
                            heightInMeter: this.measureData != null ? this.measureData.heightInMeters : null,
                            prePregnancyWt: this.measureData != null ? this.measureData.weight : null,
                            prePregnancybmi: this.measureData != null ? this.measureData.bodyMassIndex : null,
                            prePregBmiCategory: this.measureData != null ? this.measureData.bmiCategorization : null
                        });

                    }

                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    ngOnInit() {
        const url = this.router.url;
        var route = url.includes("patientForm")
        if (route) {
            this.patientForm=true
            const appointmentId = decodeURIComponent(url.split("/")[3]);
            this.appointmentId = url.split("/")[3];
            this.routingValue = url.split("/")[2]
            this.isAdmission = url.split("/")[4] === "a";
            this.encryptedAppointmentId = this.appointmentId;
            this.appointmentId = appointmentId;           
            this.fetchGPLA(appointmentId);
            this.findDashboard();
        }
        else {
            this.patientForm = false
            this.appData.userAccount
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe((userAccount: IUserAccount) => {
                    if (userAccount) {
                        this.page.userAccount = userAccount;
                        this.roleName = this.page.userAccount.roleName;
                        const url = this.router.url;
                        const appointmentId = decodeURIComponent(url.split("/")[3]);
                        this.appointmentId = url.split("/")[3];
                        this.routingValue = url.split("/")[2]
                        this.isAdmission = url.split("/")[4] === "a";
                        this.encryptedAppointmentId = this.appointmentId;
                        this.appointmentId = appointmentId;
                        this.fetchGPLA(appointmentId);
                        this.findDashboard();
                        this.route.params
                            .pipe(takeUntil(this.page.unSubscribe))
                            .subscribe(async (params: Params) => {
                                const section = params["section"];
                                this.section = section ? section : undefined;
                                this.nextRoute = await this.menuservice.getNextRoute(url, params, this.route);

                            });
                    } else {
                        this.page.userAccount = undefined;
                    }
                });
        }
    }
    /* weightValidate(formcontrol: string) {  
         if (this.opNutritionalAssessment.controls[formcontrol].invalid) {
             this.opNutritionalAssessment.patchValue({
                 [formcontrol]: null
             })
         }
     }*/
    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[DietEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.dietEncounter.base, ApiResources.dietEncounter.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [DietEncounter, Appointment, PreviousAppointment]) => {
             this.dietEncounters = response["dashboard"];
                if (this.dietEncounters != null) {
                    this.measure = this.dietEncounters.measure ? JSON.parse(this.dietEncounters.measure) : null;
                    this.specialFeature = this.dietEncounters.specialFeature ? JSON.parse(this.dietEncounters.specialFeature) : null;
                    if (this.specialFeature) {
                        this.opNutritionalAssessment.patchValue({
                            specialFeatures: this.specialFeature.specialFeature,
                            specialFeaturesForCare: this.specialFeature.specialFeature
                        })
                    }
                    if (this.dietEncounters.dietPlanPrescribed != null) {
                        this.dietPlanPrescribed = this.dietEncounters.dietPlanPrescribed ? JSON.parse(this.dietEncounters.dietPlanPrescribed) : null;
                        this.opNutritionalAssessment.patchValue({
                            heightInFeet: this.dietPlanPrescribed && this.dietPlanPrescribed.height ? this.dietPlanPrescribed.height : null,
                            heightInMeter: this.dietPlanPrescribed && this.dietPlanPrescribed.heightInMeter ? this.dietPlanPrescribed.heightInMeter : null,
                            prePregnancyWt: this.dietPlanPrescribed && this.dietPlanPrescribed.prePregnancy1 ? this.dietPlanPrescribed.prePregnancy1 : null,
                            // preBMICategtory: this.opNutrition && this.opNutrition.preBMICategtory ? this.opNutrition.preBMICategtory : this.dietPlanPrescribed && this.dietPlanPrescribed.prePregnancy ? this.dietPlanPrescribed.prePregnancy : null,
                            prePregnancybmi: this.dietPlanPrescribed && this.dietPlanPrescribed.prePregnancy ? this.dietPlanPrescribed.prePregnancy : null,
                        })
                    }
                      this.prePregnancyWt = this.dietPlanPrescribed && this.dietPlanPrescribed.prePregnancy1 ? this.dietPlanPrescribed.prePregnancy1 : this.measureData && this.measureData.weight ? this.measureData.weight : 0;
                   if (this.measure !== null) {
                        this.opNutritionalAssessment.patchValue({
                            presentWeight: this.measure.weight ? this.measure.weight : null,
                            presentBmi: this.measure.bodyMassIndex ? this.measure.bodyMassIndex : null,
                            bmiCategorization: this.measure.bmiCategorization ? this.measure.bmiCategorization : null
                        })
                        this.presentweight = this.measure.weight;
                    }
                    this.findDiet(this.appointmentId);
                }

            });



    }
    onCaluclateweightLoss(event: any) {
        var weight;
        var weightLoss = this.opNutritionalAssessment.get('weighttLossIn1stTrimester').value;
        if (event == 'Yes' && (weightLoss != null && weightLoss != undefined)) {
            weight = this.presentweight - weightLoss;
        }
        else if ((event == 'No' || event == 'Yes') && (weightLoss == null || weightLoss == undefined)) {
            weight =  this.presentweight -this.prePregnancyWt 
        }
        else {
            weight = this.prePregnancyWt - this.presentweight
        }
        if (weight <= 0) {
            this.opNutritionalAssessment.patchValue({
                wtLoss: Math.abs(weight),
                wtGain: null,
            })
        }
        else if (weight > 0) {
            this.opNutritionalAssessment.patchValue({
                wtGain: Math.abs(weight),
                wtLoss: null
            })
        }

    }
}

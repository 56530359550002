import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { finalize } from "rxjs/operators";
import { HttpService } from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { PharmacyStore } from "@shared/entities";

@Component({
    templateUrl: "./pharmacy-header.html",
    selector: "pharmacy-header"
})

export class PharmacyHeaderWidget implements OnInit, OnDestroy {
    @Input() id?: number = null;
    @Input() locationId?: number = null;
    loading: boolean;
    store: PharmacyStore;

    constructor(
        private readonly httpService: HttpService
    ) {
        this.store = new PharmacyStore();
    }

    private fetchAppliedSettings() {
        this.loading = true;
        const request = {
            id: this.id,
            requestId: this.locationId
        };
        this.httpService
            .get(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.getAppliedPharmacySettings), request)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: PharmacyStore) => {
                    this.store = response;
                },
                () => {
                    this.store = new PharmacyStore();
                }
            );
    }

    ngOnInit() {
        this.loading = true;
        this.fetchAppliedSettings();
    }

    ngOnDestroy() {

    }
}

import { LabParameterGroup } from "./lab-parameter-group.model";

export class LabParameterHeader {
    labParameterHeaderId: number;
    totalItems: number;
    createdBy: number;
    modifiedBy?: number;
    parameterName: string;
    displayName: string;
    referenceOutput: string;
    type: string;
    createdByName: string;
    modifiedByName: string;
    createdDate: Date;
    modifiedDate?: Date;
    active: boolean;
    isLoading: boolean;
    labParameterMethodId?: number;
    methodName: string;
    text: string;
    machineId: number;
    machineParameterName: string;
    parameterGroup: Array<LabParameterGroup>;
    parameterId: string;
    createdByRole: string;
    modifiedByRole: string;
    units: string;
    result: string;
    referenceRange: string;
    defaultValue: string;
    fromLIS: boolean;
    constructor() {
        this.parameterGroup = new Array<LabParameterGroup>();
    }
}
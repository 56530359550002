export class Register {
    active: boolean;
    admissionId: number;
    createdBy: number;
    createdDate: Date;
    dayCareAD: string;
    emergencyRegister: string;
    ffSemiPrivateAD: string;
    firstFloorAD: string;
    fromDate: string;
    groundFloorAD: string;
    icnRegister: string;
    jsonString: string;
    kmcAdmissionRegister: string;
    labourWardAllRegister: string;
    labourWardRegister: string;
    locationId: number;
    midWifeRegister: string;
    modifiedBy: number;
    modifiedDate: Date;
    nicuDeliveryRegister: string;
    nicuOpRegister: string;
    nicuRegister: string;
    otAllRegister: string;
    otRegister: string;
    qualityIndicatorRegister: string;
    registerId: number;
    secondFloorAD: string;
    thirdFloorAD: string;
    thirdFloorAllLW: string;
    thirdFloorLW: string;
    toDate: string;
    type: number;
}
export enum OBEncounterType {
    ProblemList = 1,
    Vitals,
    BriefRos,
    GeneticScreening,
    InfectionHistory,
    ANCCaredClosure,
    BreastExamination,
    AncCardGeneration,
    Complaints,
    OrderPrescription,
    ObstetricExamination,
    VaginalExamination,
    PlanOfManagement,
    DrugHistory,
    MenstrualHistory,
    OtherDetail,
    vteRisk,
    birthHistory,
    procedureForm,
    sgaScanning,
    surgeries,
    antinantalRisk,
    vaccine,
    PostnanatalVisit,
    Measure,
    GeneralExamination,
    Breech,
    AncCards,
    Covid,
    AnaesthesiaRecord,
    PrepregnancyCounseling,

    Allergies,
    familyHistory,
    outPatientManagement,
    PrePregnancyPlan,
    SpeacialFeature,
    ReferralForm,
    RefferalOrder,
    AdmissionSlip,
    MotherWithEpilepsy,
    ExternalCephalicVersion,
    DischargeSummary,
    Syndromes,
    OPManagement,
    NurseAssessment,
    OutSideTests,
    MeasureCommonData,
    AncCardCloseCommonData,
    NeonatalAssessmentForm,
    ScanAppointmentDetails,
    InductionForm,
    NormalDeliveryForm,
    LSCS,
    AdmissionDetails,
    IUFDEntryForm,
    DoctorsReviewForm, Reminder,
    RiskCategoryAtAdm,
    AddendumForm,
    CIMSAllergy,
    Confidential,
    FutureAppointments,
    IOLList,
    OtAppointmentDetails,
    TelePhoneTriageAssessment,
    ScanTests,
    AldreteScore,
}

export class PatientFamily {
    patientFamilyId: number;
    patientId: number;
    fullName: string;
    age: any;
    relativeGender: string;
    relation: string;
    active: boolean;
    patientName: string;
    createdBy: number;
    contactNo: string;
    education: string;
    occupation: string;
    occupationDetails: string;
    dob?: Date;
    relativeBloodGroup: string;
    patientsOrganization: string;
}
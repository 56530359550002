<div class="row p-1">
    <div class="col-12" *ngIf="loading">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading ...</span>
        </div>
    </div>
    <div class="col-12" *ngIf="!loading && (!parameters || !parameters.templates)">
        <div class="alert alert-warning">
            <i class="mdi mdi-information-variant mr-1"></i> Nothing to show....!!!
        </div>
    </div>
    <div class="col-12" *ngIf="!loading && parameters && parameters.templates.length > 0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between bg-pattern">
                    <div>
                        <div class="media mt-0 mb-0">
                            <div class="media-body mt-0 mb-0">
                                <h5 class="mb-0 text-capitalize">{{parameters.testName}}</h5>
                                <p class="font-12 mb-0 text-capitalize">{{parameters.testCode}}</p>
                                <small class="mb-0 text-capitalize">{{parameters.departmentName}}</small>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-outline-danger btn-sm" (click)="onClosePopup()">
                            <i class="mdi mdi-close mr-1"></i> Close
                        </button>
                    </div>
                </div>
                <hr class="mb-0" />
            </div>
            <div class="col-12" *ngFor="let template of parameters.templates;index as t;">
                <div class="card mb-1 shadow-none border bg-pattern">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <div class="avatar-sm">
                                    <span class="avatar-title badge-soft-primary text-primary rounded">
                                        <i class="mdi mdi-link-plus mdi-18px"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col ps-0">
                                <a href="javascript:void(0);" class="font-14 fw-bold">{{template.templateName}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="max-height:480px; overflow:auto;">
                    <div class="col-12" *ngIf="template.parameters && template.parameters.length > 0" style="max-height:600px; overflow:auto;">
                        <div class="headingStyle">
                            <div class="trapezium font-weight-semibold text-white">
                                <span class="p-1"><i class="mdi mdi-chart-gantt me-1"></i> <span>Direct Parameters</span></span>
                            </div>
                        </div>
                        <div class="card p-2">
                            <div class="row" *ngFor="let parameter of template.parameters;index as p;">
                                <div class="row col-12" *ngIf="parameter.type === 'Regular'">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="mb-0"> {{parameter.parameterName}} <code>*</code></label>
                                            <input *ngIf="parameter.referenceOutput === 'numeric'" class="form-control" placeholder="Enter {{parameter.displayName}}" type="text" [(ngModel)]="parameter.observedValue" [ngStyle]="{'color': parameter.isBold ? 'red':'black'}" (keyup)="onChange()" (input)="changeTextColor(parameter)" />
                                            <textarea rows="2" *ngIf="parameter.referenceOutput === 'text'" class="form-control" (keyup)="onChange()" placeholder="Enter {{parameter.displayName}}" [(ngModel)]="parameter.observedValue"></textarea>
                                            <select *ngIf="parameter.referenceOutput === 'selection'"  (change)="onChange()" class="form-control custom-select form-control-sm" autocomplete="nope" [(ngModel)]="parameter.observedValue">
                                                <option [ngValue]="null">None</option>
                                                <option *ngFor="let item of parameter.allParameterDetail" [textContent]="item.selectionName" [ngValue]="item.selectionName"></option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="mb-0"> Range<code>*</code></label>
                                            <div class="p-0 mb-0" *ngIf="parameter.referenceOutput === 'numeric' && parameter.type === 'Regular'">
                                                <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId">
                                                    {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}&nbsp;
                                                    <i class="fe-edit" style="cursor: pointer;" (click)="onOpenForParameterSelection(templateReferenceRange,parameter)"></i>
                                                </h5>
                                                <div *ngIf="!parameter.labParameterDetailId">
                                                    <button type="button" class="btn btn-sm waves-effect waves-light"
                                                            (click)="onOpenForParameterSelection(templateReferenceRange,parameter)">
                                                        Choose
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-0 mb-0" *ngIf="parameter.referenceOutput != 'numeric' && parameter.type === 'Regular'">
                                                <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId" [innerHTML]="parameter.text ||'-'">
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label class="mb-0"> Unit <code>*</code></label>
                                            <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId" [textContent]="parameter.selected.unitName || '--'">
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-12" *ngIf="parameter.type != 'Regular'">
                                    <div class="col-12 d-flex">
                                        <div class="form-group col-2">
                                            <label class="mb-0 text-capitalize text-primary"> {{parameter.parameterName}}</label>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group mb-0 position-relative">
                                                <ng-select class="ng-select-sm" [items]="labAntibiotics"
                                                           bindLabel="antibioticName"
                                                           bindValue="antibioticName"
                                                           typeToSearchText="Search Antibiotic"
                                                           placeholder="Enter Antibiotic name"
                                                           (change)="onClickMicroParameter($event ,t ,p)">
                                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                        No parameter found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.antibioticName"></h5>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-12 mt-2" *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                        <div class="row form-group col-12">
                                            <label class="mb-0 col-4"> {{antibiotic.antibioticName}}</label>
                                            <select (change)="onChange()" class="form-control custom-select form-control-sm col-4" autocomplete="nope" [(ngModel)]="antibiotic.observedValue">
                                                <option [ngValue]="null">None</option>
                                                <option *ngFor="let item of labAntibioticsResultTypes" [textContent]="item.name" [ngValue]="item.name"></option>
                                            </select>
                                            <!--<input class="form-control col-4" placeholder="Enter {{antibiotic.antibioticName}}" [(ngModel)]="antibiotic.observedValue" />-->
                                            <div *ngIf="!antibiotic.labParameterobservedValueId">
                                                <button type="button" class="btn btn-sm waves-effect waves-light"
                                                        (click)="removeAntibiotic(parameter,j , t , p)">
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngFor="let component of template.components;index as c;">
                        <div class="headingStyle">
                            <div class="trapezium font-weight-semibold text-white">
                                <span class="p-1"><i class="mdi mdi-chart-gantt me-1"></i> <span [textContent]="component.componentName"></span></span>
                            </div>
                        </div>
                        <div class="card p-2">
                            <div class="row" *ngFor="let parameter of component.parameters;index as p;">
                                <div class="row col-12" *ngIf="parameter.type === 'Regular'">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="mb-0"> {{parameter.parameterName}} <code>*</code></label>
                                            <input *ngIf="parameter.referenceOutput === 'numeric'" class="form-control" placeholder="Enter {{parameter.displayName}}" type="text" [(ngModel)]="parameter.observedValue" [ngStyle]="{'color': parameter.isBold ? 'red':'black'}" (keyup)="onChange()" (input)="changeTextColor(parameter)" />
                                            <textarea rows="2" *ngIf="parameter.referenceOutput === 'text'" class="form-control" (keyup)="onChange()" placeholder="Enter {{parameter.displayName}}" [(ngModel)]="parameter.observedValue"></textarea>
                                            <select *ngIf="parameter.referenceOutput === 'selection'"  (change)="onChange()" class="form-control custom-select form-control-sm" autocomplete="nope" [(ngModel)]="parameter.observedValue">
                                                <option [ngValue]="null">None</option>
                                                <option *ngFor="let item of parameter.allParameterDetail" [textContent]="item.selectionName" [ngValue]="item.selectionName"></option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="mb-0"> Range<code>*</code></label>
                                            <div class="p-0 mb-0" *ngIf="parameter.referenceOutput == 'numeric' && parameter.type === 'Regular'">
                                                <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId">
                                                    {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}&nbsp;
                                                    <i class="fe-edit" style="cursor: pointer;" (click)="onOpenForParameterSelection(templateReferenceRange,parameter)"></i>
                                                </h5>
                                                <div *ngIf="!parameter.labParameterDetailId">
                                                    <button type="button" class="btn btn-sm waves-effect waves-light"
                                                            (click)="onOpenForParameterSelection(templateReferenceRange,parameter)">
                                                        Choose
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-0 mb-0" *ngIf="parameter.referenceOutput != 'numeric' && parameter.type === 'Regular'">
                                                <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId" [innerHTML]="parameter.text ||'-'">
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label class="mb-0"> Unit <code>*</code></label>
                                            <h5 class="mt-0 mb-0" *ngIf="parameter.labParameterDetailId" [textContent]="parameter.selected.unitName || '--'">
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-12" *ngIf="parameter.type != 'Regular'">
                                    <div class="col-12 d-flex">
                                        <div class="form-group col-2">
                                            <label class="mb-0 text-capitalize text-primary"> {{parameter.parameterName}}</label>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group mb-0 position-relative">
                                                <ng-select class="ng-select-sm" [items]="labAntibiotics"
                                                           bindLabel="antibioticName"
                                                           bindValue="antibioticName"
                                                           typeToSearchText="Search Antibiotic"
                                                           placeholder="Enter Antibiotic name"
                                                           (change)="onClickMicroParameter($event ,t ,p ,c)">
                                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                        No parameter found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.antibioticName"></h5>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-12" *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                        <div class="row form-group col-12">
                                            <label class="mb-0 col-4"> {{antibiotic.antibioticName}}</label>
                                            <select (change)="onChange()" class="form-control custom-select form-control-sm col-4" autocomplete="nope" [(ngModel)]="antibiotic.observedValue">
                                                <option [ngValue]="null">None</option>
                                                <option *ngFor="let item of labAntibioticsResultTypes" [textContent]="item.name" [ngValue]="item.name"></option>
                                            </select>
                                            <!--<input class="form-control col-4" placeholder="Enter {{antibiotic.antibioticName}}" [(ngModel)]="antibiotic.observedValue" />-->
                                            <div *ngIf="!antibiotic.labParameterobservedValueId">
                                                <button type="button" class="btn btn-sm waves-effect waves-light"
                                                        (click)="removeAntibiotic(parameter,j , t , c)">
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-top: -20px !important;" *ngIf="template.isMethod || template.isInterpretation">
                        <div class="card-box">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="template.isMethod">
                                    <div class="form-group">
                                        <label class="mb-1 font-16 font-weight-bold">Method <code>*</code></label>
                                        <div>
                                            <ckeditor [editor]="notesEditor" (keyup)="onChange()" [config]="config" placeholder="Enter method text" [(ngModel)]="template.methodText"></ckeditor>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="template.isInterpretation">
                                    <div class="form-group">
                                        <label class="mb-1 font-16 font-weight-bold">Interpretation/Result <code>*</code></label>
                                        <div>
                                            <ckeditor [editor]="notesEditor" (keyup)="onChange()" [config]="config" placeholder="Enter interpretation text" [(ngModel)]="template.interpretationText"></ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <button *ngIf="isHaveParameters" class="btn btn-sm btn-outline-danger"
                            (click)="OpenReasonView(templateReason)">
                        Delete Parameters
                    </button>
                    <button *ngIf="!isHaveParameters" class="btn btn-sm btn-outline-danger"
                            (click)="onFetchInputParameters()">
                        Remove Parameters
                    </button>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-sm btn-outline-primary" *ngIf="!showDocument"
                                (click)="onOpenModalMultiple(templateMultipleUploadDocument)">
                            Upload Document
                        </button>
                        <button class="btn btn-sm btn-outline-primary" *ngIf="showDocument"
                                (click)="onViewDocument(templateUploadDocument)">
                            Upload & View Document
                        </button>
                        <div *ngIf="ShowReport">
                            <button class="btn btn-secondary btn-sm ml-1" (click)="onOpenLabReportModel(reportView)">
                                <i class="mdi mdi-file-document mr-1"></i> View Report
                            </button>
                        </div>
                        <div *ngIf="ShowReport">
                            <button class="btn btn-primary btn-sm ml-1" (click)="viewPreviousResultsAdded(showPreviousResults,newLabBookingDetailId)">
                                <i class="mdi mdi-check mr-1"></i> All Results
                            </button>
                        </div>
                        <button class="btn btn-danger btn-sm ml-1" (click)="onClosePopup()">
                            <i class="mdi mdi-close mr-1"></i> Close
                        </button>
                        <button type="button" *ngIf="!verifying" [disabled]="showButton" (click)="onSubmitparameters()" class="btn btn-sm btn-primary">
                            <span *ngIf="!submitting">
                                <i class="mdi mdi-check mr-1"></i><span *ngIf="verifyAndSubmit">Verify and Submit</span>
                                <span *ngIf="!verifyAndSubmit">Submit</span>
                            </span>
                            <span *ngIf="submitting">
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Please wait..
                            </span>
                        </button>
                        <button *ngIf="verifying" class="btn btn-sm btn-primary">
                            <span>
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Please wait..
                            </span>
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>

<ng-template #templateReferenceRange>
    <div class="modal-header d-flex justify-content-between">
        <div>
            <h4 class="modal-title">Lab Parameters Group</h4>
        </div>
        <div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
    </div>
    <div class="modal-body">
        <table class="table table-centered table-sm table-striped table-bordered mb-0 heading1" style="padding:0px !important">
            <thead class="table-user">
                <tr>
                    <th>S.No</th>
                    <th>RangeText</th>
                    <th>Gender</th>
                    <th>FromAge</th>
                    <th>ToAge</th>
                    <th>MinValue</th>
                    <th>MaxValue</th>
                    <th>Min Critical</th>
                    <th>Max Critical</th>
                    <th>Unit</th>
                    <th class="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of selectedParameter.allParameterDetail;index as i;">
                    <td>#{{i+1}}</td>
                    <td>
                        <span [textContent]="group.rangeText"></span>
                    </td>
                    <td>
                        <span [textContent]="group.gender"></span>
                    </td>
                    <td>
                        <span [textContent]="group.fromAge"></span>&nbsp;<span [textContent]="group.fromAgeType"></span>
                    </td>
                    <td>
                        <span [textContent]="group.toAge"></span>&nbsp;<span [textContent]="group.toAgeType"></span>
                    </td>
                    <td>
                        <span [textContent]="group.minValue"></span>
                    </td>
                    <td>
                        <span [textContent]="group.maxValue"></span>
                    </td>
                    <td>
                        <span [textContent]="group.minCriticalValue || '--'"></span>
                    </td>
                    <td>
                        <span [textContent]="group.maxCriticalValue || '--'"></span>
                    </td>
                    <td>
                        <span [textContent]="group.unitName || '--'"></span>
                    </td>
                    <td>
                        <button type="button" class="btn btn-sm btn-outline-primary" (click)="onSelectParameter(group)"><i class="mdi mdi-check"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
<ng-template #reportView>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="modal-body">
        <new-lab-report [encryptedNewLabBookingDetailId]="encryptedNewLabBookingDetailId" (onClose)="onCloseModal()"></new-lab-report>
    </div>
</ng-template>

<ng-template #templateUploadDocument>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-upload mr-1"></i>View Document and Upload Document</h4>
        <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModalView()">×</button>
    </div>
    <div class="row">
        <div class="modifying-content" *ngIf="modifying">
            <p>Please wait while {{modifyingContent}}.</p>
        </div>
        <div class="col-lg-4 mb-3" *ngFor="let item of documents">
            <div class="document-container">
                <div class="document-thumbnail">
                    <a href="javascript:;" (click)="onOpenModalView(templateViewDocument,item)"><img src="assets/images/documentImage1.png" [alt]="item.documentName" /></a>
                </div>
                <div class="document-body">
                    <h4><a href="javascript:;" (click)="onOpenModalView(templateViewDocument,item)" class="text-dark" [textContent]="item.documentName"></a></h4>
                    <h6 [textContent]="item.documentType"></h6>
                    <p class="text-lowercase"><span [textContent]="item.size | formatBytes"></span> - <span class="text-primary" [textContent]="item.uploadedDate | date:'dd-MM-yyyy hh:mm a'"></span></p>
                </div>
                <div class="document-actions">
                    <div *ngIf="item.uploadedBy === page.userAccount.accountId">
                        <a href="javascript:;" placement="left" ngbTooltip="Delete" (click)="onDelete(item)"><i class="mdi mdi-trash-can-outline"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mh-300">
            <div class="no-data">
                <button class="btn btn-sm btn-outline-primary"
                        (click)="onOpenModalMultiple(templateMultipleUploadDocument)">
                    Upload Document
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #templateMultipleUploadDocument>
    <form [formGroup]="uploadForm" (ngSubmit)="onUploadDocument()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Upload Documents</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onMutipleDocumetCloseModel()">×</button>
        </div>
        <div class="modal-body">
            <div class="row appointment-form bg-pattern bg-light mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="mb-1">Document Name <code>*</code></label>
                        <input type="text" autofocus formControlName="documentName" maxlength="100" block autocomplete="nope" class="form-control" placeholder="Enter document name" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label class="mb-1">Description</label>
                        <textarea rows="1" cols="20" formControlName="description" block maxlength="250" autocomplete="nope" class="form-control" placeholder="Enter document description"></textarea>
                    </div>
                </div>
            </div>
            <file-dropper [expandable]="true"
                          [maxFiles]="maxFiles"
                          [maxFileSize]="10000"
                          [accept]="'application/pdf, image/jpeg, image/pjpeg, image/png, image/gif, video/mp4'"
                          (onSelectEmitter)="onFileSelect($event)">
            </file-dropper>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Upload Document</span>
            </button>
        </div>
    </form>
</ng-template>
<ng-template #showPreviousResults>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Amendment's </h4>
        <div>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="modal-body" *ngIf="loadingObservedValues">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while Provider details....</span>
        </div>
    </div>
    <div class="modal-body">
        <div>
            <h5 class="badge badge-outline-primary"> <i class="mdi mdi-information mr-1 text-primary "></i>The Final result will Appear on Right side of screen </h5>
        </div>
        <div class="d-flex border font-15 font-weight-bold" *ngIf="!loadingObservedValues">
            <div class="p-1">
                <div *ngFor="let i of parameterNames" class="p-1 white-space-nowrap border-bottom">
                    {{i.parameterName}}
                </div>
                <br />
                <div>UpdatedBy</div>
            </div>

            <div class="overflow-auto d-flex flex-grow-1 pr-1" *ngIf="totalObservedValues2">
                <div *ngFor="let item of totalObservedValues2.slice().reverse() " class="border-left flex-grow-1 p-1">
                    <div class="flex-grow-1" [ngClass]="{'text-success':item[0].active}">
                        <div *ngFor="let each of item" class="p-1 border-bottom">
                            <div [ngStyle]="{'color': each.isChanged ? '#ff0000':'#000000'}">
                                {{each.observedValue}}
                            </div>

                        </div>
                        <br />
                        <span class="white-space-nowrap"><i [ngClass]="{'text-success':item[0].active}" class="mdi mdi-account text-primary mr-1"></i>{{item[0].createdByName}}</span>
                        <br />
                        <div class="white-space-nowrap"><i [ngClass]="{'text-success':item[0].active}" class="mdi mdi-calendar text-primary mr-1"></i><small class="white-space-nowrap" [textContent]="item[0].createdDate |date: 'dd-MM-yyyy'"></small></div>
                        <div class="white-space-nowrap"><i [ngClass]="{'text-success':item[0].active}" class="mdi mdi-clock text-primary mr-1"></i><small [textContent]="item[0].createdDate |date: 'hh:mm a'"></small></div>
                        <h5 class="badge badge-outline-success" *ngIf="item[0].active">ActiveResult</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateViewDocument>
    <div class="modal-header">
        <h4 class="modal-title"><span [textContent]="document.documentName"></span></h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <a href="javascript:;" (click)="onPrevious()" class="previous-document" placement="left" ngbTooltip="Previous document" *ngIf="showPrevious"><i class="mdi mdi-arrow-left-bold"></i></a>
        <a href="javascript:;" (click)="onNext()" class="next-document" placement="left" ngbTooltip="Next document" *ngIf="showNext"><i class="mdi mdi-arrow-right-bold"></i></a>
        <div class="p-3" *ngIf="loadingDocument">
            <div class="d-flex align-items-center">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span class="ml-3">Please wait while loading Document ...</span>
            </div>
        </div>
        <div *ngIf="!loadingDocument && documentError">
            <div class="position-relative mh-300">
                <no-data title="Document"></no-data>
            </div>
        </div>
        <ng-container *ngIf="document && document.isImage">
            <img [src]="document.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="document.documentName" />
        </ng-container>
        <ng-container *ngIf="document && document.isVideo">
            <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                <source [src]="document.formedUrl" (load)="onDocumentLoad()" [type]="document.contentType">
            </video>
        </ng-container>
        <ng-container *ngIf="document && !document.isImage && !document.isVideo">
            <pdf-viewer [src]="document.formedUrl['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgress($event)"
                        (error)="onDocumentError()"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </ng-container>
    </div>
</ng-template>

<ng-template #templateReason>
    <div class="center-position">
        <div class="modal-header">
            <h4 class="modal-title"><span [textContent]="'Reason'"></span></h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseReasonView();">×</button>
        </div>
        <div class="modal-body">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="mb-1">Reason <code>*</code></label>
                    <input type="text" class="form-control" [(ngModel)]="reason" placeholder="Enter Reason">
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-sm btn-primary"
                    (click)="onRemoveParameters()">
                Submit
            </button>
        </div>
    </div>
</ng-template>
<div class="flex-container">
  <div class="div1"></div>
  <div class="div2 align-items-center d-flex div2 justify-content-center">
    <div class="w-100 p-4">
      <div>
        <div class="auth-logo">
          <a href="javascript:;" class="logo logo-dark">
            <span class="logo-lg">
              <img [src]="logoBasics && logoBasics.imageUrl ? logoBasics.imageUrl : 'assets/images/fernandez.png'" [hidden]="loading" alt="Careaxes" width="55" />
            </span>
          </a>
          <a href="javascript:;" class="logo logo-light">
            <span class="logo-lg">
              <!--<img src="assets/images/fernandez.png" alt="Careaxes" width="200">-->
              <img [src]="logoBasics && logoBasics.imageUrl ? logoBasics.imageUrl : 'assets/images/fernandez.png'" [hidden]="loading" alt="Careaxes" width="200" />
            </span>
          </a>
        </div>
      </div>
      <div *ngIf="env !== 'live'" class="mb-4">
        <div class="badge badge- mb-1" [ngClass]="{'badge-success': env === 'uat', 'badge-blue': env === 'qa proactive', 'badge-danger': env === 'qa reactive', 'badge-warning': env === 'local'}">
          <h3 class="m-0 font-22 text-white">
            <span [textContent]="env" class="mr-1 text-uppercase"></span>Environment
          </h3>
        </div>
        <h6 class="m-0 text-secondary">
          <i class="mdi mid-laptop mr-1"></i><span [textContent]="envTitle" class="mr-1 text-uppercase"></span>
        </h6>
      </div>
      <div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <h4 class="mt-0 font-22">Welcome Back :)</h4>
          <p class="font-15">Sign in to access your account.</p>
          <div class="form-group mb-3" [ngClass]="{ 'is-invalid': submitted && (form.username.errors || form.countryId.errors) }">
            <div class="d-flex justify-content-between">
              <label class="mb-1">Username</label>
              <div class="text-primary" *ngIf="loadingLocationMap">
                loading ...
              </div>
            </div>
            <div class="input-group mb-0">
              <div class="input-group-prepend" *ngIf="form.type.value && form.type.value === 'M'">
                <select class="form-control country-control" formControlName="countryId">
                  <option *ngFor="let item of countries" [textContent]="'+' + item.optionalText" [ngValue]="item.id"></option>
                </select>
              </div>
              <input tabindex="0" type="text" block [class.border-left-0]="form.type.value && form.type.value === 'M'" [attr.maxlength]="form.type.value && form.type.value === 'M' ? 10 : 100" formControlName="username" autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.username.errors) }" placeholder="Enter mobile number or email address" />
            </div>
          </div>
          <div class="form-group">
            <label class="mb-1">Password</label>
            <div class="input-group password-group mb-0">
              <input tabindex="0" type="password" block formControlName="password" maxlength="20" autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.password.errors) }" placeholder="Enter password" />
              <div class="input-group-append cursor-pointer" password>
                <div class="input-group-text">
                  <span class="password-eye"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-3" *ngIf="locationDropDown">
            <label class="mb-1">Location</label>
            <div class="input-group password-group mb-0">
              <select class="form-control" tabindex="0" formControlName="locationId" [ngClass]="{ 'is-invalid': (submitted && form.locationId.errors) }">
                <option selected hidden [ngValue]="null">
                  Select Location
                </option>
                <option *ngFor="let location of locations" [ngValue]="location.id" [textContent]="location.name"></option>
              </select>
            </div>
          </div>
          <a href="javascript:;" tabindex="0" routerLink="/forgot-password" class="float-right anchorFocus mb-1 onHoverUnderline"><small>Forgot your password?</small></a>
          <button type="submit" [disabled]="submitting" tabindex="0" class="btn btn-primary btn-block">
            <span *ngIf="submitting">
              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              Please wait..
            </span>
            <span *ngIf="!submitting">Sign In</span>
          </button>
          <a href="javascript:;" tabindex="0" routerLink="/queue" class="float-right anchorFocus mt-3 onHoverUnderline">Queue Management</a>
        </form>
      </div>
    </div>
  </div>
</div>
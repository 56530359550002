import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";import { AppData } from "../../../app.data";
import { HttpService } from "../../services/http.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PrintOptionService } from "../../services/print-option.service";
import { IUserAccount, Page } from "../../models";
import { finalize, takeUntil } from "rxjs";
import { ApiResources } from "../../helpers";
import { start } from "@popperjs/core";
@Component({    templateUrl: "./lab-parametervalue-report.html",    selector: "lab-parametervalue-report",    encapsulation: ViewEncapsulation.None})export class LabParameterValueReport implements OnInit, OnDestroy {    @Input() newLabBookingDetailId: number;
    @Input() patientId: any;
    @Input() labMainDetailId: any;
    page: Page
    loading: boolean;
    data: any[];
    names: any[];
    parameterNames: any[];
    totalObservedValues: Array<any>;
    verifiedDate: any[];
   

    constructor(        private readonly appData: AppData,        private readonly httpService: HttpService,        private readonly sanitizer: DomSanitizer,        private readonly printOptionService: PrintOptionService    ) {        this.page = new Page();        this.totalObservedValues = new Array<any>();    }

    ngOnInit() {        this.appData.userAccount            .pipe(takeUntil(this.page.unSubscribe))            .subscribe((userAccount: IUserAccount) => {                if (userAccount) {                    this.page.userAccount = userAccount;           
                    this.fetch();
                }                else {                    this.fetch();
                }            });    }
    private fetch() {
     
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId,
            patientId: this.patientId,
            labMainDetailId: this.labMainDetailId
        };
       
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabParameters), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<any>) => {
                    this.data = response
                    const distinctThings = this.data.filter(
                        (x, i, arr) => arr.findIndex(t => t.parameterName === x.parameterName) === i
                    );
                    this.parameterNames = distinctThings;
                    const data = this.data.filter(
                        (x, i, arr) => arr.findIndex(t => t.parameterName === x.parameterName)
                    );
                    const dataMap: { [key: string]: { value: string, date: string, fullname: string }[] } = {};
                    this.data.forEach(item => {
                        if (!dataMap[item.parameterName]) {
                            dataMap[item.parameterName] = [];
                        }
                        dataMap[item.parameterName].push({ value: item.observedValue ,date: item.date ,fullname:item.fullName});

                    });

                    this.totalObservedValues = Object.keys(dataMap).map(key => ({
                        parameterName: key,
                        observedValue: dataMap[key],
                        
                    }));
                    const distinct = this.data.filter(
                        (x, i, arr) => arr.findIndex(t => t.date === x.date) === i
                    );
                    this.verifiedDate = distinct;                   
                });
    
    }    ngOnDestroy() {        this.page.unsubscribeAll();    }


   
}

<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border  justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">
            <div class="d-flex headerHeight">
                <button class="align-items-center d-flex m-1 p-0 rounded-circle encounterBtn width30px height30px" (click)="toggleSideNav()">
                    <i [ngClass]="{'d-none':sideNav}" class="mdi mdi-chevron-left font-26"></i><i [ngClass]="{'d-none':!sideNav}" class="mdi d-none mdi-chevron-right font-26"></i>
                </button>
            </div>

            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="textColorEncounter font-17 text-uppercase cursor-default" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
            </div>
            <div class="heading-color1 font-weight-bold">
                <span class="font-16" *ngIf="appointment && appointment.patientDateOfBirth">{{this.yearsfordisplay}} <sub>Y</sub> {{this.monthsfordisplay}} <sub>M</sub> {{this.daysfordisplay}} <sub>D</sub> </span>
            </div>


            <div class="heading-color1 font-weight-bold">
                <span class="font-17" *ngIf="measurements && measurements.lengthInCms != null">Height:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.lengthInCms!=null" [textContent]="measurements.lengthInCms +' cms'"></span>
            </div>
            <div class="heading-color1 font-weight-bold">
                <span class="font-17" *ngIf="measurements && measurements.weightInKgs != null">Weight:</span>
                <span class="font-17" *ngIf="measurements && measurements.weightInKgs != null" [textContent]="measurements.weightInKgs +' kg'"> </span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="!timelineService.showBox">
                <span class="font-17" *ngIf="measurements && measurements.headCircumference!=null"> HC:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.headCircumference!=null" [textContent]="measurements.headCircumference +' cms'"></span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="!timelineService.showBox">
                <span class="font-17" *ngIf="measurements && measurements.temparature!=null"> Temp:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.temparature!=null" [textContent]="measurements.temparature +' F'"></span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="!timelineService.showBox">
                <span class="font-17" *ngIf="measurements && measurements.tcb!=null">TCB:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.tcb!=null" [textContent]="measurements.tcb"></span>
            </div>

            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment && appointment.providerName!==null">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="textColorEncounter font-17 text-uppercase cursor-default">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.appointmentTimeString}}</span></h4>
            </div>

        </div>

        <div class="d-flex pt-1 pb-1">
            <div class="border d-flex encounterWedgets flex-grow-1 mx-1" *ngIf=" roleId != 63 && roleId != 82 ">
                <div class="align-items-center justify-content-center d-flex w-100-px w-75p" *ngIf="!neonatalRisk">
                    <img class="w-75" src="../../../../assets/images/timelineSvg/riskfactor-c1.svg" />
                </div>
                <div class="flex-grow-1 m-1" *ngIf="!neonatalRisk">
                    <h5 class="heading-color1 mb-0" *ngIf="!neonatalRisk">Neonatal Risk Factor:</h5>
                    <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                        <div *ngIf="!neonatalRisk">
                            <span class="line-height-20px white-space-nowrap heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 overflow-auto" *ngIf="neonatalRisk && neonatalRisk !== null &&  roleId != 63 && roleId != 82 ">
                    <div class="d-flex justify-content-between align-items-center p-2px pl-0">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 mx-1 white-space-nowrap">Neonatal Risk Factors</h5>

                    </div>
                    <div class="encounterWedgetsContent pr-1">
                        <div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color2 m-0 mb-1 d-inline line-height-1 mx-1">Neonatal Risk Factors:</h6>
                                <div class="w-100 mb-0 dashboard-break font-14" *ngIf="neonatalRisk &&( neonatalRisk.growthStatus1.length > 0)">
                                    <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                        <span class="pr-1" *ngFor="let item of  neonatalRisk.growthStatus1; let i=index;" [textContent]="item">
                                            <span *ngIf="i !=neonatalRisk.growthStatus1.length-1">,</span>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-4 min-fit-width" *ngIf="!allergies && roleId != 63 && roleId != 82">
                <div class="d-flex border encounterWedgets">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/allergies-c2.svg" />
                    </div>
                    <div class="flex-grow-1 m-1">
                        <h5 class="heading-color1 mb-0" *ngIf="!allergies">Allergies Sections:</h5>
                        <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                            <div *ngIf="!allergies">
                                <span class="line-height-20px heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="specialFeature && specialFeature.specialFeature && roleId != 63 && roleId != 82">
                <div class="d-flex border encounterWedgets pr-1">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/special-features-c1.svg" />
                    </div>
                    <div class="flex-grow-1" *ngIf="!specialFeature.specialFeature">
                        <h5 class="heading-color2 mb-0">Special Features:</h5>
                    </div>
                    <div class="flex-grow-1" *ngIf="specialFeature.specialFeature">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 white-space-nowrap">Special Features:</h5>
                        <div class="encounterWedgetsContent pr-1 text-black font-14" [textContent]="specialFeature.specialFeature"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

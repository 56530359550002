export class IssueIndentDisplay {
    pharmacyIssueHeaderId: number;
    pharmacyIndentHeaderId: number;
    pharmacyIssueDetailId: number;
    pharmacyProductId: number;
    retailStockId: number;
    createdBy: number;
    productName: string;
    createdDate: Date;
    overallNetAmount: number;
    overallGst: number;
    overallDiscount: number;
    gstPercentage: number;
    discountPercentage?: number;
    discountAmount?: number;
    netAmount: number;
    gstAmount: number;
    quantity: number;
    expiryDate: number;
    overallTotal: number;
    total: number;
    retailName: string;
    admissionNo: string;
    admissionDate: Date;
    patientName: string;
    age: string;
    gender: string;
    providerName: string;
    umrNo: string;
    billNumber: string;
    purchaseRate: number;
    batchNumber: string;
    mrp: number;
    admissionId?: number;
    dateOfBirth: Date;
    city: string;
    bedNumber: string;
    returnQuantity: number;
}

export class PharmacyInpatientReceipt {
    billNumber: string;
    createdDate: Date;
    record: Array<IssueIndentDisplay>;

    constructor() {
        this.record = new Array<IssueIndentDisplay>();
    }
}
export class PharmacyStocks {
    pharmacyStockId: number;
    otStockId: number;
    pharmacyRetailStockId: number;
    pharmacyProductId: number;
    taxId: number;
    quantityIn: number;
    quantityOut: number;
    availableQuantity: number;
    batchNumber: string;
    expiryDate?: Date;
    purchaseRate: number;
    mrp: number;
    taxPercentage: number;
    barcode: string;
    mrpChangeReason: string;
    categoryName: string;
    productName: string;
    genericName: string;
    totalItems: number;
    encryptedPharmacyStockId: string;
    currentMrpAvailability: number;
    currentPurchaseAvailability: number;
    lossAmount: number;
    totalLossAmount: number;
    indentDate: Date;
    indentQty: number;
    issueDate: Date;
    issueQty: number;
    requiredDate: Date;
    retailName: string;
    companyName: string;
    operationStockId: number;
    reqQuantity: number;
    billNumber: string;

    pharmacyProductDetailId?: number;
    pharmacyProductRackId?: number;
    roq?: number;
    rol?: number;
    rackName: string;

    wareHouseName: string;    

    isIGST: boolean;
    isSGST: boolean;
    categoryId: number;

    movedQuantity: number;
    quantity: number;
    pharmacyIndentDetailId: number;
    pharmacyIndentHeaderId: number;
    status: string;
    createdDate: Date;
    issuedQuantity: number;
    departmentName: string;

    pharmacyDepartmentalStockId: number;
    retailPharmacyId: number;
    issuingQty: string;
    isSubmitting: boolean;

}
import { HttpErrorResponse } from "@angular/common/http";
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef,Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Setting } from "@shared/entities";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { IResource, IUserAccount, Page } from "@shared/models";
import { AppConfig, AppData, HttpService, IdentityService, MenuService, NotifyService, ResourceService } from "@shared/services";
import { EmailValidator, MobileValidator } from "@shared/validators";
import { Subscription } from "rxjs";
import { debounceTime, finalize, takeUntil } from "rxjs/operators";
import { NgbAlertModule, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
    templateUrl: "./opd-registration.html",
    styleUrls:[],
    encapsulation: ViewEncapsulation.None
})
export class OPDregistrationPage implements OnInit, OnDestroy {


@ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  private isDrawing: boolean = false;
  private lastX: number;
  private lastY: number;
  borderColor : string = "black";


    page: Page;

    loadingCountries: boolean;
    countries: Array<IResource>;
   
    locationMap: Array<IResource>;
    loadingLocationMap: boolean;
    locationDropDown: boolean;

    states:Array<IResource>;
    loadingStates:boolean =false;

    cities : Array<IResource>;
    loadingCities: boolean = false;


    occupationList: Array<IResource>;
    loadingOccupation:boolean = false;

    religions: Array<IResource>;
    loadingReligion:boolean = false;

    genders: Array<IResource>;
    loadingGender :boolean = false;

    education :  Array<IResource>;
    loadingEducation : boolean = false;

    relations : Array<IResource>;
    loadingRelations : boolean = false;

    maritalStatues : Array<IResource>;
    loadingMaritalStatus : boolean = false;

    nationalities :  Array<IResource>;
    loadingNationality: boolean = false;

    locations: Array<IResource>;
    lodingLocation : boolean = false;

   idProof :Array<IResource>;



    env: string;
    envTitle: string;
    loginForm: FormGroup;
    submitting: boolean;
    submitted: boolean;
    logoBasics: Setting;
    logoSubscription: Subscription;
    loadingSettings: boolean;
    isTheme: boolean;
    themeColor: string;
    loading: boolean;

    public opd : FormGroup;
    optForm : FormData = new FormData();
    patientDetails : any ={};


    finalForm : FormData ;
    isLoading : boolean = false;
    

    constructor(
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly identityService: IdentityService,
        private readonly menuService: MenuService,
        private readonly resourceService: ResourceService,
        
        private readonly appData: AppData,
        private readonly notifyService: NotifyService,
        private location: Location
    ) {
        this.locations = new Array<IResource>();
        this.countries = new Array<IResource>();
        
        this.env = AppConfig.settings.env.toLowerCase();
        this.envTitle = AppConfig.settings.envTitle;
        this.page = new Page();
        this.locationDropDown = false;
        this.logoBasics = new Setting();

        this.buildForm();

        this.religions = new Array<IResource>(); 



    }



   

    private buildForm()
    {
    this.opd =this.formBuilder.group(
      {
          // PatientDetails
          patientName : [null,Validators.required],
          dateOfBirth : [null,Validators.required],
          age : [null,Validators.required], 
          ageAtMarriage : 0,
          maritalStatus : [null,Validators.required],
          gender: [null,Validators.required],
          religion : [null,Validators.required],
          qualification:[null,Validators.required],
          occupation: [ null,Validators.required],
          nationality: [null,Validators.required],
          phoneNumber : [null,[Validators.required, MobileValidator.isValid]],
          alternateMobileNumber : [""],
          patientEmail : [null,Validators.required],
          idProof : [null,Validators.required],
          address : [null,Validators.required],
          pincode : [null,Validators.required],
          city: [null,Validators.required],
          area : [null,Validators.required],
          state : [null,Validators.required],
          acceptTerms: [null, Validators.required],
          locationId: [null, Validators.required],
          profileImageUrl : "",
          thumbnailUrl  : "",
          idProofUrl   : "",
          patientSignatureUrl : "",
          
            
            

          
          //Relative Details

          relativeName: [null,Validators.required],
          relation : [null,Validators.required],
          relativeDob : [null,Validators.required],
          relativeAge: [null,Validators.required],
          relativeReligion : [null,Validators.required],
          relativeQualification : [null,Validators.required],
          relativeOccupation : [null,Validators.required],
          relativeNationality : [null,Validators.required],
          relativePhoneNumber : [null,[Validators.required, MobileValidator.isValid]],
          relativeAlternateMobileNo : [""],
          relativeEmail : [null,Validators.required],
          relativeIdProof :[null,Validators.required],
          relativeIdProofUrl: ""

          


  
  
        
      }
  
      
)
    }

    private fetchCountries() {
        this.loadingCountries = true;
        this.resourceService.countries()
            .pipe(finalize(() => { this.loadingCountries = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.countries = response;
                
            });
    }


    private fetchLocation() {
        this.lodingLocation = true;
        this.resourceService.locations()
            .pipe(finalize(() => { this.lodingLocation = false }))
            //.pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

private fetchOccupation() {
        this.loadingOccupation = true;
        this.resourceService.occupation()
            .pipe(finalize(() => { this.loadingOccupation = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.occupationList = response;
            });
    }

private fetchReligion() {   //fetching religions from server
        this.loadingReligion = true;
        this.resourceService.religion()
            .pipe(finalize(() => { this.loadingReligion = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.religions = response;
            });
    }

 public fetchCities(sId: number) {
        this.loadingCities = true;
        this.resourceService.cities(sId)
            .pipe(finalize(() => { this.loadingCities = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => { 
                this.cities = response;
            });
    }

    
    private fetchStates() {
        var cId=1;
        this.loadingStates = true;
        this.resourceService.states(cId)
            .pipe(finalize(() => { this.loadingStates = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.states = response;
            });
    }
    
  private fetchEducation() {
        this.loadingEducation = true;
        this.resourceService.education()
            .pipe(finalize(() => { this.loadingEducation = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.education = response;
            });
    }

  
    
 private fetchGender() {
        this.loadingGender = true;
        this.resourceService.gender()
            .pipe(finalize(() => { this.loadingGender = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.genders = response;
            });
    }

  private fetchRelationShips() {
        this.loadingRelations = true;
        this.resourceService.relationships()
            .pipe(finalize(() => { this.loadingRelations = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.relations = response;
            });
    }

 private fetchMaritalStatus() {
        this.loadingMaritalStatus = true;
        this.resourceService.maritalStatus()
            .pipe(finalize(() => { this.loadingMaritalStatus = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.maritalStatues = response;
            });
    }


private fetchIdProof() {
    
    const request = {};
    this.httpService.post(ApiResources.getURI(ApiResources.idProof.base, ApiResources.idProof.fetchActive), request)
        .pipe(takeUntil(this.page.unSubscribe))
        .subscribe(
            (response: Array<any>) => {
                this.idProof = response;
                
                }
            ,
            () => {
                this.idProof = new Array<any>();
            }
        );
}

     private fetchNationality() {
        this.loadingNationality = true;
        this.resourceService.nationality()
            .pipe(finalize(() => { this.loadingNationality = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.nationalities = response;
            });
    }
onFileSelected(event: any,key:string) {
 
 const file: File = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      if(key == "patientPhoto")
        {

            this.opd.patchValue({profileImageUrl:e.target.result})
            
        }
    
      if(key=="patientIdProofPhoto")
        {
            this.opd.patchValue({idProofUrl:e.target.result})
          
        }

      if(key=="relativeIdProof")
        {
            this.opd.patchValue({relativeIdProofUrl:e.target.result})
          

         }

    };

    reader.readAsDataURL(file);



  }
  calculateAge(event: any,key:string) {
   
    const selectedDate = event.target.value;
    
    if (selectedDate) {
     
      const today = new Date();
      const birthDate = new Date(selectedDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if(key=='age'){
      
      this.opd.patchValue({age:age})
     
      }
      else{
        this.opd.patchValue({relativeAge:age})
      }
      

    }
  }




createPatient()
{

var createPatient={
"userName": `1:${this.opd.value.phoneNumber}`,
"password" : "",
"accountTypes" : ["Patient"],
"deviceToken" :"",
"deviceId":"",
"description":"quick registration",
"patientId":0,
"accountId":0}

this.httpService
            .put<any>(ApiResources.getURI(ApiResources.patients.base, "check-user-list"), createPatient)
            .subscribe(
                async (response: any) => {
                    
                    if (response['item2'] != null)
                        this.patientDetails =await response['item1'][0];
                    else
                    {
                        this.notifyService.warning("Mobile number already exists .Please register with another number");
                         this.submitted =false;
                         this.isLoading =false; 
                        return;
                    }

                     this.updatePatient();
                },
                (e:any) => {
                    console.log(e);
                }
            );


}


updatePatient()
{
var temp = this.opd.value
var updateDetails ={
    "patientId": this.patientDetails['referenceId'],

    "DateOfBirth":temp['dateOfBirth'],
    "firstName":temp['patientName'],   
    "lastName":"",
    "fullName":temp['patientName'],    
    "age":temp['age'],
    "gender":temp['gender'],
    "email":temp['patientEmail'],
    "mobile": temp['phoneNumber'],
    "countryId":1,
    "modifiedBy":6776,
    "referralCode": "",
    
    

}
var patientFamily = {
    "patientId": this.patientDetails['referenceId'],
    "guid":this.patientDetails['guid2'],
    "salutation":"",
    "firstName" : temp['patientName'],
    "lastName": "",
    "dateOfBirth":temp['dateOfBirth'],
    "patientEmail":temp['patientEmail'],
    "patientMobile":temp['phoneNumber'],
    "patientGender":temp['gender'],
    "maritalStatus":temp['maritalStatus'],
    "profileImageUrl" : temp["profileImageUrl"],
    "patientIdProofName" : temp["idProof"],
    "idProofUrl"   : temp["idProofUrl"],
    "patientSignatureUrl" : temp["patientSignatureUrl"],
    "age":temp['age'],
    "streetAddress":temp['address'],
    "addressLine2" : temp['area'],
    "zipcode": temp['pincode'],
    "patientLocationId":temp['locationId'],

    "education":temp['qualification'],
   
    "nationality":"Indian",
    "state":temp['state'],
    "city":temp['city'],
    "religion":temp['religion'],
    "fullName":temp['patientName'],
    "occupation":temp['occupation'],
    "alternateNumber" : temp['alternateMobileNumber'],
    "ageAtMarriage"  : parseInt(temp['ageAtMarriage']),


    "relativeName":temp['relativeName'],
    "relation":temp['relation'],
    "relativeAge":temp['relativeAge'],
    "relativeGender":temp['relativeGender'],
    "relativePhoneNumber":temp['relativePhoneNumber'],
    "relativeEducation":temp['relativeQualification'],
    "relativeOccupation":temp['relativeOccupation'],
    "relativeDob":temp['relativeDob'],
    "relativeAlternateNumber" : temp['relativeAlternateMobileNumber'],
    "relativeIdProofUrl" : temp['relativeIdProofUrl'],
   

}


patientFamily["patientSignatureUrl"]=this.canvas.nativeElement.toDataURL('image/png')

this.httpService
            .put<any>(ApiResources.getURI(ApiResources.patients.base, "edit"), updateDetails)
            .subscribe(
                async (response: any) => {
                    //console.log(response)
                    
                },
                (e:any) => {
                    console.log(e);
                }
            );




//updating patientfamily 
this.httpService
            .post<any>(ApiResources.getURI(ApiResources.patients.base, "update-all"),patientFamily )
            .subscribe(
                async (response: any) => {
                    var url = this.router.url;                   
                    this.notifyService.successToast("Patient Details Registered Successfully");
                    const currentUrl = this.router.url;
                    await this.router.navigate(['']); 
                    await this.router.navigate([url]);
                    
                    
                },
                (e:any) => {
                    this.notifyService.warningToast("some error occured");
                    this.submitted =false;
                    this.isLoading =false; 
                    console.log(e);
                }
            );


}

async submit(opt : any)
{

    console.log(opt.value);
    this.submitted=true;
    if(this.isSignatureEmpty()    )
    {
    
    this.borderColor = "red";
    
    return ;
    }
else if(this.opd.invalid)
{
this.notifyService.warning("Please fill all the Mandatory Fields")
return;
}
else if(this.opd.value.profileImageUrl == '' || this.opd.value.idProofUrl == ''  || this.opd.value.relativeIdProofUrl=='')
{
this.notifyService.warning("Please upload images");
return;
}

    else{
this.borderColor = 'black';

}

    
   if (this.opd.valid && !this.isSignatureEmpty())
    {
    this.submitted =true;  
this.isLoading =true;

     this.createPatient();
   

    }



}
 



//canvas functions//

private startDrawing(event: MouseEvent | Touch) {
    this.isDrawing = true;
    this.ctx.beginPath();
    this.ctx.moveTo(event.clientX - this.canvas.nativeElement.getBoundingClientRect().left, 
                    event.clientY - this.canvas.nativeElement.getBoundingClientRect().top);
  }

  private draw(event: MouseEvent | Touch) {
    if (this.isDrawing) {
      this.ctx.lineTo(event.clientX - this.canvas.nativeElement.getBoundingClientRect().left, 
                      event.clientY - this.canvas.nativeElement.getBoundingClientRect().top);
      this.ctx.stroke();
    }
  }

  private endDrawing() {
    this.isDrawing = false;
    this.ctx.closePath();
  }

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }


isSignatureEmpty(): boolean {
  const imageData = this.ctx.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] !== 0) {
      return false; // Found a non-empty pixel
    }
  }
   this.borderColor = "red";
  return true; // All pixels are empty
}


//canvas functions
  

    ngOnInit() {
       
        this.fetchCountries();
        this.fetchStates();
        this.fetchOccupation();
        this.fetchReligion();
        this.fetchGender();
        this.fetchEducation();
        this.fetchRelationShips();
        this.fetchMaritalStatus();  
        this.fetchNationality();
        this.fetchLocation();
        //this.fetchIdProof();
       

  this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.strokeStyle = '#000';
    this.ctx.lineWidth = 2;

    this.canvas.nativeElement.addEventListener('mousedown', (event) => this.startDrawing(event));
    this.canvas.nativeElement.addEventListener('mousemove', (event) => this.draw(event));
    this.canvas.nativeElement.addEventListener('mouseup', () => this.endDrawing());

    this.canvas.nativeElement.addEventListener('touchstart', (event) => this.startDrawing(event.touches[0]));
    this.canvas.nativeElement.addEventListener('touchmove', (event) => this.draw(event.touches[0]));
    this.canvas.nativeElement.addEventListener('touchend', () => this.endDrawing());
  
  


 
    }





    ngOnDestroy() {
        
        this.page.unsubscribeAll();
    }
}
<div class="modal-header flex-wrap">
    <h4 class="modal-title"><i class="mdi mdi-gate mr-1"></i> GATE PASS</h4>
    <div>
        <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="col-12 column-gap10px d-flex justify-content-end p-0 white-space-nowrap">
        <button class="btn btn-xs" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo
        </button>
        <button class="btn btn-xs" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo
        </button>
        <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
    </div>
</div>
<div class="mb-3" Id="invoiceId">

    <div>
        <div class="p-1 position-relative px-sm-4 report">


            <table class="w-100">
                <thead>
                    <tr>
                        <td>
                            <!--place holder for the fixed-position header-->
                            <div class="page-header-space border-bottom border-dark">
                                <div *ngIf="isPrintLogo">
                                    <banner-setting></banner-setting>
                                </div>
                                <div *ngIf="!isPrintLogo">

                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <!--*** CONTENT GOES HERE ***-->
                            <div>
                                <div class="min-height-80 mb-3">
                                    <!--<div>
                                        <banner-setting></banner-setting>
                                    </div>-->
                                    <div class=" border-dark  mt-lg-2">
                                        <h5 class="text-center">GATE PASS</h5>
                                    </div>

                                    <div class="mt-2">
                                        <div class="overflow-auto">
                                            <table class="line-height-normal w-100">
                                                <tr class="vertical-align-top">
                                                    <td><h5>Admission No</h5></td>
                                                    <td class="text-left w-350-px"><span class="mr-1">:</span><span [textContent]="admission?.admissionNo"></span></td>
                                                    <td><h5>Date</h5></td>
                                                    <td class="text-left white-space-nowrap"><span class="mr-1">:</span><span [textContent]="(maxDischargeDate | date: 'dd:MM:yyyy') + ', '+(maxDischargeDate | date: 'h:mm:ss a')"></span></td>
                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td><h5>Patient Name</h5></td>
                                                    <td colspan="3" class="text-left vertical-align-top">
                                                        <span class="mr-1 w-10px">:</span>
                                                        <span>
                                                            <span class="text-capitalize" [textContent]="admission?.fullName + ', '"></span>
                                                            <span [textContent]="(admission?.gender === 'F' ? 'Female' : 'Male') + ', '"></span>
                                                            <span [textContent]="admission?.age + 'yrs'"></span>
                                                        </span>
                                                    </td>

                                                </tr>
                                                <tr class=" vertical-align-top">
                                                    <td class=" w-100px white-space-nowrap "><h5>Father/Husband Name</h5></td>
                                                    <td colspan="3" class="text-left text-capitalize">
                                                        <span class="mr-1">:</span>
                                                        <span class="text-capitalize" [textContent]="admission?.fatherOrHusband"></span>
                                                    </td>

                                                </tr>
                                                <tr class=" vertical-align-top">
                                                    <td><h5>Case Type</h5></td>
                                                    <td class="text-left ">
                                                        <span class="mr-1 text-left">:</span>
                                                        <span [textContent]="record?.caseTypeName"></span>
                                                    </td>
                                                    <td><h5>Bed No</h5></td>
                                                    <td class="text-left">
                                                        <div class="d-flex">
                                                            <span class="mr-1 d-inline-block">:</span>
                                                            <span class="d-inline-block" [textContent]="!admission?.bedNumber ? 'N/A' : (admission?.wardName + ' / ' + admission?.roomName + ' / ' + admission?.bedNumber)"></span>
                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr class="vertical-align-top">
                                                    <td><h5>Address</h5></td>
                                                    <td class="text-left" colspan="3">
                                                        <span class="mr-1">:</span>
                                                        <span [textContent]="admission?.fullAddress"></span>
                                                    </td>
                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td><h5>Valid Upto</h5></td>
                                                    <td class="text-left" colspan="3">
                                                        <span class="mr-1">:</span>
                                                        <span class="white-space-nowrap" [textContent]="(record?.validUpto | date: 'dd:MM:yyyy') +', '+ (record?.validUpto | date: 'h:mm:ss a')"></span>
                                                    </td>
                                                </tr>


                                            </table>

                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <div class="overflow-auto">
                                            <table class="border-dark  w-100">
                                                <tr class=" vertical-align-top">
                                                    <th class="border border-dark text-left">S.No.</th>
                                                    <th class="border-top text-center border-bottom border-right border-dark">Checked By</th>
                                                    <th class="border-bottom text-center border-top border-right border-dark">Authorized By</th>

                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td class="border-bottom border-left text-left border-right border-dark">
                                                        <span [textContent]="'Room Linen'"></span>
                                                    </td>
                                                    <td class="border-bottom border-right border-dark pl-1">

                                                        <span [textContent]="''"></span>
                                                    </td>
                                                    <td class="border-bottom border-right text-center border-dark">
                                                        <span [textContent]="''"></span>
                                                    </td>

                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td class="border-bottom border-left text-left border-right border-dark">
                                                        <span [textContent]="'Room Inventory'"></span>
                                                    </td>
                                                    <td class="border-bottom border-right border-dark pl-1">

                                                        <span [textContent]="''"></span>
                                                    </td>
                                                    <td class="border-bottom border-right text-center border-dark">
                                                        <span [textContent]="''"></span>
                                                    </td>

                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td class="border-bottom border-left text-left border-right border-dark">
                                                        <span [textContent]="'Ready For Discharge'"></span>
                                                    </td>
                                                    <td class="border-bottom border-right border-dark pl-1">

                                                        <span [textContent]="''"></span>
                                                    </td>
                                                    <td class="border-bottom border-right text-left border-dark">
                                                        <span [textContent]="''"></span>
                                                    </td>

                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td class="border-bottom border-left text-left border-right border-dark">
                                                        <span [textContent]="'Gate-Pass'"></span>
                                                    </td>
                                                    <td class="border-bottom border-right border-dark pl-1">

                                                        <span [textContent]="''"></span>
                                                    </td>
                                                    <td class="border-bottom border-right text-left border-dark">
                                                        <span [textContent]="''"></span>
                                                    </td>

                                                </tr>
                                                <tr class="vertical-align-top">
                                                    <td class="border-bottom border-left text-left border-right border-dark">
                                                        <span [textContent]="'Payment Mode'"></span>
                                                    </td>
                                                    <td class="border-bottom border-right border-dark pl-1">

                                                        <span [textContent]="''"></span>
                                                    </td>
                                                    <td class="border-bottom border-right text-left border-dark">
                                                        <span [textContent]="''"></span>
                                                    </td>

                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap justify-content-between">
                                        <div>
                                            <div class="mt-3">
                                                <div class="overflow-auto">
                                                    <div class="d-flex-start">
                                                        <h5>Printed By : </h5> <span class="text-capitalize mb-0" [textContent]="page.userAccount?.fullName"></span>
                                                    </div>
                                                    <div class="d-flex-start">
                                                        <h5>Printed Date : </h5> <span class="text-capitalize mb-0" [textContent]="currentDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="align-text-bottom a4-footer flex-grow-1 d-flex-end">
                                            <qrcode [qrdata]="QrCode" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tbody>


                <tfoot>
                    <tr>
                        <td>
                            <!--place holder for the fixed-position footer-->
                            <div class="page-footer-space"></div>
                        </td>
                    </tr>
                </tfoot>

            </table>
        </div>
    </div>
</div>
import { PayTypes } from "./paytype.entity";

export class PharmacySaleBill {
    pharmacySaleDetailId: number;
    pharmacySaleHeaderId: number;
    pharmacyProductId: number;
    serialNum: number;
    taxId: number;
    pharmacyRetailStockId: number;
    quantity: number;
    total: number;
    totalAmount: number;
    taxAmount: number;
    mrp: number;
    discountPerItem?: number;
    discount?: number;
    netAmount: number;
    billType: string;
    billNumber: string;
    saleDate: Date | string;
    patientId: number;
    patientName: string;
    patientMobile: string;
    mobile: string;
    admissionId: number;
    providerName: string;
    overallTotal: number;
    overallDiscount?: number;
    overallTaxes: number;
    overallNetAmount: number;
    productName: string;
    expiryDate?: Date;
    returnQuantity?: number;
    batchNumber: string;
    genericName: string;
    categoryName: string;
    companyName: string;
    umrNo: string;
    gender: string;
    age: string;
    createdDate: Date;
    rowSpan: number;
    paidVia: string;
    taxPercentage: string;
    sgst?: number;
    cgst?: number;
    igst?: number;
    taxableAmount?: number;
    paymentNumber: string;
    hsnCode: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth?: Date;
    specializationName: string;
    isIGST: boolean;
    isSGST: boolean;
    isSalucroBill: boolean;

    scheduledDrug: string;
    isGeneralItem: boolean;
    locationId: number;
    paidAmount?: number;
    reasonForDiscount: string;
    removedAmount: number;
    isSelected: boolean;
    productReturnQuantity: number;
    payType: Array<PayTypes>;
    paymentType: string;
    encryptedPharmacySaleHeaderId: string;
    constructor() {
        this.payType = new Array<PayTypes>();
    }
    
} 
import { Injectable, OnDestroy } from "@angular/core";
import { HttpService } from "./http.service";
import { ApiResources } from "../helpers";
import { NotifyService } from "./notify.service";
import { MasterBillModel } from "../entities";
import { Router } from "@angular/router";
import { Page } from "../models";
import { AppData } from "../../app.data";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BillNotificationCommentModalComponent } from "../components";
import { Idle } from "@ng-idle/core";

@Injectable()
export class BillNotificationService implements OnDestroy {
    masterBill: Array<MasterBillModel>;
    moduleNames: string;
    page: Page;
    modalRef: NgbModalRef;

    constructor(
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly router: Router,
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly idle: Idle,
    ) {
        this.page = new Page();
        this.router.events
            .subscribe(() => {
                if (this.modalRef != undefined) {
                    this.modalRef.close();
                }
            });
        this.masterBill = new Array<MasterBillModel>();
    }

    get(patientId?: number) {
        const request = {
            patientId: patientId
        }
        this.httpService
            .post(ApiResources.getURI(ApiResources.masterbill.base, ApiResources.masterbill.fetchPatientDue), request)
            .subscribe(
                (response: Array<MasterBillModel>) => {
                    this.masterBill = response;
                    this.masterBill = Array.from(this.masterBill.reduce((m, t) => m.set(t.modulesName, t), new Map()).values());
                    this.moduleNames = '';
                    this.masterBill.forEach((item) => {
                        this.moduleNames += item.modulesName + ',';
                    })
                    if (response && response.length > 0) {
                        this.notifyService.billConfirm(`You are having Payment Due in <b>${this.moduleNames}</b> Do you want to pay ?`, () => {
                            this.onPayDue(this.masterBill[0].encryptedPatientId)
                        }, () => { this.openModal() })
                    }
                },
                () => {
                }
            );
    }

    openModal() {
        this.modalRef = this.modalService.open(BillNotificationCommentModalComponent, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "custom-modal effect-scale idle-timeout"
        });
        this.modalRef.componentInstance.patientId = this.masterBill[0].patientId;
    }

    onPayDue(encryptedPatientId: string) {
        this.router.navigateByUrl(`app/masters/final-master-bill/${encryptedPatientId}`);
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}
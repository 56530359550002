export class PurchaseOrderDetail {
    purchaseOrderDetailId: number;
    purchaseOrderHeaderId: number;
    pharmacyProductApprovalId: number;
    pharmacyProductId: number;
    quantity: number;
    amount: number;
    taxAmount: number;
    netAmount: number;
    mrp: number;
    purchaseRate: number;
    productName: string;
    genericName: string;
    isGeneralItem: boolean;
    companyId: number;
    categoryId: number;
    taxId: number;
    tax: number;
    categoryName: string;
    companyName: string;
    typeName: string;
    subTypeName: string;
    purchaseUnitQty: number;
    saleUnitQty: number;
    purchaseUnitName: string;
    saleUnitName: string;
    status: string;
    isExpiry: boolean;
    feededQuantity: number | null;
    feedTotal: number | null;
    free: number | null;
    feedPurchaseRate: number | null;
    feedMrp: number | null;
    taxPerItem: number | null;
    feedTaxAmount: number | null;
    discountPerItem: number | null;
    discountAmount: number | null;
    feedNetAmount: number | null;
    batchNumber: string;
    expiryDate: Date | null;
    pharmacyPurchaseDetailId: number | null;
    pharmacyPurchaseHeaderId: number | null;
    barcode: string;
    purchaseFree?: number;
    inventoryItem: boolean;
    requestedQuantity: number;
    pendignQty: number;
    pendingQtyInPer: any;
    unit: string;
}
<button class="btn btn-sm disable-on-pdf"  type="button" (click)="generatePdfFromButton()">Print All Attached Document</button>
<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading Report details....</span>
    </div>
</div>
<div *ngIf="!isExternalReport">
    <div class="modal-body" *ngIf="!loading && demographData.length === 0 && !isExternalReport">
        <div class="d-flex justify-content-center">
            <div class="no-data-container">
                <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
                <h4 class="mt-1">No Report Data found.Nothing to show you.</h4>
            </div>
        </div>
    </div>

    <div Id="invoiceId" *ngIf="!loading && demographData.length > 0" class="d-flex justify-content-between pb-2 pt-0 w-100 flex-wrap">
        <div class="report position-relative">

            <table class="w-100">


                <tbody>
                    <tr>
                        <td>
                            <!--*** CONTENT GOES HERE ***-->
                            <div class="page">
                                <div id="print-content">
                                    <div id="dataContainer">
                                        <div id="header-content" class="w-100 disable-on-pdf" style="border-bottom:1px solid;">
                                            <div class="w-100 justify-content-between d-flex flex-wrap mt-1 print ">
                                                <div>
                                                    <div class="d-flex align-items-center" *ngIf="isPrintLogo">
                                                        <div class="mr-2">
                                                            <img src="assets/images/fernandezoriginal.svg" alt="logo" class="min-width17vw max-width_25vw">
                                                            <h4 class="white-space-nowrap" style="margin-top: -4px; margin-left: 56px;">Build for Birthing</h4>
                                                        </div>
                                                        <div style="color: #e3a400; line-height: 23px;" class="border-dark border-left font-weight-bold"><small class="ml-2">LABORATORY SERVICES</small></div>
                                                    </div>
                                                </div>

                                                <div class="align-items-end d-flex flex-grow-1 justify-content-end">
                                                    <div class="d-flex" style="gap:15px;">
                                                        <div class="font-weight-semibold mt-20px text-black" style=" line-height: 20px;">
                                                            <div><i style="color: #e3a400;" class="mdi mdi-heart mr-2"></i>T +91 40 4063 2414 / 18004191397 </div>
                                                            <div>
                                                                <i style="color: #e3a400;" class="mdi mdi-circle-outline mr-2"></i>E appointments@fernandez.foundation
                                                            </div>
                                                            <div class="text-right" style="color: #e3a400;"> www.fernandezhospital.com</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 class="headingText col-12" colspan="5" style="color: #000; font-weight: bold; text-align: center; text-decoration: underline; padding-bottom: 10px">
                                                Laboratory Report
                                            </h3>
                                            <div  class="overflow-auto">
                                                <table width="100%" class="heading1">
                                                    <tr class="vertical-align-top">
                                                        <th>
                                                            <h5 class="white-space-nowrap" *ngIf="demographData[0].patientName">Patient Name</h5>
                                                        </th>
                                                        <td class="d-flex">
                                                            <span style="margin-right: 5px">: </span>
                                                            <span class="text-capitalize" *ngIf="demographData[0].patientName" [textContent]="demographData[0].patientName"></span>
                                                        </td>
                                                        <th>
                                                            UMR No
                                                        </th>
                                                        <td class="white-space-nowrap">
                                                            <span style="margin-right: 5px">: </span>
                                                            <span [textContent]="demographData[0].umrNo || '-----'"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="vertical-align-top">
                                                        <th>
                                                            Age/Gender
                                                        </th>
                                                        <td>
                                                            <div class="d-flex">
                                                                <span style="margin-right: 5px">: </span>
                                                                <div>
                                                                    <span *ngIf="!demographData[0].dateOfBirth">{{demographData[0].age}} Year &nbsp;0 Month&nbsp;0Days </span>
                                                                    <span *ngIf="demographData[0].dateOfBirth">{{age.year}} Year &nbsp;{{age.month}} Month&nbsp;{{age.day}} Days </span>
                                                                    <span *ngIf="demographData[0].gender">&nbsp;/</span>
                                                                    <span *ngIf="demographData[0].gender" [textContent]="demographData[0].gender || '----'"></span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th>
                                                            Invoice Date
                                                        </th>
                                                        <td class="white-space-nowrap">
                                                            <span style="margin-right: 5px">: </span>
                                                            <span [textContent]="demographData[0].bookedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="vertical-align-top">
                                                        <th>
                                                            Location
                                                        </th>
                                                        <td>
                                                            <span style="margin-right: 5px">: </span>
                                                            <span class="text-capitalize" [textContent]="demographData[0].fromLocation"></span>
                                                        </td>
                                                        <th class="white-space-nowrap">
                                                            Requisition No
                                                        </th>
                                                        <td class="white-space-nowrap">
                                                            <span style="margin-right: 5px">: </span>
                                                            <span [textContent]="demographData[0].requisitionNumber"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="vertical-align-top">
                                                        <th>
                                                            Patient Type
                                                        </th>
                                                        <td>
                                                            <span style="margin-right: 5px">: </span>
                                                            <span [textContent]="demographData[0].bookingType"></span>
                                                        </td>
                                                        <th class="white-space-nowrap">
                                                            Collected Date
                                                        </th>
                                                        <td>
                                                            <span style="margin-right: 5px">: </span>
                                                            <span [textContent]="demographData[0].collectionDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                        </td>
                                                    </tr>
                                                    <tr class="vertical-align-top">
                                                        <th>
                                                            Doctor Name
                                                        </th>
                                                        <td class="d-flex">
                                                            <span style="margin-right: 5px">: </span>
                                                            <span class="text-capitalize" [textContent]="demographData[0].doctorName || '------'"></span>
                                                        </td>
                                                        <th>
                                                            Received Date
                                                        </th>
                                                        <td>
                                                            <span style="margin-right: 5px">: </span>
                                                            <span [textContent]="demographData[0].receivedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <!--<div class="mt100" id="backgroundOfwaterMark" style="z-index:100" *ngIf="reportSignatures.length == 0">
                                            <p id="bg-textOfwaterMark" style="z-index:1; -webkit-transform: rotate(-30deg); color: black">Interim Report</p>
                                        </div>-->
                                        <div class="col-12 p-0 mt-2" style="border-bottom:1px solid;">

                                            <ng-container *ngIf="reports.templates.length >0">
                                                <div *ngFor="let template of reports.templates; index as t">
                                                    <div class="d-flex justify-content-center">
                                                        <h4 style="text-decoration:underline;" *ngIf="reports.departmentName !='' && reports.departmentName != null">{{reports.departmentName}}</h4>
                                                    </div>
                                                    <div class="d-flex justify-content-center">
                                                        <h4 style="text-decoration:underline;">{{reports.testName}}</h4>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <div class="overflow-auto textOfEveryVw">
                                                                <table width="100%">
                                                                    <thead>
                                                                        <tr class="vertical-align-top">
                                                                            <th>Test Name</th>
                                                                            <th>Result</th>
                                                                            <th>Biological Reference Range</th>
                                                                            <th>Unit</th>
                                                                            <th>Method</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <h5 style="text-decoration:underline;">PARAMETERS</h5>
                                                                        <tr *ngFor="let parameter of template.parameters;index as j;">
                                                                            <td>
                                                                                <span [textContent]="parameter.parameterName || '-----'" *ngIf="parameter.type == 'Regular'"></span>
                                                                                <h5 class="mb-0 text-capitalize text-primary" [textContent]="parameter.parameterName || '-----'" *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount >0"></h5>
                                                                                <ng-container *ngIf="parameter.type != 'Regular'  && parameter.antibioticsCount >0">
                                                                                    <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                                                        <span [textContent]="antibiotic.antibioticName || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td>
                                                                                <span [ngClass]="{'font-weight-bolder' : parameter.isBold}" [textContent]="parameter.observedValue || '-----'" *ngIf="parameter.type == 'Regular'"></span>&nbsp;
                                                                                <span [textContent]="parameter.showText" *ngIf="parameter.type == 'Regular'"></span>
                                                                                <ng-container *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount >0">
                                                                                    <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                                                        <span [textContent]="antibiotic.observedValue || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td>
                                                                                <span *ngIf="parameter.referenceOutput === 'numeric' && (parameter.text === null || parameter.text === undefined) && parameter.type == 'Regular'">
                                                                                    {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}
                                                                                </span>
                                                                                <span *ngIf="parameter.text && parameter.type == 'Regular'">
                                                                                    <span [innerHTML]="parameter.text"></span>
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span *ngIf="parameter.selected && parameter.selected != undefined && parameter.selected.unitName != undefined && parameter.selected.unitName != null && parameter.type == 'Regular'">
                                                                                    {{parameter.selected.unitName || ''}}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span [textContent]="parameter.methodName || ' '" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null"></span>
                                                                                <span [textContent]="parameter.methodName || ' '" *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount >0"></span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody *ngFor="let component of template.components;index as n;">
                                                                        <h5 style="text-decoration:underline;">{{component.componentName}}</h5>
                                                                        <tr *ngFor="let parameter of component.parameters;index as j;">
                                                                            <td>
                                                                                <span [textContent]="parameter.parameterName || '-----'" *ngIf="parameter.type == 'Regular'"></span>
                                                                                <h5 class="mb-0 text-capitalize text-primary" [textContent]="parameter.parameterName || '-----'" *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount >0"></h5>
                                                                                <ng-container *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount > 0">
                                                                                    <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                                                        <span [textContent]="antibiotic.antibioticName || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td>
                                                                                <span [ngClass]="{'font-weight-bolder' : parameter.isBold}" [textContent]="parameter.observedValue || '-----'" *ngIf="parameter.type == 'Regular'"></span>&nbsp;
                                                                                <span [textContent]="parameter.showText" *ngIf="parameter.type == 'Regular'"></span>
                                                                                <ng-container *ngIf="parameter.type != 'Regular'  && parameter.antibioticsCount >0">
                                                                                    <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                                                        <span [textContent]="antibiotic.observedValue || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td>
                                                                                <span *ngIf="parameter.referenceOutput === 'numeric' && (parameter.text === null || parameter.text === undefined) && parameter.type == 'Regular'">
                                                                                    {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}
                                                                                </span>
                                                                                <span *ngIf="parameter.text && parameter.type == 'Regular'">
                                                                                    <span [innerHTML]="parameter.text"></span>
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span *ngIf="parameter.selected && parameter.selected != undefined && parameter.selected.unitName != undefined && parameter.selected.unitName != null && parameter.type == 'Regular'">
                                                                                    {{parameter.selected.unitName || ''}}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span [textContent]="parameter.methodName || ' '" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null"></span>
                                                                                <span [textContent]="parameter.methodName || ' '" *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount >0"></span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w-100" *ngIf="template.isMethod || template.isInterpretation">
                                                        <div class="p-0">
                                                            <div class="d-flex flex-wrap">
                                                                <div class="col-12 p-0" *ngIf="template.isMethod">
                                                                    <div class="form-group">
                                                                        <label class="mb-0 headingText1 font-weight-bold pl-4">Method</label>
                                                                        <div [innerHTML]="template.methodText"></div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 p-0" *ngIf="template.isInterpretation">
                                                                    <div class="form-group">
                                                                        <label class="mb-0 headingText1 font-weight-bold pl-4">Interpretation/Result</label>
                                                                        <div [innerHTML]="template.interpretationText"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div class="col-12 disable-on-pdf " *ngIf="reportData ">
                                            <div class="heading1 pl-1 pr-1" *ngIf="!loading && checkExternal && checkExternal.reportUrl">
                                                <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                                                            [render-text]="true"
                                                            [autoresize]="true"
                                                            [original-size]="false"
                                                            [fit-to-page]="false"
                                                            (on-progress)="onProgress($event)"
                                                            style="width: 100%; height: 500px">
                                                </pdf-viewer>
                                            </div>
                                        </div>

                                        <div class="col-12 disable-on-pdf " *ngIf="reportDataArray && reportDataArray.length > 0">
                                            <div class="heading1 pl-1 pr-1" *ngIf="!loading && allDocumentUrl">
                                                <div *ngFor="let report of reportDataArray">
                                                    <ng-container *ngIf="report.filetype === 'jpg' || report.filetype === 'jpeg' || report.filetype === 'png' || report.filetype === 'gif'" [style.display]="disableTag ? 'none' : 'block'">
                                                        <img [src]="report.url['changingThisBreaksApplicationSecurity']"
                                                             [hidden]="loadingDocument || documentError"
                                                             (load)="onDocumentLoad()"
                                                             (error)="onDocumentError()" />
                                                    </ng-container>


                                                    <ng-container *ngIf="report.filetype === 'pdf'">
                                                        <pdf-viewer [src]="report.url['changingThisBreaksApplicationSecurity']"
                                                                    [render-text]="true"
                                                                    [autoresize]="true"
                                                                    [original-size]="false"
                                                                    [fit-to-page]="false"
                                                                    (on-progress)="onProgress($event)"
                                                                    style="width: 100%; height: 500px">
                                                        </pdf-viewer>
                                                    </ng-container>
                                                    <ng-container *ngIf="report.filetype === 'mp4'">
                                                        <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                                                            <source [src]="report.url['changingThisBreaksApplicationSecurity']" [type]=" report.contentType">
                                                        </video>

                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex justify-content-center">
                                                <p>--End Of Report--</p>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="align-items-baseline d-flex flex-wrap justify-content-between mb-1 ml-lg-4 mr-lg-5">
                                                <div class="align-items-center d-flex" style="gap:20px;">
                                                    <div>
                                                        <qrcode [qrdata]="QrCode" [width]="150" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                                                    </div>
                                                    <div *ngIf="demographData[0].isNablRequired && demographData[0].nablRequired && nablUrl">
                                                        <img [src]="nablUrl" alt="No Data" class="mt-1" width="100" />
                                                        <!--<img class="mt-1" height="150" src="/assets/images/lisenseimg.jpeg" alt="No Data" />-->
                                                    </div>
                                                </div>
                                                <div class="text-capitalize" *ngFor="let signature of reportSignatures;index as p;">
                                                    <div class="bg-light">
                                                        <img style="height: 100px !important" [src]="safe(signature.signature)" alt="DoctorSignature" class="img-fluid">
                                                    </div>
                                                    <h6 class="m-0 headingText1" [textContent]="signature.providerName"></h6>
                                                    <!--<span [textContent]="signature.departmentName"></span>-->
                                                    <h6 class="m-0 headingText1">{{signature.departmentName}}</h6>
                                                    <!--<span [textContent]="demographData[0].receivedDate | date:'dd/MM/yyyy, h:mm a'"></span>-->
                                                    <h6 class="m-0 headingText1">{{signature.approvedDate | date:'dd/MM/yyyy, h:mm a'}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <h4 class="border-bottom border-dark border-top topHeading font-weight-bold mb-2 p-1 text-center text-dark">
                                        TERMS AND CONDITIONS FOR LABORATORY TESTING AND REPORTING
                                    </h4>
                                    <div class="d-flex flex-lg-nowrap flex-wrap headingText1 pr-3 text-justify" style="gap:15px;">
                                        <div>
                                            <ul>
                                                <li>
                                                    The reported results are for information and interpretation of the
                                                    referring clinician or other medical professional, who understands
                                                    reporting units, reference ranges and limitation of technologies.
                                                </li>
                                                <li>
                                                    A qualified medical professional should interpret the laboratory
                                                    results and adjuvant information using objective clinical judgment
                                                    and in conjunction with patient presentation, history, and other
                                                    diagnostic evidence.
                                                </li>
                                                <li>
                                                    The laboratory services at Fernandez Hospital (A unit of Fernandez
                                                    Foundation) may outsource certain tests to qualified labs.
                                                </li>
                                                <li>
                                                    Test results depend upon quality of sample and testing method, and
                                                    they may vary from lab-to-lab and/or from time-to-time for the same
                                                    parameters for the same patient. To help maintain sample quality, the
                                                    laboratory specifies criteria for specimen storage in accordance with
                                                    accreditation guidelines and testing methods. For more information,
                                                    please contact the Laboratory Services team.
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul>
                                                <li>
                                                    Specified biological reference ranges encompass 95% confidence
                                                    limits of a given population, which implies test results for healthy
                                                    individuals may fall in the abnormal range. In case of unexpectedly
                                                    abnormal lab results, the lab services team may perform repeat
                                                    analysis, based on Clinician/Client’s request and after due
                                                    investigation.
                                                </li>
                                                <li>
                                                    We assure that we maintain confidentiality of this information.
                                                </li>
                                                <li>
                                                    Fernandez Foundat ion, i ts di rectors, employees and/or
                                                    representatives are not liable for claims of damages incurred by any
                                                    person, including the patient as a result of interpretation of lab reports.
                                                    Financial or monetary claims are subject to approval by the
                                                    management of Fernandez Foundation and shall not exceed the
                                                    stipulated test cost under any circumstances.
                                                </li>
                                                <li>
                                                    All claims are subject to the jurisdiction of Hyderabad (TS) India.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>


                            </div>

                            </td>
                        </tr>
                    </tbody>

                <tfoot class="disable-on-pdf">
                    <tr>
                        <td>
                            <!--place holder for the fixed-position footer-->
                            <div class="page-footer-space"></div>
                        </td>
                    </tr>
                </tfoot>

            </table>

            <div class="page-footer disable-on-pdf">
                <div class="border-top border-dark pb-1 px-1 left-0 right-0" [ngStyle]="{'background-color': (isPrintLogo && footerUrl != null) ? 'white':'#dddddd'}" style="background-color: #dddddd; ">
                    <div *ngIf="isPrintLogo && footerUrl != null" class="text-center p-1">
                        <img [src]="footerUrl" alt="Careaxes" style="max-height: 75px; max-width: 100%;" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="isExternalReport">
    <div class="modal-body" *ngIf="isExternalLoading">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while rendering pdf....</span>
        </div>
    </div>
    <div class="heading1 pl-1 pr-1" Id="invoiceId" *ngIf="!loading && reportData">
        <div class="d-block d-flex justify-content-end mt-1">
            <button [useExistingCss]="true" class="btn btn-light text-dark font-weight-semibold btn-xs mr-1 d-print-none" type="button" (click)="onPrintPdf()"><i class="fe-printer mr-1"></i>print</button>
        </div>
            <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        [fit-to-page]="false"
                        (on-progress)="onProgress($event)"
                        style="width: 100%; height: 500px">
            </pdf-viewer>
        </div>
    </div>



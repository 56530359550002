import { IResource } from "@shared/models";
import { ModuleModel } from "./module.model";
import { PackageModuleDetailModel } from "./package-module-detail.model";
import { PackageModuleModel } from "./package-module.model";
import { SurgeryChargeModel } from "../../../../operation-theater/models";
import { SurgeryLinkModel } from "./surgery-link-model.model";

export class PackageModuleViewModel {
    packageModule: PackageModuleModel;
    modules: Array<ModuleModel>;
    packageModuleDetails: Array<PackageModuleDetailModel>;
    chargeCategory: IResource;

    totalQuantity: number;
    freeQuantity: number;
    freeQuantityTotal: number;
    subTotal: number;
    discount: number;
    total: number;

    constructor() {
        this.modules = [];
        this.packageModuleDetails = [];
    }
}
export class PharmacyInPatientReturnModel {
    pharmacyInPatientReturnId: number;
    pharmacyIssueDetailId: number;
    returnQuantity: number;
    createdBy: number;
    createdDate: Date;
    acceptedBy: number | null;
    acceptedDate: Date | null;
    createdByName: string;
    acceptedByName: string;
    pharmacyProductId: number | null;
    retailStockId: number | null;
    productName: string;
    genericName: string;
    patientName: string;
    mobile: string;
    umrNo: string;
}

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RequestInterceptor } from "@shared/interceptors";
import { AppointmentRefreshService, AppointmentToggleService, BannerService, BillNotificationService, CommunicationService, DashboardService, FinalBillService, HttpErrorService, HttpService, IconService, IdentityService, JitsiService, MenuService, NotifyService, PrintOptionService, PrintService, QueueService, RedirectAppointmentService, ResourceService, SettingService, TimelineToggleService, ValidatorService, VideoLinkService, ExportService, EncounterCommunication, RealTimeNodeService } from "@shared/services";
import { SharedCommonModule } from "@shared/shared.common.module";
import { SharedLibsModule } from "@shared/shared.libs.module";
import { SharedService } from "./services/shared.service";
import { NurseNotesPage, ProgressReportLabsPage,  ProgressReportMedicationPage, ProgressReportNotesPage, ProgressReportVitalsPage } from "../areas/admin/progress-report";
//import { SignatureWidget, OTPWidget, PharmacyProductWidget, BarcodeScannerWidget, PatientDocumentsWidget, AsideAppointmentsWidget, CommonData, FileDropperWidget, TimelineWidget, AppointmentInvoiceWidget, PharmacyIndentWidget, NewLabParameterWidget, BannerSettingWidget, PharmacyHeaderWidget, ChatBoxWidget, ColorIdentification, ImgCropWidget, ImgCaptureWidget, ObHeaderComponent } from "@shared/widgets"; 

import { NewLabTimelineWidget, GatePassReportWidget, SignatureWidget, InsuranceDocumentsWidget, OTPWidget, DietHeaderWidget, PharmacyProductWidget, BarcodeScannerWidget, PatientDocumentsWidget, AsideAppointmentsWidget, CommonData, FileDropperWidget, TimelineWidget, AppointmentInvoiceWidget, PharmacyIndentWidget, NewLabParameterWidget, BannerSettingWidget, PharmacyHeaderWidget, ChatBoxWidget, ColorIdentification, ImgCropWidget, ImgCaptureWidget, ObHeaderComponent, GynHeaderWidget, PediatricsHeaderComponent, NewLabReportWidget, ReceiptViewWidget, ParameterInputWidget, NewLabInvoiceWidget, GeneticHeaderComponent, PhysiotherapyHeaderComponent, EmergencyHeaderWidget, LabStatusIdentification, NurseHeaderComponent, TestChatBoxWidget, TelemedicineWidget, MessagingWidget, NeonatalHeaderWidget, ReportFooterLocationWidget, RegistrationInvoiceWidget, TransferedLabsWidget, NewLabModifyWidget, NewLabCancelledInvoiceWidget, LabParameterValueReport, GraphViewPage, InterimReportWidget } from "@shared/widgets"; 
//ImageDrawWidget
import { ProgressReportTimelinePage } from "../areas/admin/progress-report/pages/timeline/timeline.page";
import { InPatientsViewPage } from "../areas/admin/nurse-module";
import { ModifyCounsellingComponent, ViewCounsellingComponent } from "../areas/admin/counselling";
const widgets = [InsuranceDocumentsWidget, GatePassReportWidget, SignatureWidget, OTPWidget, PharmacyProductWidget, TimelineWidget, BarcodeScannerWidget, PatientDocumentsWidget, AsideAppointmentsWidget, CommonData, FileDropperWidget, AppointmentInvoiceWidget, PharmacyIndentWidget, NewLabParameterWidget, DietHeaderWidget, BannerSettingWidget, PharmacyHeaderWidget, ChatBoxWidget, ColorIdentification, ImgCropWidget, ImgCaptureWidget, ObHeaderComponent, GynHeaderWidget, EmergencyHeaderWidget, PediatricsHeaderComponent, NewLabReportWidget, ReceiptViewWidget, ParameterInputWidget, NewLabInvoiceWidget, NewLabTimelineWidget, GeneticHeaderComponent, PhysiotherapyHeaderComponent, EmergencyHeaderWidget, LabStatusIdentification, NurseHeaderComponent, TestChatBoxWidget, TelemedicineWidget, MessagingWidget, NeonatalHeaderWidget, InvestigationWidget, ReportFooterLocationWidget, RegistrationInvoiceWidget, TransferedLabsWidget, NewLabModifyWidget, NewLabCancelledInvoiceWidget, LabParameterValueReport, GraphViewPage, InterimReportWidget];
//ImageDrawWidget
import { ViewPackageModuleComponent } from "../areas/admin/masters/pages/package-modules";
import { BillNotificationCommentModalComponent } from "./components";
import { ProgressInfusionPage } from "../areas/admin/progress-report/pages/infusion/infusion.page";
import { InvestigationWidget } from "./widgets/investigations/investigation-exclusions.page";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
@NgModule({
    declarations: [widgets, BillNotificationCommentModalComponent, ViewPackageModuleComponent, ProgressReportTimelinePage, InPatientsViewPage, ModifyCounsellingComponent, ViewCounsellingComponent, ProgressReportLabsPage, ProgressReportMedicationPage, ProgressReportVitalsPage, NurseNotesPage, ProgressReportNotesPage
],
    imports: [SharedCommonModule, SharedLibsModule, NgxExtendedPdfViewerModule],
    exports: [SharedCommonModule, SharedLibsModule, widgets, ViewPackageModuleComponent, ProgressReportTimelinePage, InPatientsViewPage, ModifyCounsellingComponent, ViewCounsellingComponent]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                RealTimeNodeService, TimelineToggleService, CommunicationService, VideoLinkService, AppointmentToggleService, IdentityService, NotifyService, HttpService, ResourceService, JitsiService, QueueService, ValidatorService, FinalBillService, MenuService, PrintService, HttpErrorService, DashboardService, SettingService, AppointmentRefreshService, RedirectAppointmentService, PrintOptionService, BannerService, IconService, SharedService, BillNotificationService, ExportService, EncounterCommunication,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: RequestInterceptor,
                    multi: true
                }
            ]
        };
    }
}


export class neonatalIPEncounterFullTranscript {
    appointmentId: number;
    appointmentNo: string;
    admissionId: number;
    admissionNo: number;
    patientId: number;
    neonatalIPEncounterId: number;
    encryptedPatientId: string;
    encounterId?: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    admissionDate: Date;
    appointmentTime: object;
    providerName: string;
    admissionTimeString: string;
    umrNo: string;
    patientNo: string;
    medications: string;
    signature: string;
    clinicPicture: string;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
    partner: string;
    email: string;
    friendlyName: string;
    neonatalBaby: string;
    nicu: string;
    neonatalInfo: string;
    birthInjuryConsentAndRecordForm: string;
    reminder: string;
    refferalOrder: string;
    referralForm: string;
    reAdmission: string;
    iufdEntryForm: string;
    dischargeSummary: string;
    neonatalInitialAsessment: string;
    admssionSlip: string;
    specialFeature: string;
    futureAppointments: string;
    otAppointmentDetails: string;
    scanAppointmentDetails: string;
    orders: string;
    diagnosisOrder: string;
    medicationComment: string;
    allergies: string;
    scanTests: string;
    familyHistory: string;
    surgeries: string;
   

    birthHistory: string;
    diagnosisRecords: string;
    problemList: string;

    
   
}
import { Injectable } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import Swal from 'sweetalert2'

@Injectable()
export class NotifyService {
    constructor(private readonly toastrService: ToastrService) { }
    hideAll() {

    }

    warningToast(message: string) {
        this.toastrService.warning(message, "Warning!");
    }

    infoToast(message: string) {
        this.toastrService.info(message, "Information!");
    }

    successToast(message: string) {
        this.toastrService.success(message, "Success!");
    }

    errorToast(message: string) {
        this.toastrService.error(message, "Error!");
    }

    defaultErrorToast() {
        this.toastrService.error("Sorry! Error occurred. Please try again later.", "Error!");
    }

    warning(message: string, callback?: any) {
        return Swal.fire({
            title: "",
            html: message,
            icon: 'warning',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: 'btn reset-btn',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                callback && callback();
            }
        });
    }

    info(message: string, callback?: any) {
        return Swal.fire({
            title: "",
            html: message,
            icon: 'info',
            backdrop: false,
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: 'btn btn-info',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                callback && callback();
            }
        });
    }

    success(message: string, callback?: any) {
        return Swal.fire({
            title: "",
            html: message,
            icon: 'success',
            backdrop: false,
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: 'btn btn-primary',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                callback && callback();
            }
        });
    }
    error(message: string, callback?: any) {
        return Swal.fire({
            title: "",
            html: message,
            icon: 'error',
            backdrop: false,
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: 'btn btn-danger',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                callback && callback();
            }
        });
    }

    defaultError(callback?: any) {
        return Swal.fire({
            title: "",
            text: "Sorry error occurred. Please try again later.",
            icon: 'error',
            backdrop: false,
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: "Okay",
            customClass: {
                confirmButton: 'btn btn-danger',
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                callback && callback();
            }
        });
    }

    delete(yesCallback?: any, noCallback?: any) {
        return Swal.fire({
            title: "Are you sure?",
            text: "Do you really want to delete this record? This process cannot be undone.",
            icon: 'warning',
            backdrop: false,
            showConfirmButton: true,
            confirmButtonText: "Yes! Delete",
            showDenyButton: true,
            denyButtonText: "No! Cancel",
            customClass: {
                confirmButton: 'btn btn-danger',
                denyButton: 'btn btn-light'
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                yesCallback && yesCallback();
            } else if (result.isDenied) {
                noCallback && noCallback()
            }
        });
    }

    confirm(message: string, yesCallback?: any, noCallback?: any) {
        return Swal.fire({
            title: "",
            html: message,
            backdrop: false,
            showConfirmButton: true,
            confirmButtonText: "Yes!",
            showDenyButton: true,
            denyButtonText: "No! Cancel",
            customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn btn-light'
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                yesCallback && yesCallback();
            } else if (result.isDenied) {
                noCallback && noCallback()
            }
        });
    }
    billConfirm(message: string, yesCallback?: any, reasonCallback?: any) {
        return Swal.fire({
            title: "",
            html: message,
            backdrop: false,
            showConfirmButton: true,
            confirmButtonText: "Yes!",
            showDenyButton: true,
            denyButtonText: "No! Cancel",
            customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn btn-light'
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                yesCallback && yesCallback();
            } else if (result.isDenied) {
                reasonCallback && reasonCallback();
            }
        });
    }
    advancedConfirm(message: string, endMeetingLabel: string, endMeetingCallback?: any) {
        return Swal.fire({
            title: "Are you sure?",
            html: message,
            icon: `info`,
            backdrop: false,
            showConfirmButton: true,
            confirmButtonText: endMeetingLabel,
            showDenyButton: true,
            denyButtonText: "Cancel",
            customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn btn-light'
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                endMeetingCallback && endMeetingCallback();
            }
        });
    }

    endCallConfirm(message: string, endMeetingCallback?: any, leaveMeetingCallback?: any) {
        return Swal.fire({
            title: "Are you sure?",
            html: message,
            icon: `info`,
            backdrop: false,
            showConfirmButton: true,
            confirmButtonText: "End Call",
            showDenyButton: true,
            denyButtonText: "Leave Call",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn reset-btn',
                cancelButton: 'btn btn-light'
            }
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                endMeetingCallback && endMeetingCallback();
            } else if (result.isDenied) {
                leaveMeetingCallback && leaveMeetingCallback();
            }
        });
    }
}
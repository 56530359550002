import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount } from "../../models";
import { takeUntil, finalize } from "rxjs/operators";
import { LabBillHeaderModel } from "../../../areas/admin/labs/pages/models";
import { ApiResources } from "../../helpers";
import { AppData, HttpService, PrintOptionService } from "../../services";

@Component({
    templateUrl: "./new-lab-invoice.html",
    selector: "new-lab-invoice",
    styleUrls:["./new-lab-invoice.css"],
    encapsulation: ViewEncapsulation.None
})

export class NewLabInvoiceWidget implements OnInit, OnDestroy {
    @Input() newLabBookingHeaderId: number;
    @Input() encryptedNewLabBookingHeaderId: string;
    @Input() showFinanceandPrintByDetails: boolean;
    @Output() onClose = new EventEmitter<any>();
    @Input() isNewlabinvoiceId: boolean;
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    QrCode: string = null;
    currentDate: Date;
    bill: Array<LabBillHeaderModel>;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
    ) {
        this.page = new Page();
        //this.age = new Age();
        this.bill = new Array<LabBillHeaderModel>();
        this.currentDate = new Date();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                } else {
                    this.page.userAccount = undefined;
                    this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    private fetchInvoice() {
        const request = {
            encryptedNewLabBookingHeaderId: this.encryptedNewLabBookingHeaderId

        };
        if (this.showFinanceandPrintByDetails == null && this.showFinanceandPrintByDetails == undefined) {
            this.showFinanceandPrintByDetails == false;
        }
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchSelectedInvoiceLabData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<LabBillHeaderModel>) => {
                    this.bill = response;
                    this.QrCode = `${location.origin}${location.pathname}#/new-lab-webreports/${this.bill[0].requisitionNumber}`;
                });
        this.currentDate = new Date();
    }

}
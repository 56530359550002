<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <button type="button" class="btn btn-secondary btn-sm ml-1" (click)="onShowFilters()"><i class="fe-filter mr-1"></i> Filters</button>
                    </div>
                    <h4 class="page-title">Accounts</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-0">
                <div class="card-box mb-1 p-1">
                    <table class="table table-borderless table-sm mb-0">
                        <tr style="font-size:12px;" class="myFlex">
                            <td class="flex-grow-1">
                                <div class="form-group mb-0">
                                    <label class="mb-1 font-15">Full name</label>
                                    <input type="text" class="form-control form-control-sm" (keyup.enter)="onApplyFilters()" autocomplete="nope" [(ngModel)]="filters.options.fullName" block maxlength="152" placeholder="Enter full name" />
                                </div>
                            </td>

                            <td>
                                <div class="form-group">
                                    <label class="mb-1 font-15">Email address</label>
                                    <input type="text" class="form-control form-control-sm" (keyup.enter)="onApplyFilters()" autocomplete="nope" [(ngModel)]="filters.options.email" block maxlength="100" placeholder="Enter email address" />
                                </div>
                            </td>
                            <td class="flex-grow-1">
                                <div class="form-group">
                                    <label class="mb-1 font-15">Mobile number</label>
                                    <input type="text" class="form-control form-control-sm" (keyup.enter)="onApplyFilters()" autocomplete="nope" [(ngModel)]="filters.options.mobile" numbersOnly block maxlength="10" placeholder="Enter mobile number" />
                                </div>
                            </td>
                            <td class="flex-grow-1">
                                <div class="form-group">
                                    <label class="mb-1 font-15">Role</label>
                                    <select class="form-control custom-select form-control-sm" (keyup.enter)="onApplyFilters()" autocomplete="nope" [(ngModel)]="filters.options.roleId">
                                        <option [ngValue]="null" *ngIf="!loadingRoles">All</option>
                                        <option selected *ngIf="loadingRoles">Loading...</option>
                                        <option *ngFor="let item of roles" [textContent]="item.value" [ngValue]="item.id"></option>
                                    </select>
                                </div>
                            </td>
                            <td style="width: 17%;" class="flex-grow-1">
                                <div class="d-flex justify-content-center xsMarTop">
                                    <div class="form-group mb-0">
                                        <button type="button"
                                                (click)="onApplyFilters()"
                                                class="btn btn-sm btn-primary rounded-pill">
                                            <i class="mdi mdi-magnify mr-1"></i>Search
                                        </button>
                                        <button type="button"
                                                (click)="onResetFilters()"
                                                class="btn btn-sm reset-btn rounded-pill ml-1">
                                            <i class="mdi mdi-backup-restore mr-1"></i>Reset
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading Accounts ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (!users || !users.length)">
            <div class="col-12 mh-300">
                <no-data [applied]="filters.applied" [title]="'Accounts'"></no-data>
            </div>
        </div>
        <div class="row" *ngIf="!loading && users && users.length">
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-nowrap table-sm table-striped table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>User Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email Address</th>
                                        <th>Status</th>
                                        <th>Last Login Date</th>
                                        <th class="text-right" style="width: 85px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of users; let i = index; trackBy: trackBy.account">
                                        <td>
                                            <div class="media">
                                                <div class="avatar-sm mr-2">
                                                    <img *ngIf="item.thumbnailUrl" [src]="item.thumbnailUrl" [alt]="item.fullName" class="img-fluid rounded-circle">
                                                    <span *ngIf="!item.thumbnailUrl" class="avatar-title rounded-circle font-11 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.fullName | initials"></span>
                                                </div>
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.fullName"></h5>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span class="badge bg-soft-info" [textContent]="item.roleName"></span></td>
                                        <td>
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.userName"></h5>
                                            </div>
                                        </td>
                                        <td *ngIf="item.mobile" [textContent]="'(+' + item.countryCode + ') ' + item.mobile"></td>
                                        <td *ngIf="!item.mobile" [textContent]="'No mobile '"></td>
                                        <td [textContent]="item.email ? item.email : 'No email'"></td>
                                        <td>
                                            <span class="badge badge-soft-success" *ngIf="item.status === 'A'">Active</span>
                                            <span class="badge badge-soft-danger" *ngIf="item.status === 'D'">Inactive</span>
                                            <span class="badge badge-soft-danger" *ngIf="item.status === 'L'">Locked</span>
                                        </td>
                                        <td>
                                            <span [textContent]="item.lastLoginDate | utcToLocal:false"></span>
                                            <small class="ml-1" [textContent]="item.lastLoginDate | utcToLocal:false:'hh:mm a'"></small>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <div *menuButton="'master_accounts_modify_location'">
                                                    <a href="javascript:;" *ngIf="item.active" class="action-icon text-primary mr-1" placement="left" ngbTooltip="Modify Location" (click)="onOpenLocationModel(item,templateModifyLocation)"><i class="fe-map-pin"></i></a>
                                                </div>
                                                <a href="javascript:;" *menuButton="'master_accounts_change_password'" class="action-icon text-primary mr-1" placement="left" ngbTooltip="Change Password" (click)="onOpenChangePassword(item,templateChangePassword)"><i class="mdi mdi-account-key-outline"></i></a>
                                                <div *menuButton="'master_accounts_active'">
                                                    <a href="javascript:;" *ngIf="item.active" class="action-icon text-success mr-1" placement="left" ngbTooltip="Inactivate" (click)="onModifyStatus(item.accountId, false)"><i class="mdi mdi-close-thick"></i></a>
                                                </div>
                                                <div *menuButton="'master_accounts_inactive'">
                                                    <a href="javascript:;" *ngIf="!item.active" class="action-icon text-danger mr-1" placement="left" ngbTooltip="Activate" (click)="onModifyStatus(item.accountId, true)"><i class="mdi mdi-check-bold"></i></a>
                                                 </div>
                                                    <div *menuButton="'master_accounts_lock'">
                                                        <a href="javascript:;" *ngIf="!item.isLocked" class="action-icon text-primary mr-1" placement="left" ngbTooltip="Lock" (click)="onModifyLockedStatus(item.accountId, true)"><i class="fe-lock"></i></a>
                                                        <a href="javascript:;" *ngIf="item.isLocked" class="action-icon text-primary" placement="left" ngbTooltip="Unlock" (click)="onModifyLockedStatus(item.accountId, false)"><i class="fe-unlock"></i></a>
                                                    </div>
                                                </div>
</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav class="d-flex align-items-center justify-content-between p-2" *ngIf="pagination.totalPages > 1">
                            <p class="mb-0 font-13">
                                <span class="text-dark">Page <span [textContent]="pagination.currentPage"></span> of <span [textContent]="pagination.totalPages"></span></span>
                                <span class="ml-1">
                                    <span>(Showing <span [textContent]="pagination.currentItems - users.length + 1"></span> - <span [textContent]="pagination.currentItems"></span> of <span [textContent]="pagination.totalItems"></span>   Users)</span>
                                </span>
                            </p>
                            <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="true" [ellipses]="true" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                                <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                                <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                            </ngb-pagination>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="right-bar">
    <div data-simplebar class="h-100">
        <div class="filters-header">
            <h4>Filter Options</h4>
            <a href="javascript:;" (click)="onCloseFilters()"><i class="fe-x"></i></a>
        </div>
        <div class="filters-body">
            <div class="form-group position-relative">
                <label class="mb-1 font-13">Last logged date</label>
                <input class="form-control date-picker form-control-sm" type="text" block placeholder="Select last loggin date" [(ngModel)]="filters.options.lastLoginDate" readonly autocomplete="nope" ngbDatepicker #fromDate="ngbDatepicker" (click)="fromDate.toggle(); $event.stopPropagation();">
            </div>
            <div class="form-group">
                <label class="mb-1 font-13">Country</label>
                <select class="form-control custom-select form-control-sm" (keyup.enter)="onApplyFilters()" autocomplete="nope" [(ngModel)]="filters.options.countryId">
                    <option [ngValue]="null" *ngIf="!loadingCountries">All</option>
                    <option selected *ngIf="loadingCountries">Loading...</option>
                    <option *ngFor="let item of countries" [textContent]="item.value" [ngValue]="item.id"></option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-1 font-13">Status</label>
                <select class="form-control custom-select form-control-sm" (keyup.enter)="onApplyFilters()" autocomplete="nope" [(ngModel)]="filters.options.status">
                    <option [ngValue]="null">All</option>
                    <option [ngValue]="'A'">Active</option>
                    <option [ngValue]="'D'">Inactive</option>
                    <option [ngValue]="'L'">Locked</option>
                </select>
            </div>
        </div>
        <div class="filters-footer">
            <button type="button" (click)="onResetFilters()" class="btn btn-sm btn-light">Reset</button>
            <button type="button" (click)="onApplyFilters()" class="btn btn-sm btn-primary ml-1">Search</button>
        </div>
    </div>
</div>

<ng-template #templateChangePassword>
    <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="fe-log-in mr-1"></i>Change Password for {{selectedAccount.fullName}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="form-group mb-3">
                <label class="mb-1">New Password</label>
                <input type="password" formControlName="password" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': submitted && forms.passwordForm.password.errors }" (keypress)="space($event)" placeholder="Your new password" />
                <div *ngIf="submitted && forms.passwordForm.password.errors" class="invalid-feedback">
                    <!--<div *ngIf="forms.passwordForm.password.errors">
                        Password must contain minimum of 4 characters.
                    </div>-->
                    <div *ngIf="forms.passwordForm.password.errors">
                        Password must contain minimum of 1 Specialcharacter, LowerCAse aplhabet, Uppercase Alphabet and Number.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="mb-1">Re-enter Password</label>
                <div class="input-group mb-0">
                    <input type="password" formControlName="confirmPassword"  block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': submitted && forms.passwordForm.confirmPassword.errors }" (keypress)="space($event)" placeholder="Your new password again" />
                    <div class="input-group-append cursor-pointer" password><div class="input-group-text"><span class="password-eye"></span></div></div>
                </div>
                <div *ngIf="submitted && forms.passwordForm.confirmPassword.errors" class="invalid-feedback show-must">
                    <!--<div *ngIf="forms.passwordForm.confirmPassword.errors.minLength && forms.passwordForm.confirmPassword.errors.notEquivalent">
        Passwords are not matched
    </div>-->
                    <div *ngIf="forms.passwordForm.confirmPassword.errors.minLength || forms.passwordForm.confirmPassword.errors.notEquivalent ">Password were not matched</div>
                    

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-sm btn-primary">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateModifyLocation>
    <form [formGroup]="locationForm" (ngSubmit)="onModifyLocation()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="fe-log-in mr-1"></i>Location for {{selectedAccount.fullName}}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row mb-1">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Locations <code>*</code></label>
                        <ng-select class="ng-select-sm text-uppercase" [items]="locations"
                                   bindLabel="name"
                                   bindValue="id"
                                   autocomplete="nope"
                                   placeholder="Select locations"
                                   [ngClass]="{ 'is-invalid': submitted && forms.locationForm.locationIds.errors }"
                                   [multiple]="true"
                                   formControlName="locationIds">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No locations found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-sm btn-primary">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>
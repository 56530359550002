import { NgbDate, Placement, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { IResource } from "../../../../shared/models";

export interface INurseModel {
    bedIds: Array<number>;
    roleName: string;
    fullName: string;
    shiftId: number;
    nurseShiftMapId: number;
    shiftName: string;
    generalDate: Date;
    date: NgbDate;
    accountEncryptId: string;
    accountId: number;
    thumbnailUrl: string;

    dates: Array<DateHolder>;
}

export class SlotHolder {
    hoveredDate: NgbDate = null;
    minDate: NgbDate = null;

    fromDate: Date;
    toDate: Date;
    isSameDate: boolean;

    selectedSlots: Array<IResource>;
    selectedBeds: Array<Bed>;
    dbBeds: Array<Bed>;

    loadingBeds: boolean;
    nurseShiftMapId: number;

    shiftName: string;
}

export class HourHolder {
    hour: number;
    meridiemHour: number;
    meridiem: string;
    icon: string;
    name: string;
}

export enum BedStatus {
    Available = 1,
    Booked = 2,
    RoomTransfer = 3,
    Cleaning = 4,
    Other = 5
}

export class Bed {
    bedId: number;
    bedName: string;
    bedStatusId: BedStatus;
    bedStatusName: string;
    roomName: string;
    roomRent: number;
    wardName: string;
    floorName: string;
    admissionNo: string;
    admissionDate: Date;
    doctorName: string;
    doctorGender: string;
    doctorAge: number;
    doctorImage: string;
    nurseImage: string;
    departmentName: string;
    patientName: string;
    patientAge: number;
    patientGender: string;
    patientMaritalStatus: string;
    umrno: string;
    patientImage: string;
    nurseAccountId: number;
    fullName: string;
    roleName: string;
    nurseShiftMapId: number;
    fromDate: Date;
    toDate: Date;
    priorityName: string;
    priorityIcon: string;
    priorityColor: string;

    bedIndex: number;
    isSelected: boolean;
    isUnavailable: boolean;
    assignedFullName: string;
    assignedRoleName: string;

    locationId: number;
}

export enum FetchBedMode {
    OnInitial = 1,
    OnFormEdit = 2,
    OnModalOpen = 3
}

export class BedFilter {
    nurseShiftMapId: number;
    shiftId: number;
    fromDate: NgbDate;
    toDate: NgbDate;
    isRetouch: boolean;
    nurseAccountId?: number;
    locationId: number;
}

export class ViewModel {
    nurseAccountId: number;
    nurseShiftMapId: number;
    fromDate: Date;
    toDate: Date;
    shiftId: number;
    bedIds: Array<number>;
}

export class NurseDetails {
    accountId: number;
    nurseImage: string;
    fullName: string;
    roleName: string;
    email: string;
    mobile: string;
    countryCode: string;
}

export class DateHolder {
    date: NgbDate;
    fDate: Date;
    eDate: string;
    isToday: boolean;
    placement: Placement;

    bedCount: number;
    isExists: boolean;
    shiftName: string;
    shiftId: number;
    nurseShiftMapId: number;
    isDeleting: boolean;

    popover: NgbPopover;
    beds: Array<IBasicBed>;
    loadingBeds: boolean;
}

export class WordHelper {
    static floorName: string = "Floor";
    static wardName: string = "Ward";
    static roomName: string = "Room";
}

export enum Mode {
    New = 1,
    Edit = 2
}

export interface IBasicBed {
    bedName: string;
    roomName: string;
    wardName: string;
    floorName: string;
    bedId: number;
    patientAge: number;
    patientName: string;
    patientGender: string;
    admissionNo: string;
    umrno: string;
    priorityName: string;
    priorityIcon: string;
    priorityColor: string;
    patientImage: string;
}

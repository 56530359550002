import { Component, ViewEncapsulation, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { GenericResponse } from "@shared/models";
import { HttpService, BannerService, PrintOptionService} from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { IReceiptModel, IReceiptSubModel, IReceiptCostModel } from "../../../areas/admin/services/models/receipt.model";
import { AdmissionFetchHelper } from "../../../areas/admin/progress-report/shared/helper";
import { IAdmissionModel } from "../../../areas/admin/services/models/admission.model";
import { ReceiptType } from "../../enums";
import { ImageReports } from "@shared/entities";

@Component({
    templateUrl: "./receipt-view.html",
    styleUrls: ['./receipt-view.css'],
    selector: "receipt-view-widget",
    encapsulation: ViewEncapsulation.None
})
export class ReceiptViewWidget implements OnInit {

    @Output() emitOnCloseModel = new EventEmitter();
    @Input() receiptId: number;
    @Input() admissionId: number;
    @Input() isAdmission: boolean;
    @Input() waterMarkText: string;

    loading: boolean;
    record: IReceiptModel;
    admission: IAdmissionModel;
    receiptType = ReceiptType;

    @Input() isPrintLogo: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";

    constructor(
        private readonly httpService: HttpService,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly bannerService: BannerService,
        private readonly printOptionService: PrintOptionService
    ) {
        
    }

    onCloseModal = () => {
        this.emitOnCloseModel.emit();
    }

    admissionFetch = (callback?: Function) => {
        this.admissionFetchHelper.admissionFetch(+this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
            this.admission = data;
            callback();
        });
    }

    fetch = () => {
        
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.receipt.base, ApiResources.receipt.fetch),
                { id: this.admissionId.toString(), isAdmission: this.isAdmission, receiptId: this.receiptId })
            .subscribe(
                (response: GenericResponse) => {

                    this.loading = false;
                    const item = response.data.records[0] as IReceiptModel;
                    item.receiptNo = String(item.receiptId).padStart(6, '0');
                    item.subRecords = response.data.subRecords as Array<IReceiptSubModel>;
                    item.costModel = response.data.costViewModel as IReceiptCostModel;
                    this.record = item;
                }
            );
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    ngOnInit() {
        this.loading = true;
        this.admissionFetch(() => {
            this.fetch();
        });
        this.printOptionService.get((is) => { this.isPrintLogo = is; });

        this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
    }
}
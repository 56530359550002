
<div id="wrapper">
    <div class="navbar-custom" (mouseenter)="leave()">
        <div class="container-fluid" style="line-height: 70px">
            <ul class="list-unstyled topnav-menu m-0">
                <li class="d-lg-none">
                    <button class="button-menu-mobile waves-effect waves-light"
                            (click)="onToggleMenu($event)">
                        <i class="fe-menu"></i>
                    </button>
                </li>
            </ul>
            <div style="line-height: 16px; top: 50px; left: 12px"
                 class="position-absolute d-lg-none"
                 [ngClass]="{ 'd-none': sideSubMenu.length ==0 }">
                <a aria-expanded="true"
                   class="text-success"
                   aria-haspopup="true"
                   data-toggle="dropdown"
                   href="javascript:;"
                   id="menuThreeDot"
                   #threedot
                   (click)="onToggleSubMenu($event,threedot)"
                   placement="left" ngbTooltip="Actions">
                    <i class="mdi mdi-dots-horizontal mdi-36px"></i>
                </a>
            </div>
            <ul class="list-unstyled topnav-menu float-left mb-0">
                <li class="dropdown d-none d-lg-inline-block">
                    <div class="app-search app-search-custom">
                        <div class="app-search-box">
                            <div class="menu-search-p">
                                <input type="text"
                                       class="form-control menu-search"
                                       block
                                       autocomplete="off"
                                       #menuSearchInput
                                       [ngbTypeahead]="menuSearch"
                                       [resultFormatter]="formatMenuMatches"
                                       [resultTemplate]="tempalteMenuSearchResults"
                                       [inputFormatter]="formatMenuMatches"
                                       (focus)="menuSearchInput.value = ''"
                                       (selectItem)="onSelectMenu($event, menuSearchInput)"
                                       placeholder="Search Pages" />
                            </div>
                        </div>
                    </div>
                </li>
                <li></li>
                <li class="dropdown d-none d-md-inline-block header-info"
                    *ngIf="settingService.cubicles.length">
                    <ng-container *ngIf="settingService.cubicles.length">
                        <ng-container *ngFor="let item of settingService.cubicles">
                            <span class="mr-2 ml-2 header-info-div">
                                <i class="fe-box mr-2"></i>
                                <span class="text-capitalize" [textContent]="item.name"></span>
                            </span>
                        </ng-container>
                    </ng-container>
                </li>
                <li class="dropdown d-none d-md-inline-block header-info"
                    *ngIf="settingService.selectedConsultantDoctor && page.userAccount.roleId !== 3">
                    <ng-container *ngIf="settingService.cubicles.length">
                        <ng-container *ngFor="let item of settingService.cubicles">
                            <span class="header-info-div">
                                <i class="fe-user mr-2"></i>
                                <span class="text-capitalize"
                                      [textContent]="settingService.selectedConsultantDoctor.value"></span>
                            </span>
                        </ng-container>
                    </ng-container>
                </li>
            </ul>
            <ul class="list-unstyled topnav-menu float-right mb-0">
                <!--<li class="dropdown d-none d-lg-inline-block" *ngIf="publicIp">
                                          <span class="mr-2">
                                              Public:<span class="ml-1" [textContent]="publicIp"></span>
                                          </span>
                                      </li>
                                      <li class="dropdown d-none d-lg-inline-block" *ngIf="privateIp">
                                          <span class="mr-2">
                                              Private:<span class="ml-1" [textContent]="privateIp"></span>
                                          </span>
                                      </li>-->
                <li class="dropdown d-none d-md-inline-block"
                    *ngIf="page && page.userAccount && page.userAccount.locationName">
                    <span class="mr-2">
                        <i class="mdi mdi-checkbox-blank-circle text-danger"
                           *ngIf="communicationService && communicationService.hubConnection && communicationService.hubConnection.state && communicationService.hubConnection.state !== 'Connected'"></i>
                        <i class="mdi mdi-checkbox-blank-circle text-success"
                           *ngIf="communicationService && communicationService.hubConnection && communicationService.hubConnection.state && communicationService.hubConnection.state === 'Connected'"></i>
                        <!-- <span
                                          class="ml-2"
                                          *ngIf="communicationService && communicationService.hubConnection && communicationService.hubConnection.state"
                                          [textContent]="communicationService.hubConnection.state"
                                        ></span> -->
                    </span>
                </li>
                <li class="dropdown d-none d-md-inline-block"
                    *ngIf="page && page.userAccount && page.userAccount.locationName">
                    <span class="mr-2">
                        <i class="fe-map-pin mr-2"></i>
                        <span [textContent]="page.userAccount.locationName"></span>
                    </span>
                </li>
                <li class="dropdown d-none d-md-inline-block">
                    <div class="app-search">
                        <div class="app-search-box">
                            <div class="input-group patient-search">
                                <input type="text"
                                       class="form-control"
                                       block
                                       #searchPatient
                                       autocomplete="off"
                                       [ngbTypeahead]="search"
                                       [resultFormatter]="formatMatches"
                                       [resultTemplate]="tempalteSearchResults"
                                       [inputFormatter]="formatMatches"
                                       (focus)="searchPatient.value = ''"
                                       (selectItem)="onSelectPatient($event)"
                                       placeholder="Enter Name/Mobile/UMR No" />
                                <div class="input-group-append">
                                    <button *ngIf="searching"
                                            class="btn bg-primary"
                                            type="button"
                                            style="cursor: default !important">
                                        <span class="spinner-border text-white spinner-border-sm"></span>
                                    </button>
                                    <a *ngIf="!searching"
                                       class="btn bg-primary ml-0 center-all"
                                       placement="left" ngbTooltip="Patients"
                                       [routerLink]="['/app/patients']">
                                        <i class="fe-users"></i>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li class="dropdown notification-list">
                    <a class="nav-link dropdown-toggle waves-effect waves-light" (click)="onOpenWebAlert(templateWebAlerts)" href="javascript:;" role="button" aria-haspopup="false" aria-expanded="false">
                        <i class="fe-bell noti-icon"></i>
                        <span *ngIf="unreadCount" class="badge badge-danger rounded-circle noti-icon-badge" [textContent]="(unreadCount) > 99 ? '99+' : (unreadCount)"></span>
                    </a>
                </li>
                <li class="dropdown d-none d-lg-inline-block">
                    <full-screen></full-screen>
                </li>
                <!--<li class="dropdown notification-list">
                                          <a class="nav-link dropdown-toggle waves-effect waves-light" (click)="onNotificationToggle()" href="javascript:;" role="button" aria-haspopup="false" aria-expanded="false">
                                              <i class="fe-bell noti-icon"></i>
                                              <span class="badge badge-danger rounded-circle noti-icon-badge" [textContent]="notificationCount > 99 ? '99+' : notificationCount"></span>
                                          </a>
                                          <div class="dropdown-menu dropdown-menu-right dropdown-lg" [ngClass]="{'show':showTicket}">
                                              <div class="dropdown-item noti-title">
                                                  <h5 class="m-0">
                                                      Notifications
                                                  </h5>
                                              </div>
                                              <div class="noti-scroll">
                                                  <div class="dropdown-item notify-item" style="line-height:initial;" *ngIf="!loading && notifications && notifications.length === 0">
                                                      <div class="notify-icon">
                                                          <div class="avatar-sm rounded-circle bg-info text-hover">
                                                              <i class="fe-info font-33 avatar-title text-white"></i>
                                                          </div>
                                                      </div>
                                                      <p class="notify-details">Information</p>
                                                      <p class="text-muted mb-0 user-msg">There is nothing to show you right now.</p>
                                                  </div>
                                                  <div class="dropdown-item notify-item active" style="line-height:initial;" *ngIf="loading">
                                                      <div class="notify-icon">
                                                          <div class="avatar-sm rounded-circle bg-info text-hover">
                                                              <span style="vertical-align:middle !important" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                          </div>
                                                      </div>
                                                      <p class="notify-details">Information</p>
                                                      <p class="text-muted mb-0 user-msg">Please wait while loading..</p>
                                                  </div>
                                                  <div class="dropdown-item" *ngFor="let notification of notifications;index as i;" (click)="onNavigate(notification)">
                                                      <a href="javascript:void(0);" class="dropdown-item notify-item active" style="line-height:initial; padding-top:8px; padding-bottom:8px;">
                                                          <div class="notify-icon m-1">
                                                              <img *ngIf="notification.patientThumbnailUrl" [src]="safe(notification.patientThumbnailUrl)" [alt]="notification.patientName" class="img-fluid rounded-circle">
                                                              <span *ngIf="!notification.patientThumbnailUrl" class="avatar-title rounded-circle bg-info font-24 text-white"
                                                                    [textContent]="notification.patientName | initials"></span>
                                                          </div>
                                                          <span class="notify-details m-0" [textContent]="notification.patientName"></span>
                                                          <span *ngIf="notification.patientAge"><span>(<span [textContent]="notification.patientAge"></span>yrs)</span></span>
                                                          <p class="text-muted mb-0 user-msg">
                                                              <small>
                                                                  <span class="badge bg-primary text-white font-weight-lighter" style="padding:3px;" [textContent]="notification.moduleType"></span>
                                                                  <span class="d-block font-weight-bold"> {{notification.message}}</span>
                                                              </small>
                                                          </p>
                                                      </a>
                                                  </div>
                                              </div>
                                              <a [routerLink]="['/app/web-notification']" (click)="showTicket = false" style="line-height:initial;" class="dropdown-item text-center text-primary notify-item notify-all pb-1">
                                                  View all
                                                  <i class="fe-arrow-right"></i>
                                              </a>
                                          </div>
                                      </li>-->
                <li class="dropdown notification-list"
                    *ngIf="page && page.userAccount && page.userAccount.accountId > 0">
                    <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
                       data-bs-toggle="dropdown"
                       href="javascript:;"
                       role="button"
                       aria-haspopup="false"
                       aria-expanded="false">
                        <avatar-img *ngIf="!page.userAccount.thumbnailUrl"
                                    src="assets/images/doctor_male.jpg"
                                    [alt]="page.userAccount.fullName"
                                    cssClass="rounded-circle myicon"></avatar-img>
                        <avatar-img *ngIf="page.userAccount.thumbnailUrl"
                                    [src]="page.userAccount.thumbnailUrl"
                                    [alt]="page.userAccount.fullName"
                                    cssClass="rounded-circle myicon"></avatar-img>
                        <span class="pro-user-name ml-1">
                            <span [textContent]="page.userAccount.fullName"></span><i class="mdi mdi-chevron-down ml-2 mr-1"></i>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right profile-dropdown"
                         style="
            position: absolute;
            will-change: transform;
            top: 0px;
            left: 0px;
            transform: translate3d(24px, 65px, 0px);
            line-height: 20px;
          ">
                        <div class="dropdown-header noti-title">
                            <h6 class="m-0">Welcome <span>{{page.userAccount.roleName}}</span>!</h6>
                        </div>
                        <!-- For Removing Profile filed from the LayOut
                                          <a routerLinkActive="active"
                           href="javascript:;"
                           class="dropdown-item notify-item"
                           (click)="onProfile()">
                            <i class="fe-user"></i>
                            <span>Profile</span>
                        </a>-->
                        <a href="javascript:;" class="dropdown-item notify-item" (click)="fetchCubicles()">
                            <i class="fe-box"></i>
                            <span>Rooms</span>
                        </a>
                        <a href="javascript:;"
                           class="dropdown-item notify-item"
                           (click)="onOpenModal(templateChangePassword)">
                            <i class="fe-lock"></i>
                            <span>Change Password</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="javascript:;" class="dropdown-item notify-item" (click)="onLogout()">
                            <i class="fe-log-out"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </li>
            </ul>
            <!--<div class="logo-box">
                              <a href="javascript:;" class="logo logo-dark text-center">
                                  <span class="logo-sm">
                                      <img src="assets/images/icon.png" alt="Careaxes" height="22">
                                  </span>
                                  <span class="logo-lg">
                                      <img src="assets/images/logo.png" alt="Careaxes" height="25">
                                  </span>
                              </a>
                          </div>-->

            <div class="clearfix"></div>
        </div>
    </div>
    <div class="left-side-menu"
         (mouseleave)="removeWidth()"
         [ngClass]="{ 'w-345': menuHolder.subMenus.length && isHovering}">
        <div class="h-100">
            <div>
                <div class="sidebar-icon-menu h-100">
                    <a href="javascript:;" [routerLink]="defaultMenu" class="logo position-fixed">
                        <span>
                            <img [src]="logoBasics && logoBasics.imageUrl ? logoBasics.imageUrl : 'assets/images/logo-careaxes.png'"
                                 [hidden]="loading"
                                 alt="Careaxes"
                                 width="35" />
                        </span>
                    </a>
                    <nav class="nav flex-column"
                         (mouseleave)="leaveMenu()"
                         [ngClass]="{'layout-main-menu-active' : !isHovering, 'layout-main-menu-inactive': isHovering}">
                        <div (mouseenter)="enter(menu)"                            
                             routerLinkActive="menu-active"
                             class="pt-1 pb-1 menu-border"
                             [ngClass]="{'inactive-highlight' : isHovering && menuHolder.mainPage === menu.mainPage}"
                             *ngFor="let menu of menus; let i = index">
                            <a href="javascript:;"
                               class="nav-link m-auto position-relative"
                               [routerLink]="menu.url">
                                <span *ngIf="menu.hasMarker" class="menu-marker">
                                    <i class="mdi"
                                       [ngClass]="{'mdi-menu-right': !menu.isHovering, 'mdi-menu-down': menu.isHovering}"></i>
                                </span>
                                <div><i [class]="menu.iconClasses"></i></div>
                                <div class="text-white white-space-break very-small text-center text-capitalize"
                                     [textContent]="menu.displayName"></div>
                            </a>
                        </div>
                    </nav>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <ng-container *ngIf="menuHolder.subMenus.length">
            <div id="submenu"
                 (mouseleave)="leave()"
                 class="layout-sidebar-main-menu d-block mt-1 saideNavMt"
                 style="
        background-size: 200%;
        background-repeat: no-repeat;
        z-index: 10000;
        border-radius: 0 10px 10px 0;
      "
                 [ngClass]="menuHolder.subMenus.length ? 'fade-in':'fade-out'">
                <div id="two-col-menu" class="h-100">
                    <div class="twocolumn-menu-item d-block h-100">
                        <h5 class="menu-title mb-2 text-primary text-center flyout-menu">
                            <i class="mdi mdi-apps mdi-infor text-white font-18 mr-1"></i><span [textContent]="menuHolder.menuName"></span>
                        </h5>
                        <div class="overflow-auto" style="height: calc(100% - 52px)">
                            <ul class="nav flex-column encounter-menu pb-5">
                                <ng-container *ngFor="let menu of menuHolder.subMenus">
                                    <ng-container *ngIf="menuType.SubMenu === menu.menuTypeId">
                                        <li class="nav-item w-100">
                                            <a class="text-color text-truncate dropdown-item position-relative"
                                               (click)="leave()"
                                               [routerLink]="menu.url"
                                               [class.active]="activeRoute === menu.url"
                                               href="javascript:;">
                                                <i *ngIf="menu.iconClasses" [class]="'mr-1 ' + menu.iconClasses"></i>
                                                <span class="text-capitalize" [textContent]="menu.displayName"></span>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="menuType.CategoryMenu === menu.menuTypeId">
                                        <li class="nav-item category-menu w-100">
                                            <a href="javascript: void(0)"
                                               data-toggle="collapse"
                                               class="text-color dropdown-item text-truncate position-relative"
                                               aria-expanded="true">
                                                <i [class]="'mr-1 ' + menu.iconClasses"></i>
                                                <span [textContent]="menu.displayName"></span>
                                                <span class="menu-arrow"></span>
                                            </a>
                                            <div class="collapse show" [id]="'sidebarMultilevel' + '_' + menu.id">
                                                <ul class="nav-second-level1">
                                                    <li class="nav-item" *ngFor="let item of menu.categoryMenus">
                                                        <a class="text-color dropdown-item"
                                                           (click)="leave()"
                                                           [routerLink]="item.url"
                                                           [class.active]="activeRoute === item.url"
                                                           href="javascript:;">
                                                            <i [class]="'mr-1 ' + item.iconClasses"></i>
                                                            <span class="text-capitalize" [textContent]="item.displayName"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="content-page" id="content-page">
        <telemedicine-widget *ngIf="page.userAccount && page.userAccount.allowVideoCall"></telemedicine-widget>
        <session-timeout></session-timeout>
        <idle-timeout></idle-timeout>
        <router-outlet></router-outlet>
        <button class="btn btn-secondary btn-scroll-top btn-sm"
                style="z-index: 1000000"
                (click)="scrollToTop()">
            <i class="fe-arrow-up"></i>
        </button>
        <footer class="footer">
            <div class="container-fluid">
                <div class="d-flex heading1 justify-content-between p-1">
                    <div>
                        <script>
                            document.write(new Date().getFullYear());
                        </script>
                        &copy; Careaxes by
                        <a target="_blank" href="https://sujainfo.com/">Suja Info</a>
                    </div>
                    <div>
                        <span *ngIf="webVersion" [textContent]="'version : '+webVersion"></span>
                        <span ngbPopover="{{url}}"
                              triggers="mouseenter:mouseleave"
                              popoverTitle="Pointing URL"
                              class="ml-1">
                            <i class="mdi mdi-information"></i>
                        </span>
                    </div>
                    <div *ngIf="page && page.userAccount && page.userAccount.lastLoginDate">
                        <!--<div class="text-md-right d-none d-sm-block">
                                                                          Last Logged In <a href="javascript:;" [textContent]="page.userAccount.lastLoginDate | utcToLocal"></a>
                                                                      </div>-->
                        <div class="text-md-right d-sm-block">
                            Last Logged In
                            <a href="javascript:;"
                               [textContent]="page.userAccount.lastLoginDate | date:'dd MMMM y , h:mm a'"></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>

<div id="overlay" class="rightbar-overlay"></div>
<ng-template #templateChangePassword>
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="fe-lock mr-1"></i>Change Password</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    (click)="onCloseModal();">
                ×
            </button>
        </div>
        <div class="modal-body">
            <div class="alert alert-light bg-light text-dark font-13 border-0" role="alert">
                <span>Password must contain minimum of 4 characters.</span>
            </div>
            <div class="form-group mb-3">
                <label class="mb-1">New Password</label>
                <input type="password"
                       formControlName="password"
                       block
                       autocomplete="nope"
                       class="form-control"
                       [ngClass]="{ 'is-invalid': submitted && form.password.errors }"
                       placeholder="Your new password" />
                <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
                    <div *ngIf="form.password.errors.minLength">Invalid password</div>
                </div>
            </div>
            <div class="form-group">
                <label class="mb-1">Re-enter Password</label>
                <div class="input-group mb-0">
                    <input type="password"
                           formControlName="confirmPassword"
                           block
                           autocomplete="nope"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': submitted && form.confirmPassword.errors }"
                           placeholder="Your new password again" />
                    <div class="input-group-append cursor-pointer" password>
                        <div class="input-group-text">
                            <span class="password-eye"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="submitted && form.confirmPassword.errors" class="invalid-feedback show-must">
                    <div *ngIf="form.confirmPassword.errors.minLength || form.confirmPassword.errors.notEquivalent">
                        Passwords are not matched
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light mr-1" (click)="onCloseModal();">
                Cancel
            </button>
            <button type="submit" [disabled]="submitting" class="btn btn-sm btn-primary">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #tempalteSearchResults let-item="result" let-term="term" let-i="index">
    <div class="media" style="line-height: normal !important">
        <div class="avatar-xs mr-2">
            <!--<avatar-img *ngIf="item.optionalText" [src]="item.optionalText" [alt]="item.value" cssClass="img-fluid rounded-circle"></avatar-img>-->
            <!--<span *ngIf="!item.optionalText" class="avatar-title rounded-circle font-10 bg-soft-warning font-weight-bold text-warning" [textContent]="item.value | initials"></span>-->
            <span class="avatar-title rounded-circle font-10 bg-soft-primary font-weight-bold text-primary"
                  [textContent]="item.value | initials"></span>
        </div>
        <div class="media-body">
            <span class="badge badge-soft-primary text-capitalize">
                <ngb-highlight highlightClass="text-primary font-weight-bold"
                               [result]="item.value"
                               [term]="term"></ngb-highlight>
            </span>
            <br />
            <ngb-highlight highlightClass="text-primary font-weight-bold"
                           [result]="item.optionalText2"
                           [term]="term"></ngb-highlight>
            <span class="mx-1">
                <i class="mdi mdi-slash-forward"></i>
            </span>
            <ngb-highlight highlightClass="text-primary font-weight-bold"
                           [result]="item.optionalText1"
                           [term]="term"></ngb-highlight>
        </div>
    </div>
</ng-template>

<ng-template #tempalteMenuSearchResults let-item="result" let-term="term" let-i="index">
    <div class="media" style="line-height: normal !important">
        <div class="avatar-xs mr-2">
            <span class="avatar-title rounded-circle font-15 bg-soft-primary font-weight-bold text-primary">
                <i *ngIf="item.iconClasses" [class]="item.iconClasses"></i>
                <i *ngIf="!item.iconClasses" class="mdi mdi-help"></i>
            </span>
        </div>
        <div class="media-body">
            <span class="badge badge-soft-info">
                <ngb-highlight highlightClass="text-primary font-weight-bold"
                               [result]="item.mainPage"
                               [term]="term"></ngb-highlight>
            </span>
            <span *ngIf="item.subPage">
                <span class="mx-1">
                    <i class="mdi mdi-arrow-right-thin"></i>
                </span>
                <ngb-highlight highlightClass="text-primary font-weight-bold"
                               [result]="item.subPage"
                               [term]="term"></ngb-highlight>
            </span>
            <br />
            <ngb-highlight highlightClass="text-primary font-weight-bold"
                           [result]="item.displayName"
                           [term]="term"></ngb-highlight>
        </div>
    </div>
</ng-template>

<ng-template #templateModify>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-account-box-outline mr-1"></i>User Profile</h4>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                (click)="onCloseModal();">
            ×
        </button>
    </div>
    <form [formGroup]="userForm" (ngSubmit)="onProfileSubmit()">
        <div class="modal-body">
            <div *ngIf="userLoading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait loading while we load user profile.
            </div>
            <div class="row" *ngIf="!userLoading">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>First Name <code>*</code></label>
                                <input type="text"
                                       class="form-control"
                                       formControlName="firstName"
                                       placeholder="Enter first name"
                                       [ngClass]="{ 'is-invalid': submitted && profileForm.firstName.invalid }" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Last Name <code>*</code></label>
                                <input type="text"
                                       class="form-control"
                                       formControlName="lastName"
                                       placeholder="Enter last name"
                                       [ngClass]="{ 'is-invalid': submitted && profileForm.lastName.invalid }" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="mb-1">Role <code>*</code></label>
                                <select class="form-control custom-select"
                                        formControlName="roleId"
                                        [ngClass]="{ 'is-invalid': submitted && profileForm.roleId.errors }">
                                    <option [ngValue]="null">Select</option>
                                    <option [ngValue]="2">Administrator</option>
                                    <option [ngValue]="5">Support</option>
                                    <option [ngValue]="6">Nurse</option>
                                    <option [ngValue]="7">Receptionist</option>
                                    <option [ngValue]="8">Accountant</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="mb-1">Email address <code>*</code></label>
                                <input type="text"
                                       class="form-control"
                                       readonly
                                       formControlName="email"
                                       maxlength="150"
                                       block
                                       autocomplete="nope"
                                       [ngClass]="{ 'is-invalid': submitted && profileForm.email.errors }"
                                       placeholder="Enter email address" />
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-md-6">
                            <div class="form-group mb-0">
                                <label class="mb-1">Mobile number <code>*</code></label>
                                <input type="text"
                                       numbersOnly
                                       readonly
                                       class="form-control"
                                       formControlName="mobile"
                                       maxlength="10"
                                       block
                                       autocomplete="nope"
                                       [ngClass]="{ 'is-invalid': submitted && profileForm.mobile.errors }"
                                       placeholder="Enter mobile number" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-0">
                                <label class="mb-1">Country <code>*</code></label>
                                <input type="text" readonly formControlName="countryName" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-md-6">
                            <div class="form-group mb-0">
                                <label class="mb-1">Gender</label>
                                <select class="form-control custom-select" formControlName="gender">
                                    <option [ngValue]="null">Select</option>
                                    <option [ngValue]="'M'">Male</option>
                                    <option [ngValue]="'F'">Female</option>
                                    <option [ngValue]="'O'">Others</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 mb-0">
                            <div class="form-group position-relative">
                                <label class="mb-1">Date Of Birth</label>
                                <input class="form-control date-picker"
                                       type="text"
                                       placeholder="Select"
                                       formControlName="dateOfBirth"
                                       readonly
                                       autocomplete="nope"
                                       ngbDatepicker
                                       #dateOfBirth="ngbDatepicker"
                                       [maxDate]="today"
                                       [minDate]="minDate"
                                       (click)="dateOfBirth.toggle(); $event.stopPropagation();" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">
                Cancel
            </button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateCubicle>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fe-box mr-1"></i>Select Room</h4>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                (click)="onCloseModal();">
            ×
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="cubiclesLoading" class="d-flex justify-content-center align-items-center">
            <span class="spinner-grow spinner-grow-lg"></span> please wait while loading cubicles
        </div>
        <div class="d-flex justify-content-center"
             *ngIf="page.userAccount.roleId !== 3 && !cubiclesLoading">
            <div class="form-group text-center">
                <label class="mb-1">Select Consultant Doctor</label>
                <div *ngIf="settingService.selectedConsultantDoctor && !isAssigningDoctor">
                    <span class="text-capitalize font-weight-bold mr-2"
                          [textContent]="settingService.selectedConsultantDoctor.value"></span>
                    <span (click)="clearConsultantDoctor(select3)" class="cursor-pointer text-danger">
                        <i class="mdi mdi-close"></i>
                    </span>
                </div>
                <ng-container *ngIf="isAssigningDoctor">
                    <div class="d-flex justify-contant-center align-items-center my-2">
                        <span class="spinner-grow spinner-grow-lg"></span> please wait while assigning/un
                        assigning doctor
                    </div>
                </ng-container>
                <div [ngClass]="{'d-block': !settingService.selectedConsultantDoctor, 'd-none': settingService.selectedConsultantDoctor}">
                    <ng-select [items]="providers | async"
                               #select3
                               [loading]="loadingProviders"
                               bindLabel="value"
                               bindValue="id"
                               [typeahead]="providersInput"
                               typeToSearchText="Search provider"
                               [virtualScroll]="true"
                               placeholder="Enter provider name"
                               (change)="onProviderChange($event)"
                               class="cubible-search"
                               [(ngModel)]="providerId">
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            <div class="ng-option disabled" *ngIf="searchTerm">
                                No provider found for '{{ searchTerm }}'
                            </div>
                            <div class="ng-option disabled" *ngIf="!searchTerm">Search consultant doctor</div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-i="index">
                            <div class="media">
                                <div class="avatar-xs mr-1">
                                    <!--<img *ngIf="item.optionalText"
                                                                                             [src]="item.optionalText"
                                                                                             [alt]="item.value"
                                                                                             class="img-fluid rounded-circle" />-->
                                    <span *ngIf="!item.optionalText"
                                          class="avatar-title rounded-circle font-11 font-weight-bold text-white"
                                          avatar-bg
                                          [index]="i"
                                          [textContent]="item.value | initials"></span>
                                </div>
                                <div class="media-body">
                                    <h5 class="mb-0 mt-0 font-14 font-weight-normal" [textContent]="item.value"></h5>
                                    <h6 class="mb-0 mt-0 font-15 font-weight-normal"
                                        [textContent]="item.optionalText2"></h6>
                                    <h6 class="mb-0 mt-0 font-15 font-weight-normal"
                                        [textContent]="item.optionalText1"></h6>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                            <div class="media">
                                <div class="avatar-xs mr-1">
                                    <!--<img *ngIf="item.optionalText"
                                                                                             [src]="item.optionalText"
                                                                                             [alt]="item.value"
                                                                                             class="img-fluid rounded-circle" />-->
                                    <span *ngIf="!item.optionalText"
                                          class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-primary text-primary"
                                          [textContent]="item.value | initials"></span>
                                </div>
                                <div class="media-body">
                                    <h5 class="mb-0 mt-0 font-14 font-weight-normal" [textContent]="item.value"></h5>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div *ngIf="!cubiclesLoading" class="overflow-auto">
            <table class="table table-sm table-bordered table-striped">
                <tbody>
                    <tr *ngIf="currentAssignedCubicles.length" class="table-primary">
                        <th>Assigned Room</th>
                        <th class="text-right">Assigned To</th>
                        <th class="text-right">Action</th>
                    </tr>
                    <tr *ngFor="let item of currentAssignedCubicles">
                        <td [textContent]="item.name"></td>
                        <td class="text-right" [textContent]="item.assignedName"></td>
                        <td width="150" class="text-right">
                            <button class="btn btn-sm mb-0 btn-danger"
                                    *ngIf="(page.userAccount.roleId === 1 || page.userAccount.roleId === 5 || page.userAccount.roleId === 2 ||  page.userAccount.accountId === item.accountId) && !item.loading"
                                    (click)="onUnassign(item)">
                                <i class="mdi mdi-close mr-1"></i>
                                <span *ngIf="!item.loading">Remove</span>
                                <span *ngIf="item.loading">loading...</span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="currentAssignedCubicles.length > 0">
                        <td colspan="3">
                            <nav class="d-flex align-items-center justify-content-between">
                                <p class="mb-0 font-13"></p>
                                <ngb-pagination class="pagination justify-content-end"
                                                [maxSize]="5"
                                                [rotate]="true"
                                                [ellipses]="true"
                                                [(page)]="assignedCubiclespagination.pageIndex"
                                                [pageSize]="assignedCubiclespagination.pageSize"
                                                (pageChange)="onAssignedPageChange($event)"
                                                [collectionSize]="currentAssignedCubicles.length">
                                    <ng-template ngbPaginationPrevious>
                                        <i class="fe-arrow-left"></i>
                                    </ng-template>
                                    <ng-template ngbPaginationNext>
                                        <i class="fe-arrow-right"></i>
                                    </ng-template>
                                </ngb-pagination>
                            </nav>
                        </td>
                    </tr>

                    <tr *ngIf="currentUnAssignedCubicles.length" class="table-primary">
                        <th colspan="3">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>Unassigned Rooms</div>
                                <div>
                                    <ng-select class="ng-select-sm text-uppercase"
                                               [items]="unassignedCubicles"
                                               bindLabel="name"
                                               bindValue="cubicleId"
                                               autocomplete="nope"
                                               (change)="onUnAssignedCubicleSearch($event)"
                                               placeholder="Search unassigned cubilces">
                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                            <div class="ng-option disabled">
                                                No unassigned cubicles found for '{{searchTerm}}'
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let item of currentUnAssignedCubicles">
                        <td [textContent]="item.name"></td>
                        <td colspan="2" class="text-right">
                            <button class="btn btn-sm mb-0 btn-success" (click)="onAssign(item)">
                                <i class="mdi mdi-check mr-1"></i>
                                <span *ngIf="!item.loading">Select</span>
                                <span *ngIf="item.loading">loading...</span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="currentUnAssignedCubicles.length > 0 && unAssignedCubiclespaginationFlag">
                        <td colspan="3">
                            <nav class="d-flex align-items-center justify-content-between">
                                <p class="mb-0 font-13"></p>
                                <ngb-pagination class="pagination justify-content-end"
                                                [maxSize]="5"
                                                [rotate]="false"
                                                [ellipses]="false"
                                                [(page)]="unAssignedCubiclespagination.pageIndex"
                                                [pageSize]="unAssignedCubiclespagination.pageSize"
                                                (pageChange)="onUnAssignedPageChange($event)"
                                                [collectionSize]="unAssignedCubiclespagination.totalItems">
                                    <ng-template ngbPaginationPrevious>
                                        <i class="fe-arrow-left"></i>
                                    </ng-template>
                                    <ng-template ngbPaginationNext>
                                        <i class="fe-arrow-right"></i>
                                    </ng-template>
                                </ngb-pagination>
                            </nav>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Close</button>
    </div>
</ng-template>


<ng-template #templateWebAlerts>
    <div class="modal-header">
        <h4 class="modal-title">Alerts</h4>
        <div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 mb-1">
                <div class="row" *ngIf="loadingWebAlerts">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Please wait while loading your products ...</span>
                        </div>
                    </div>
                </div>
                <div class="dataTables_wrapper overflow-auto" style="height:400px">
                    <div class="row" *ngIf="!loadingWebAlerts && (!webAlerts || !webAlerts.length) && (!userAlerts || !userAlerts.length)">
                        <div class="col-12">
                            <div class="alert alert-warning">
                                <i class="mdi mdi-information-outline mr-1"></i>There is no Alerts for this user...!!
                            </div>
                        </div>
                    </div>
                    <div class="overflow-auto" *ngIf="userAlerts && userAlerts.length >0">
                        <table class="table table-hover table-bordered table-striped table-sm">
                            <thead class=" ">
                                <tr role="row">
                                    <th>Comments</th>
                                    <th>Details</th>
                                    <th>CreatedBy</th>
                                    <th>Created Date</th>
                                    <th>Actions</th>
                                    <th>Acknowledge Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of userAlerts; let i = index;">

                                    <ng-container *ngIf="item.isActionRequired!=false">
                                        <td [textContent]="item.comments"></td>
                                        <ng-container *ngIf="item.alertTypeCode=='admission_added'">
                                            <td [textContent]="(item.patientName | uppercase) + ' is admitted with Admission No: '+item.admissionNo"></td>
                                        </ng-container>
                                        <ng-container *ngIf="item.alertTypeCode=='new_born_baby_added'">
                                            <td [textContent]="(item.patientName | uppercase) +' with Admission No : '+item.admissionNo+' has registered their newly born baby'"></td>
                                        </ng-container>
                                        <ng-container *ngIf="item.alertTypeCode=='ip_issue_alert'">
                                            <td [textContent]="(item.patientName | uppercase) +' with Admission No : '+item.admissionNo+' has been issued '+item.productName+' medication'"></td>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!item.isActionRequired">
                                        <td colspan="2" [textContent]="item.comments"></td>
                                    </ng-container>
                                    <td [textContent]="item.createdByName"></td>
                                    <td>
                                        <div>
                                            <span [textContent]="item.createdDate | date: 'dd/MM/yyyy'"></span>
                                        </div>
                                        <div>
                                            <small [textContent]="item.createdDate | date:'hh:mm a'"></small>
                                        </div>
                                    </td>
                                    <ng-container *ngIf="item.isActionRequired!=false">
                                        <td>
                                            <div class="d-flex">
                                                <button *ngIf="item.acknowledgeBy == null && item.alertTypeCode != 'ip_issue_alert'" class="btn-primary" (click)="onUserAccept(item, index)">
                                                    <span *ngIf="!item.loading">Accept</span>
                                                    <span *ngIf="item.loading">
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        loading..
                                                    </span>
                                                </button>
                                                <button *ngIf="item.acknowledgeBy == null && item.alertTypeCode == 'ip_issue_alert'" class="btn-primary" (click)="onUserAccept(item, index)">
                                                    <span *ngIf="!item.loading">Approve</span>
                                                    <span *ngIf="item.loading">
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        loading..
                                                    </span>
                                                </button>
                                                <button *ngIf="item.acknowledgeBy == null && item.alertTypeCode == 'ip_issue_alert'" class="btn-primary" (click)="onUserReject(item, index)">
                                                    <span *ngIf="!item.loading">Reject</span>
                                                    <span *ngIf="item.loading">
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        loading..
                                                    </span>
                                                </button>
                                                <div *ngIf="item.acknowledgeBy!= null && item.alertTypeCode != 'ip_issue_alert'">
                                                    <span>
                                                        This Task has been accepted by <span class="text-black" [textContent]="item.acknowledgeByName"></span>
                                                    </span>
                                                </div>
                                                <div *ngIf="item.acknowledgeBy!= null && item.alertTypeCode == 'ip_issue_alert' && item.status==='A'">
                                                    <span>
                                                        This Task has been approved by <span class="text-black" [textContent]="item.acknowledgeByName"></span>
                                                    </span>
                                                </div>
                                                <div *ngIf="item.acknowledgeBy!= null && item.alertTypeCode == 'ip_issue_alert' && item.status==='R'">
                                                    <span>
                                                        This Task has been Rejected by <span class="text-black" [textContent]="item.acknowledgeByName"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <span [textContent]="item.acknowledgeDate | date: 'dd/MM/yyyy'"></span>
                                            </div>
                                            <div>
                                                <small [textContent]="item.acknowledgeDate | date:'hh:mm a'"></small>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!item.isActionRequired">
                                        <td colspan="2">Description of the Lab amendments</td>
                                    </ng-container>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="overflow-auto" *ngIf="webAlerts && webAlerts.length >0">
                        <table class="table table-hover table-bordered table-striped table-sm">
                            <thead class=" ">
                                <tr role="row">
                                    <th>Comments</th>
                                    <th>CreatedBy</th>
                                    <th>Created Date</th>
                                    <th>Actions</th>
                                    <th>Accepted Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of webAlerts; let i = index;">
                                    <td [textContent]="item.comments"></td>
                                    <td [textContent]="item.createdByName"></td>
                                    <td>
                                        <div>
                                            <span [textContent]="item.alertDate | date: 'dd/MM/yyyy'"></span>
                                        </div>
                                        <div>
                                            <small [textContent]="item.alertDate | date:'hh:mm a'"></small>
                                        </div>
                                    </td>
                                    <ng-container *ngIf="item.isActionRequired && item.alertTypeCode==='on_discharge_progress'">
                                        <td>
                                            <div class="d-flex">
                                                <button *ngIf="item.isActionRequired &&  !item.acceptedBy" class="btn-primary" (click)="onAccept(item, index)">
                                                    <span *ngIf="!item.loading">Accept</span>
                                                    <span *ngIf="item.loading">
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        loading..
                                                    </span>
                                                </button>
                                                <div *ngIf="item.acceptedBy">
                                                    <span>
                                                        This Task has been accepted by <span class="text-black" [textContent]="item.acceptedByName"></span>
                                                    </span>
                                                </div>&nbsp;

                                                <input *ngIf="item.isActionRequired && (item.acceptedBy == page.userAccount.accountId) && !item.isActionCompleted" class="form-control" type="text" [(ngModel)]="message" placeholder="Enter the reason for delay" #messageInput />
                                                <button *ngIf="item.isActionRequired && (item.acceptedBy == page.userAccount.accountId) && !item.isActionCompleted" class="btn-danger size" (click)="onClear(item)">
                                                    <span *ngIf="!item.loading">Clear</span>
                                                    <span *ngIf="item.loading">
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        loading..
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <span [textContent]="item.acceptedDate | date: 'dd/MM/yyyy'"></span>
                                            </div>
                                            <div>
                                                <small [textContent]="item.acceptedDate | date:'hh:mm a'"></small>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!item.isActionRequired  && item.alertTypeCode==='lab_amendments_modified'">
                                        <td colspan="3">
                                            Description of the Lab amendments
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!item.isActionRequired  && item.alertTypeCode==='payment_alert'">
                                        <td colspan="3">                                        
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="item.isActionRequired && item.alertTypeCode==='ip_issue_alert'">
                                        <td>
                                            <div class="d-flex">
                                                <button *ngIf="item.isActionRequired &&  !item.acceptedBy" class="btn-primary" (click)="onAccept(item, index)">
                                                    <span *ngIf="!item.loading">Accept</span>
                                                    <span *ngIf="item.loading">
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        loading..
                                                    </span>
                                                </button>
                                                <div *ngIf="item.acceptedBy">
                                                    <span>
                                                        This Task has been accepted by <span class="text-black" [textContent]="item.acceptedByName"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <span [textContent]="item.acceptedDate | date: 'dd/MM/yyyy'"></span>
                                            </div>
                                            <div>
                                                <small [textContent]="item.acceptedDate | date:'hh:mm a'"></small>
                                            </div>
                                        </td>
                                    </ng-container>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>

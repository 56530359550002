/* eslint-disable no-self-assign */
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { HttpService, AppData, EncounterCommunication, TimelineToggleService} from '../../services';
import { takeUntil, finalize } from 'rxjs/operators';
import { Page, IUserAccount, PatientProfile, IEncounterResource, ObEncounter } from '../../models';
import { Appointment, PreviousAppointment } from '../../entities';
import { ApiResources } from '../../helpers';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GYN } from '../../../areas/admin/gyn-encounter/gyn.namespace';
import { GynEncounter } from '../../models/gyn-encounter.model';
import { forkJoin } from "rxjs";
import { GYNType } from "@shared/enums";
import { OB } from '../../../areas/admin/full-transcript/pages/ob-report/ob.namespace';

@Component({
    selector: 'gyn-header',
    templateUrl: './gyn-header.html',
    styleUrls: ['./gyn-header.css'],
})
export class GynHeaderWidget implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    @Input() encounterType: string;
    gynHistory: GYN.NewGynHistory;
    partnerDetail: GYN.GyneacPartner;//
    page: Page;
    isAdmission: any;
    allergies: OB.Allergies;
    gynEncounter: GynEncounter;//
    gpla: IEncounterResource;
    gplaJson: GYN.NewGynHistory;
    appointments: any;
    oldAppointment: any;
    vitals: any;
    visitNo: number;
    encounterCommunicationSubscription: Subscription;
    appointment: Appointment;
    patient: PatientProfile;
    husbandName: string;
    gynCardGeneration: GYN.GynCardGeneration;
    specialFeature: OB.SpecialFeature;     
    roleId: number;
    measure: IEncounterResource;
    visitNumber: number;
    gynaecVisit: any;
    measureJson: GYN.Measures;
    sideNav: boolean;
    antinantalRisk: OB.AntinantalRisk;
    obEncounter: ObEncounter;
    name: string;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly router: Router,
        public timelineService: TimelineToggleService

    ) {
        this.page = new Page();

    }
   
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.findAncCard(this.appointment.patientId);
                this.findDashboard();
                setTimeout(() => {
                    //this.findVisitNo(this.appointment.patientId);

                }, 500)
                setTimeout(() => {
                    this.findAncCard(this.appointment.patientId);
                   
                }, 500)
            });
    }

    //private findVisitNo(id: any) {
    //    const request = {
    //        patientId: id
    //    };
    //    this.httpService.get<number>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findVisitNo), request)
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .pipe(finalize(() => this.page.loading = false))
    //        .subscribe((response: number) => {
    //            this.visitNo = response == 0 ? 1 : response;

    //        });
    //}
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                this.husbandName = this.patient.relativeDetails[0]?this.patient.relativeDetails[0].fullName:null;

            });
    }


    private findAncCard(id: number) {
        this.httpService.get(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchGynCard), { patientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: GYN.GynCardGeneration) => {
                if (response) {
                    this.gynCardGeneration = response;
                   // this.findDashboard();
                   this.findGynaecVisit(this.appointmentId);
                }
            });
    }
    private findGynaecVisit(appointmentId: string) {
        const request = { encryptedAppointmentId: appointmentId, isAdmission: this.isAdmission, type: GYNType.Measure }
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchGyn), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.measure = response;
                if (this.measure.jsonString) {
                    this.measureJson = JSON.parse(this.measure.jsonString);
                    //this.ancVisit = this.obEncounter.measure ? JSON.parse(this.obEncounter.measure) : null;
                    //if (this.ancVisit) {
                    //    this.visit = this.ancVisit && this.ancVisit.visitNumber ? this.ancVisit.visitNumber : this.visitNumber;
                    //}
                   // this.visitNo = this.measureJson && this.measureJson.visitNo ? parseInt(this.measureJson.visitNo) : this.visitNo;
                }
                else {
                   // this.visitNo = this.visitNo
                       
                }
            });

    }
    private findDashboard() {

        const request = {
            encryptedAppointmentId: this.appointmentId,
            encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            encryptedPatientId: this.patientId,
            isAdmission: this.isAdmission
        };
        request["isadmission"] = this.isAdmission;
        let history = this.httpService.post<[GynEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.findData), request)
        let gpla = this.httpService.post(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchCommonEncounter), request)
        forkJoin([history, gpla])
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<any>) => {
                this.gynEncounter = response[0]["dashboard"];

                this.appointments = response[0]["appointments"];

                this.oldAppointment = response[0]["oldappointment"];
                this.gpla = response[1];
                this.gplaJson = JSON.parse(this.gpla.jsonString);
                if (this.gynEncounter) {
                    this.gynHistory = this.gynEncounter.gynHistory ? JSON.parse(this.gynEncounter.gynHistory) : this.gplaJson;
                    this.partnerDetail = this.gynEncounter.gyneacPartner ? JSON.parse(this.gynEncounter.gyneacPartner) : null;
                    this.specialFeature = this.gynEncounter.specialFeature ? JSON.parse(this.gynEncounter.specialFeature) : null;
                    this.gynaecVisit = this.gynEncounter.gynaecVisit ? JSON.parse(this.gynEncounter.gynaecVisit) : null;
                    this.measureJson = this.gynEncounter.measure ? JSON.parse(this.gynEncounter.measure) : null;
                    this.allergies = this.gynEncounter.allergies ? JSON.parse(this.gynEncounter.allergies) : null;
                    this.antinantalRisk = this.gynEncounter.antinantalRisk ? JSON.parse(this.gynEncounter.antinantalRisk) : null;
                    if (this.measureJson) {
                        this.visitNo = this.measureJson && this.measureJson.visitNo ? parseInt(this.measureJson.visitNo) : this.visitNumber;
                    }
                }
            });

    }

    private findPatient() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.page.loading = true;
                const request = { encryptedAppointmentId: this.appointmentId };
                request["isAdmission"] = this.isAdmission;
                this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: GynEncounter) => {
                        this.patientId = response.encryptedPatientId;

                        this.findPatientDetails(response.encryptedPatientId);
                    });
            }
        }, 10);
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    const appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                     this.roleId = this.page.userAccount.roleId;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.encounterCommunicationSubscription = this.encounterCommunication.gynEncounter.subscribe(() => {
                        this.findPatient();
                        this.findAppointment(appointmentId);
                    });
                    this.name = this.page.userAccount.fullName;
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
        this.encounterCommunicationSubscription.unsubscribe();
    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
}
export class Notification {
    webNotificationId: number;
    webNotificationPriorityId: number;
    webNotificationLogTypeId: number;
    patientId?: number;
    referenceId?: number;
    patientAge?: number;

    message: string;
    redirectionLink: string;
    allowedRoles: string;
    allowedAccounts: string;
    priority: string;
    logType: string;
    patientName: string;
    patientGender: string;
    umrNo: string;
    patientThumbnailUrl: string;
    encryptedReferenceId: string;
    encryptedPatientId: string;
    patientMobile: string;

    createdDate: Date;

    isRead: boolean;

    webNotificationTypeId: number;
    moduleType: string;
    totalItems: number;

    total: number;
    Amount: number;
    modifiedBy: number;
    appointmentId: number;
    paymentNumber: string;

    appointmentTimeString: string;
    appointmentDate: Date;
    appointmentTime: object;

    tokenNumber?: number;
    specializationName: string;
    departmentId: string;
    departmentName: string;
    providerId: number;
    providerName: string;
    specializationId: number;
    appointmentNo: string;
    patientPaymentStatus?: boolean;
    practiceName: string;
    providerLocation: string;
    nextAppointment: string;
    lastConsultedDate: Date;
    lastConsultedTime: string;
    lastConsultedDoctor: string;
    lastPaidAmount: number;
    paymentStatus?: boolean;
    active?: boolean;
    hide: boolean;
    counsellingId: number;
}
import { Component, OnDestroy, OnInit, TemplateRef, Input, Output, EventEmitter } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { AppData, HttpService, NotifyService, ValidatorService, ResourceService } from "@shared/services";
import { WhiteSpaceValidator, uniqPharmacyProductValidator } from "@shared/validators";
import { takeUntil, finalize, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from "rxjs/operators";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Supplier, InventoryProduct, Company, PharmacyProductSubType, PharmacyProductType } from "@shared/entities";
import { Observable, of, forkJoin } from "rxjs";


interface DrugModel {
    drugName: string;
    companyName: string;
    packagingOfMedicines: string;
    medicineType: string;
    composition: string;
    alchoholInteraction: string;
    pregnancyInteraction: string;
    expertAdvice: string;
    commonSideEffects: string;
    medicineFaq: string;
    medicineInteraction: string;
    usage: string;
}

interface CategoryModel {
    saleUnit: number;
    saleCat: string;
    purchaseUnit: number;
    purchaseCat: string;
    category: string;
    companyName: string;
}

@Component({
    selector: "pharmacy-product",
    templateUrl: "./pharmacy-product.html"
})
export class PharmacyProductWidget implements OnInit, OnDestroy {
    @Input() productModel: any;
    @Input() productModify: any;
    @Input() fromPage: string;
    @Output() omit = new EventEmitter<any>();
    @Output() omitOnDirect = new EventEmitter<any>();

    page: Page;
    loading: boolean;
    modalRef: NgbModalRef;
    productForm: FormGroup;
    submitted: boolean;
    submitting: boolean;
    loadingTax: boolean;
    tax: Array<IResource>;
    loadingCategory: boolean;
    category: Array<IResource>;
    loadingRack: boolean;
    rack: Array<IResource>;
    loadingUnit: boolean;
    units: Array<IResource>;
    companies: Array<Company>;
    loadingCompany: boolean;
    imageSrc = "assets/images/pharmacy/pp-capsule.png";

    loadingSupplier: boolean;
    searchFailed: boolean;
    searching: boolean;
    suppliers: Array<Supplier>;
    source: string;
    numberArray = [1, 5, 10, 15, 20, 50, 80, 100, 150, 200, 250, 300, 350, 400, 450, 500];

    finalCategoryModel: CategoryModel;

    lookUpForm: FormGroup;
    companyForm: FormGroup;
    typeOf: string;
    unitOf: string;

    loadingType: boolean;
    productTypes: Array<PharmacyProductType>;
    displayProductTypes: Array<PharmacyProductType>;
    subType: Array<PharmacyProductSubType>;
    loadingSubType: boolean;
    loadingStorage: boolean;
    storage: Array<IResource>;
    loadingSchedule: boolean;
    schedule: Array<IResource>;
    drugRisks: Array<IResource>;
    nabhCategory: Array<IResource>;
    formulations: Array<IResource>;
    medRoutes: Array<IResource>;
    dosageTypes: Array<IResource>;
    frequencies: Array<IResource>;



    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly modalService: NgbModal,
        private readonly formBuilder: FormBuilder,
        public validatorService: ValidatorService,
        private readonly resourceService: ResourceService
    ) {
        this.page = new Page();
        this.suppliers = new Array<Supplier>();
        this.tax = new Array<IResource>();
        this.category = new Array<IResource>();
        this.rack = new Array<IResource>();
        this.units = new Array<IResource>();
        this.companies = new Array<Company>();
        this.productTypes = new Array<PharmacyProductType>();
        this.displayProductTypes = new Array<PharmacyProductType>();
        this.subType = new Array<PharmacyProductSubType>();
        this.storage = new Array<IResource>();
        this.schedule = new Array<IResource>();
        this.drugRisks = new Array<IResource>();
        this.nabhCategory = new Array<IResource>();
        this.formulations = new Array<IResource>();
        this.medRoutes = new Array<IResource>();
        this.dosageTypes = new Array<IResource>();
        this.frequencies = new Array<IResource>();
        this.buildForm();

    }

    checkFormType() {
        if (this.productModel && this.productModel.pharmacyProductId > 0) {
            this.updateForm(this.productModel);
        }
    }

    onOmitCloseModel() {
        this.omit.emit();
    }

    onOmitCloseModelPurchase(num: number) {
        this.omit.emit(num);
    }

    onDirectOmit() {
        this.omitOnDirect.emit();
    }

    onFetchLookUpMasters() {
        this.loadingTax = true;
        let hitAPi = new Array<any>();
        let taxAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "PharmacyGst" });
        hitAPi.push(taxAPI);
        let storageAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "StorageType" });
        hitAPi.push(storageAPI);
        let scheduleAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "ScheduleDrug" });
        hitAPi.push(scheduleAPI);
        let categoryAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "PharmacyCategory" });
        hitAPi.push(categoryAPI);
        let unitAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "PharmacyUnit" });
        hitAPi.push(unitAPI);
        let drugRiskAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "PharmacyDrugRisk" });
        hitAPi.push(drugRiskAPI);
        let nabhAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "NABHCategory" });
        hitAPi.push(nabhAPI);
        let dosageAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "PharmacyDosageType" });
        hitAPi.push(dosageAPI);
        let formulationAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "PharmacyFormulation" });
        hitAPi.push(formulationAPI);
        let medRouteAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), { name: "MedRoute" });
        hitAPi.push(medRouteAPI);

        let frequencyAPI = this.httpService.get(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.medicationFrequency), { name: "G" });
        hitAPi.push(frequencyAPI);

        forkJoin([...hitAPi])
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTax = false))
            .subscribe((response: Array<any>) => {

                this.tax = response[0] as Array<IResource>;
                this.storage = response[1] as Array<IResource>;
                this.schedule = response[2] as Array<IResource>;
                this.category = response[3] as Array<IResource>;
                this.units = response[4] as Array<IResource>;
                this.drugRisks = response[5] as Array<IResource>;
                this.nabhCategory = response[6] as Array<IResource>;
                this.dosageTypes = response[7] as Array<IResource>;
                this.formulations = response[8] as Array<IResource>;
                this.medRoutes = response[9] as Array<IResource>;
                this.frequencies = response[10] as Array<IResource>;
                this.category = this.category.filter((item) => {
                    if (item.value != "") {
                        return item;
                    }
                })
            }, () => { this.notifyService.warningToast("Unable to fetch master types."); });
    }

    private fetchFormulation(id?: number) {
        this.loadingCategory = true;
        this.resourceService.lookUpValueOnLookUpName("PharmacyFormulation")
            .pipe(finalize(() => { this.loadingCategory = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.formulations = response;
                if (UtilHelper.isEmpty(id)) {
                    const find = this.formulations.find(c => c.id === id);
                    this.productForm.patchValue({
                        formulationId: find.id
                    });
                }
            });
    }

    private fetchUnit(id?: number) {
        this.loadingUnit = true;
        this.resourceService.lookUpValueOnLookUpName("PharmacyUnit")
            .pipe(finalize(() => { this.loadingUnit = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.units = response;
                if (UtilHelper.isEmpty(id)) {
                    const find = this.units.find(c => c.id === id);
                    if (this.unitOf === "P") {
                        this.productForm.patchValue({
                            purchaseUnitName: find.value,
                            purchaseUnit: find.id
                        });
                    } else if (this.unitOf === "S") {
                        this.productForm.patchValue({
                            saleUnitName: find.value,
                            saleUnit: find.id
                        });
                    }
                }
            });
    }

    private fetchCompany(id?: number) {
        const model = {
            typeOf: "pharmacyCompany"
        }
        const request = model;
        this.loadingCompany = true;
        this.httpService.post(ApiResources.getURI(ApiResources.company.base, ApiResources.company.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingCompany = false;
            }))
            .subscribe((response: Array<Company>) => {
                this.companies = response;
                if (UtilHelper.isEmpty(id)) {
                    const find = this.companies.find(x => x.companyId === id);
                    this.productForm.patchValue({
                        companyId: find.companyId,
                        companyName: find.name
                    });
                }
            }, () => {
                this.companies = new Array<Company>();
            });
    }

    private fetchSuppliers() {
        const model = {
            supplierOf: "pharmacy"
        }
        const request = model;
        this.loadingSupplier = true;
        this.httpService.post(ApiResources.getURI(ApiResources.supplier.base, ApiResources.supplier.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingSupplier = false;
            }))
            .subscribe((response: Array<Supplier>) => {
                this.suppliers = response;
            }, () => {
                this.suppliers = new Array<Supplier>();
            });
    }

    private buildForm() {
        this.productForm = this.formBuilder.group({
            pharmacyProductId: 0,
            productName: [null, [Validators.required, WhiteSpaceValidator.isValid], [uniqPharmacyProductValidator(this.validatorService, "pharmacyProductId")]],
            companyId: [null, [Validators.required]],
            companyName: [null],
            categoryId: [null, [Validators.required]],
            categoryName: [null],
            supplierId: [null],
            taxId: [null, [Validators.required]],
            tax: [null],
            purchaseUnitName: [null],
            purchaseUnit: [null, [Validators.required]],
            purchaseUnitQty: [1],
            saleUnitName: [null],
            saleUnit: [null, [Validators.required]],
            saleUnitQty: [1],
            isProductExpire: [true],
            barcode: [null, [WhiteSpaceValidator.isValid]],
            genericName: [null, [WhiteSpaceValidator.isValid]],
            hsnCode: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            medicationId: [null, [WhiteSpaceValidator.isValid]],
            suppliers: [null],
            scheduledDrug: [null, [WhiteSpaceValidator.isValid]],
            isGeneralItem: [false, [Validators.required]],
            storageTypeId: [null],
            pharmacyProductSubTypeId: [null],
            pharmacyProductTypeId: [null, [Validators.required]],
            onlyConsumable: [false],
            saleLoose: [false],
            potency: [null, [WhiteSpaceValidator.isValid]],
            dosage: [null, [WhiteSpaceValidator.isValid]],
            strength: [null, [WhiteSpaceValidator.isValid]],
            formula: [null, [WhiteSpaceValidator.isValid]],
            drugRiskId: [null],
            nabhCategoryId: [null],
            dosageTypeId: [null],
            medFrequencyMasterId: [null],
            fixedDose: [null],
            noOfTimes: [null],
            formulationId: [null],
            medRouteId: [null],
            inventoryExpiry: [null],
            alchoholInteraction: [null],
            pregnancyInteraction: [null],
            expertAdvice: [null],
            commonSideEffects: [null],
            medicineFaq: [null],
            medicineInteraction: [null],
            usage: [null],
            inventoryItem: [false],
            consumable: [false],
            asset: [false],
            discount: [null],
            department:[null],
            groupName:[null],
            subGroupName:[null],
            categorysName:[null],
            subCategoryName:[null],
            requestType:[null]
        });
    }

    onChangeProductType(from: string, selected?: boolean) {
        
        const getHSNControl = this.productForm.controls["hsnCode"];
        const getTypeControl = this.productForm.controls["pharmacyProductTypeId"];

        if (this.productModify == false) {
            const preservedValues = {

                pharmacyProductTypeId: this.productForm.get('pharmacyProductTypeId').value,
                isGeneralItem: this.productForm.get('isGeneralItem').value,
                inventoryItem: this.productForm.get('inventoryItem').value
            };
            this.productForm.reset();
            this.productForm.patchValue(
                {
                    isGeneralItem: preservedValues.isGeneralItem,
                    inventoryItem: preservedValues.inventoryItem,
                    isProductExpire: true,
                    asset: false,
                    consumable: false,
                    saleLoose: false,
                    onlyConsumable: false,
                    pharmacyProductId: 0,
                   
                })
        }

        if (UtilHelper.isEmpty(selected) && !selected) {
            from = this.productForm.value.isGeneralItem ? "G" : "P";
        }

        switch (from) {
            case "P":
                getHSNControl.setValidators([Validators.required, WhiteSpaceValidator.isValid]);
                getHSNControl.updateValueAndValidity();
                this.onGeneralItemChange();
                break;
            case "G":
                getHSNControl.clearValidators();
                getHSNControl.updateValueAndValidity();
                this.onGeneralItemChange();
                break;
            case "I":
                getHSNControl.clearValidators();
                getHSNControl.updateValueAndValidity();
                getTypeControl.clearValidators();
                getTypeControl.updateValueAndValidity();
                break;
        }
    }

    updateForm(record: InventoryProduct) {
        let supplierIds = new Array<number>();
        if (UtilHelper.isEmpty(record.suppliers)) {
            let ids = record.suppliers.split(",");
            ids.forEach((id) => {
                supplierIds.push(parseInt(id));
            });
        }

        this.productForm.patchValue({
            pharmacyProductId: record.pharmacyProductId,
            productName: record.productName,
            companyId: record.companyId,
            companyName: record.companyName,
            categoryId: record.categoryId,
            categoryName: record.categoryName,
            supplierId: null,
            taxId: record.taxId,
            tax: record.tax,
            purchaseUnit: record.purchaseUnit,
            purchaseUnitName: record.purchaseUnitName,
            purchaseUnitQty: record.purchaseUnitQty,
            saleUnit: record.saleUnit,
            saleUnitName: record.saleUnitName,
            saleUnitQty: record.saleUnitQty,
            isProductExpire: record.isProductExpire,
            barcode: record.barcode,
            genericName: record.genericName,
            createdBy: this.page.userAccount.accountId,
            hsnCode: record.hsnCode,
            medicationId: record.medicationId,
            suppliers: supplierIds.length > 0 ? supplierIds : null,
            scheduledDrug: record.scheduledDrug,
            isGeneralItem: record.isGeneralItem,
            storageTypeId: record.storageTypeId,
            pharmacyProductSubTypeId: record.pharmacyProductSubTypeId,
            pharmacyProductTypeId: record.pharmacyProductTypeId,
            onlyConsumable: record.onlyConsumable,

            saleLoose: record.saleLoose,
            potency: record.potency,
            dosage: record.dosage,
            strength: record.strength,
            formula: record.formula,
            drugRiskId: record.drugRiskId,
            nabhCategoryId: record.nabhCategoryId,
            dosageTypeId: record.dosageTypeId,
            medFrequencyMasterId: record.medFrequencyMasterId,
            fixedDose: record.fixedDose,
            noOfTimes: record.noOfTimes,
            formulationId: record.formulationId,
            medRouteId: record.medRouteId,
            inventoryExpiry: record.inventoryExpiry,
            alchoholInteraction: record.alchoholInteraction,
            pregnancyInteraction: record.pregnancyInteraction,
            expertAdvice: record.expertAdvice,
            commonSideEffects: record.commonSideEffects,
            medicineFaq: record.medicineFaq,
            medicineInteraction: record.medicineInteraction,
            usage: record.usage,
            inventoryItem: record.inventoryItem,
            consumable: record.consumable,
            asset: record.asset,
            discount: record.discount,
            department: record.department,
            groupName: record.groupName,
            subGroupName: record.subGroupName,
            categorysName: record.categorysName,
            subCategoryName: record.subCategoryName,
            requestType:record.requestType
        });

        if (UtilHelper.isEmpty(record.pharmacyProductTypeId)) {
            this.fetchSubType(record.pharmacyProductTypeId);
        }

        if (record.inventoryItem) {
            this.onChangeProductType("I");
        } else if (record.isGeneralItem) {
            this.onChangeProductType("G");
        } else {
            this.onChangeProductType("P");
        }

        this.productForm.patchValue({
            pharmacyProductTypeId: record.pharmacyProductTypeId,
            pharmacyProductSubTypeId: record.pharmacyProductSubTypeId,
            productName: record.productName,
            genericName: record.genericName,
            taxId: record.taxId,
            categoryName: record.categoryName,
            categoryId: record.categoryId,
            companyId: record.companyId,
            hsnCode: record.hsnCode,
            companyName: record.companyName,
            purchaseUnit: record.purchaseUnit,
            purchaseUnitQty: record.purchaseUnitQty,         
        });
    }
    get form() { return this.productForm.controls }

    onSubmit() {
        this.submitted = true;

        if (!this.productForm.value.saleLoose as boolean) {
            this.productForm.patchValue({
                saleUnit: this.productForm.value.purchaseUnit,
                saleUnitName: this.productForm.value.purchaseUnitName,
                saleUnitQty: this.productForm.value.purchaseUnitQty
            });
        }

        if (this.productForm.invalid) {
            return;
        }
        this.submitting = true;
        const request = this.productForm.getRawValue();
        request.discount = request.discount !== null ? request.discount : 0;
        request["loginAccountId"] = this.page.userAccount.accountId;
        request["loginRoleId"] = this.page.userAccount.roleId;
        request["createdByName"] = this.page.userAccount.fullName;
        if (request["productName"].drugName) {
            request["productName"] = request["productName"].drugName;
        }
        // request["tax"] = request["tax"].toString();

        if (request["suppliers"] != undefined && request["suppliers"] != null) {
            request["suppliers"] = request["suppliers"].toString();
        }
        let url = request["pharmacyProductId"] > 0 ? ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.updateProduct) : ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.addProduct);

        this.httpService.post(url, request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitted = this.submitting = false;
            }))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast(`Product ${request["pharmacyProductId"] > 0 ? 'updated' : 'added'} successfully.`);
                    if (this.fromPage === "Purchase") {
                        this.onOmitCloseModelPurchase(response);
                    }
                    this.onOmitCloseModel();
                }
                if (response < 0) {
                    this.notifyService.warning("Product Name already exists.");
                }

            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warning(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    onOpenModel(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "sm",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            console.log(e);
        }
        this.submitted = this.submitting = false;
    }

    onSelectMedicine(event: NgbTypeaheadSelectItemEvent) {
        function startsWithNumber(str) {
            return /^\d/.test(str);
        }
        if (event.item) {
            const record = event.item as DrugModel;
            try {
                let type = record["packagingOfMedicines"].toLowerCase();
                let splitedRecord = type.split(" ");
                if (type.startsWith("strip")) {
                    this.finalCategoryModel = {
                        category: splitedRecord[3].toUpperCase(),
                        purchaseCat: splitedRecord[0].toUpperCase(),
                        saleCat: splitedRecord[3].toUpperCase(),
                        purchaseUnit: 1,
                        saleUnit: +splitedRecord[2],
                        companyName: record.companyName
                    };
                }
                else if (startsWithNumber(type) && (type.includes("tablet") || type.includes("tablets"))) {
                    this.finalCategoryModel = {
                        category: splitedRecord[1].toUpperCase(),
                        purchaseCat: splitedRecord[splitedRecord.length - 1].toUpperCase(),
                        saleCat: splitedRecord[1].toUpperCase(),
                        purchaseUnit: 1,
                        companyName: record.companyName,
                        saleUnit: +splitedRecord[0]
                    };
                } else {
                    this.finalCategoryModel = {
                        category: splitedRecord[splitedRecord.length - 1].toUpperCase(),
                        purchaseCat: record.medicineType.trim().toUpperCase(),
                        saleCat: record.medicineType.trim().toUpperCase(),
                        purchaseUnit: 1,
                        companyName: record.companyName,
                        saleUnit: 1
                    };
                }
                this.onAutoMedSelection();

            } catch (e) {
                // ignore
                console.log(e);
            }

            this.productForm.patchValue({
                genericName: record.composition,
                alchoholInteraction: record.alchoholInteraction,
                pregnancyInteraction: record.pregnancyInteraction,
                expertAdvice: record.expertAdvice,
                commonSideEffects: record.commonSideEffects,
                medicineFaq: record.medicineFaq,
                medicineInteraction: record.medicineInteraction,
                usage: record.usage
            });
        }
    }

    onAutoMedSelection() {
        if (this.finalCategoryModel) {
            this.productForm.patchValue({
                saleUnitQty: this.finalCategoryModel.saleUnit,
                purchaseUnitQty: this.finalCategoryModel.purchaseUnit
            });

            const getCompany = this.companies.find(c => c.name.toUpperCase() === this.finalCategoryModel.companyName.toUpperCase());
            if (!getCompany) {
                this.buildCompanyForm();
                this.companyForm.patchValue({
                    companyId: 0,
                    name: this.finalCategoryModel.companyName.toUpperCase(),
                    location: "Others",
                    typeOf: "pharmacyCompany"
                });

                this.onSubmitCompany();
            }
            else {
                this.productForm.patchValue({
                    companyId: getCompany.companyId,
                    companyName: getCompany.name
                });
            }

            const getCategory = this.formulations.find(c => c.value.toUpperCase() === this.finalCategoryModel.category.toUpperCase());
            if (!getCategory) {
                this.buildLookupForm();
                this.lookUpForm.patchValue({
                    lookupId: 0,
                    lookupValueId: 0,
                    name: this.finalCategoryModel.category,
                    typeOf: "PharmacyFormulation"
                });
                this.typeOf = "PharmacyFormulation";
                this.onSubmitLookup(this.typeOf);
            } else {
                this.productForm.patchValue({
                    formulationId: getCategory.id,
                });
            }
        }
    }

    formatMatches = (item: string | DrugModel) => typeof item === "object" ? item["drugName"] || "" : item || "";

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap((term: string) =>
                term.length < 2 ? of([]) : this.fetchDrugs(term)
                    .pipe(
                        tap(() => this.searchFailed = false),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        })
                    )
            ),
            tap(() => this.searching = false)
        );

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((account: IUserAccount) => {
                if (account) {
                    this.page.userAccount = account;
                    this.productForm.patchValue({
                        createdBy: this.page.userAccount.accountId,
                        modifiedBy: this.page.userAccount.accountId,
                    });
                  
                    this.checkFormType();
                    this.onFetchLookUpMasters();
                    this.fetchCompany();
                    this.fetchSuppliers();
                    this.fetchType();
                }
            });
    }

    ngOnDestroy() {
        if (this.modalRef) { this.onCloseModal(); }
        this.page.unsubscribeAll();
    }

    fetchDrugs(request?: any): Observable<Array<DrugModel>> {
        return this.httpService.get<Array<DrugModel>>(ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchDrugs), { term: request });
    }

    onOpenOtherFormModel(content: TemplateRef<any>, type: string, unitOf?: string) {
        switch (type) {
            case "U":
                this.unitOf = unitOf;
                this.typeOf = "Unit"
                this.buildLookupForm();
                break;
            case "C":
                this.typeOf = "Category";
                this.buildLookupForm();
                break;
            case "M":
                this.buildCompanyForm();
                break;

        }

        this.onOpenModel(content);

    }

    private buildCompanyForm() {
        this.companyForm = this.formBuilder.group({
            companyId: 0,
            name: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            location: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            typeOf: "pharmacyCompany"
        });
    }

    buildLookupForm() {
        this.submitted = false;
        this.lookUpForm = this.formBuilder.group({
            lookupId: 0,
            lookupValueId: 0,
            name: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            typeOf: this.typeOf
        });
    }

    get otherForms() {
        return {
            lookup: this.lookUpForm ? this.lookUpForm.controls : {},
            company: this.companyForm ? this.companyForm.controls : {}
        }
    }

    onGeneralItemChange() {
        this.displayProductTypes = new Array<PharmacyProductType>();
        const value = this.productForm.value.isGeneralItem || false;
        this.displayProductTypes = this.productTypes.filter(x => x.isGeneralItem === value);
    }

    onSubmitLookup(direct?: string) {
        this.submitted = true;
        if (this.lookUpForm.invalid) {
            return;
        }

        this.submitting = true;
        const request = this.lookUpForm.getRawValue();

        request["loginAccountId"] = this.page.userAccount.accountId;
        request["loginRoleId"] = this.page.userAccount.roleId;
        request["typeName"] = this.typeOf;

        this.httpService
            .post(ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.addCommonMasters), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe(
                (response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast(`${this.typeOf} added successfully.`);
                        this.onFetchLookUpMasters()
                        this.onCloseModal();
                        switch (this.typeOf) {
                            case "Unit":
                                this.fetchUnit(response);
                                break;
                            case "PharmacyFormulation":
                                this.fetchFormulation(response);
                                break;

                        }

                        switch (direct) {
                            case "Unit":
                                this.fetchUnit(response);
                                break;
                            case "PharmacyFormulation":
                                this.fetchFormulation(response);
                                break;

                        }
                    }
                    if (response < 0) {
                        this.notifyService.warningToast("The given name already exists.");
                    }
                },
                (error: HttpErrorResponse) => {
                    if (error) {
                        this.notifyService.warningToast(error.error)
                    }
                    else {
                        this.notifyService.errorToast("Error occured in creating units");
                    }
                }
            );
    }

    onSubmitCompany() {
        this.submitted = true;
        if (!this.companyForm.valid) {
            return;
        }
        this.submitting = true;
        const request = this.companyForm.getRawValue();
        request["loginAccountId"] = this.page.userAccount.accountId;
        request["loginRoleId"] = this.page.userAccount.roleId;
        request["createdBy"] = this.page.userAccount.accountId;

        this.httpService.post(ApiResources.getURI(ApiResources.company.base, ApiResources.company.add), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.submitted = this.submitting = false;
            }))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Manufacturer added successfully.");
                }
                if (response === -1) {
                    this.notifyService.warningToast("Manufacturer name already exists.");
                    return;
                }

                this.onCloseModal();
                this.fetchCompany(response);
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warningToast(errorMessage);
                } else {
                    this.notifyService.defaultErrorToast();
                }
            });

    }

    private fetchType() {
        this.loadingType = true;

        const request = {};
        this.httpService
            .post(ApiResources.getURI(ApiResources.pharmacyStore.base, ApiResources.pharmacyStore.fetchProductType), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingType = false))
            .subscribe(
                (response: Array<PharmacyProductType>) => {
                    this.productTypes = response;
                    this.onGeneralItemChange();
                },
                () => {
                    this.productTypes = new Array<PharmacyProductType>();
                    this.notifyService.defaultErrorToast();
                }
            );

    }

    onFetchTypeChange(item?: PharmacyProductType) {
        if (item) {
            this.fetchSubType(item.pharmacyProductTypeId);
        } else {
            this.subType = new Array<PharmacyProductSubType>();
            this.productForm.patchValue({
                pharmacyProductSubTypeId: null
            });
        }
    }

    private fetchSubType(id: number) {
        this.loadingSubType = true;

        const request = {
            pharmacyProductSubTypeId: id
        };
        this.httpService
            .post(ApiResources.getURI(ApiResources.pharmacyStore.base, ApiResources.pharmacyStore.fetchProductSubType), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSubType = false))
            .subscribe(
                (response: Array<PharmacyProductSubType>) => {
                    this.subType = response;    
                },
                () => {
                    this.subType = new Array<PharmacyProductSubType>();
                    this.notifyService.defaultErrorToast();
                }
            );

    }
}
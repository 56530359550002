import { LookUpValue } from "../../../../../shared/entities";

export class LabParameterMethod {
    labParameterMethodId: number;
    methodName: string;
}
export class AntibioticTagMaster {
    name: string;
    antibioticTagMasterId: number;
    antiBioticOne: string;
    tagsAntibiotic: any;
    lookups:any
}
import { LabMainDetailTemplate } from 'src/app/areas/admin/labs/pages/models/lab-detail-model';

export class LabTransferModel {
    newLabBookingHeaderId: number;
    patientId?: number;
    doctorId?: number;
    employeeId?: number;
    locationId: number;
    age?: number;
    requisitionNumber: string;
    patientName: string;
    mobile: string;
    doctorName: string;
    employeeName: string;
    umrNo: string;
    gender: string;
    createdDate: Date;
    approvedDate: Date;
    bookedDate: Date;
    totalItems: number;
    testName: string;
    testCode: string;

    transferNumber: string;
    transferedBy: number;
    transferedDate: Date;
    receivedBy?: number;
    receivedDate?: Date;
    collectionDate?: Date;
    barcodeDate?: Date;
    labMainDetailId: number;
    transferByName: string;
    recievedByName: string;
    status: string;
    sampleCollectedByName: string;
    barcodeGeneratedByName: string;
    fromLocation: string;
    transferedToLocation: string;
    isBarcodeGenerated: boolean;
    encryptedId: string;
    newLabBookingDetailId?: number;
    dateOfBirth?: Date;
    rowColor: string;
    approved?: boolean;
    assignedDoctor: string;
    createdByName: string;
    comment: string;
    isSelected: boolean;
    createdBy: number;
    transferredTemperature?: number;
    receivedTemperature?: number;
    holdComments: string;
    transferredTemperatureComments: string;
    receivedTemperatureComments: string;
    departmentName: string;
    sampleCollectionLocation: string;
    technicianComment: string;
    technicianName: string;
    technicianVerificationDate?: Date;
    emergency: boolean;
    bookingType: string;

    labSampleCollectionDetailId?: number;
    subSampleCollectedBy?: number;
    subCollectionDate?: Date;
    subBarcodeDate?: Date;
    subIsBarcodeGenerated: boolean;
    subBarcodeGeneratedBy?: number;
    sampleName: string;
    subSampleCollectedByName: string;
    subBarcodeGeneratedByName: string;
    subSampleCollectionLocation: string;
    noOfSamplesCollectText: string;
    noOfSamplesCollect: number;
    subStatus: string;
    appointmentId: number;
    encounterType: string;
    encryptedAppointmentId: string;
    transferedLocation: string;
    paymentType: string;
    sampleUsage: string;
    active: boolean;
    roleId?: number;
    assignDoctorRequired?: boolean;
    nablRequired?: boolean;
    isNablRequired?: boolean;
    paymentStatus?: boolean;
    subSampleCollectedByRole?: string;
    transferByRole?: string;
    recievedByRole?: string;
    sampleCollectedByRole?: string;
    messageCount: number;
    labDepartmentId: number;
    isExternalLab: boolean;
    isInternalLab: boolean;
    amount?: number;
    encryptedNewLabBookingDetailId: string;
    sampleID: string;
    isAbnormal: boolean;
    consentFormUrl: string;
    quickReport: boolean;
    fromLocationId: number;
    templateCount?: number;
    templates: Array<LabMainDetailTemplate>;
    encryptedLabMainDetailId: string;
    disabled: boolean;
    constructor() {
        this.templates = new Array<LabMainDetailTemplate>();
    }
}


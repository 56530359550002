export class RetailIssueIndentTrackReport {
  productName: string;
  genericName: string;
   quantity: number;
   locationId?: number;
    pharmacyDepartmentId?: number;
    createdDate?: Date;
    fromDate?: string;
    toDate?: string;
    approvedDate?: string;
    status: string;
    fullName?: string;
    indentDate: Date;
    issuedDate: Date;
    indentQuantity: number;
    issueQuantity: number;
    issuedBy: string;
    indentRaisedBy: string;
    Name?: string;
    issueNumber: string;
    retailPharmacyId?:number;
    retailName?:string;
    departmentName?:string;
    indentFor:string;
}

<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border d-flex justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">
            <div class="d-flex headerHeight">
                <button class="align-items-center d-flex m-1 p-0 rounded-circle encounterBtn width30px height30px" (click)="toggleSideNav()">
                    <i [ngClass]="{'d-none':sideNav}" class="mdi mdi-chevron-left font-26"></i><i [ngClass]="{'d-none':!sideNav}" class="mdi d-none mdi-chevron-right font-26"></i>
                </button>
            </div>
            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="textColorEncounter font-17 text-uppercase cursor-default" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
            </div>

            <div>
                <span class="heading-color1 font-weight-bold" *ngIf="patient">
                    <span class="font-17">Ip No:{{patient.patient.admissionId}}</span>&nbsp;
                </span>
                <span class="heading-color1 font-weight-bold" *ngIf="appointment">
                    <span class="font-17">Room Name:{{appointment.roomName}}</span>
                </span>
            </div>
            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment && appointment.providerName!==null">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="textColorEncounter font-17 text-uppercase cursor-default">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.admTime}}</span></h4>
            </div>
        </div>
    </div>
    <div class="d-flex pt-1 pb-1">
        <div class="border d-flex encounterWedgets flex-grow-1 mx-1" *ngIf=" roleId != 63 && roleId != 82 ">
            <div class="align-items-center justify-content-center d-flex w-100-px w-75p" *ngIf="!antinantalRisk">
                <img class="w-75" src="../../../../assets/images/timelineSvg/riskfactor-c1.svg" />
            </div>
            <div class="flex-grow-1 m-1" *ngIf="!antinantalRisk">
                <h5 class="heading-color1 mb-0" *ngIf="!antinantalRisk">Risk Factor:</h5>
                <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                    <div *ngIf="!antinantalRisk">
                        <span class="line-height-20px white-space-nowrap heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1 overflow-auto" *ngIf="antinantalRisk && antinantalRisk !== null &&  roleId != 63 && roleId != 82 ">
                <div class="d-flex justify-content-between align-items-center p-2px pl-0">
                    <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 mx-1 white-space-nowrap">Risk Factor</h5>
                    <!--<button class="btn text-primary d-block line-height-1px font-12 px-1 text-center"><i class=" mdi mdi-arrow-expand-vertical"></i> See more</button>-->
                </div>
                <div class="encounterWedgetsContent pr-1">
                    <div>
                        <div class="d-flex align-items-baseline font-weight-medium w-100">
                            <h6 class="white-space-nowrap heading-color2 m-0 mb-1 d-inline line-height-1 mx-1">Antinantal Risk :</h6>
                            <div class="w-100 mb-0 dashboard-break font-14" *ngIf="antinantalRisk &&( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)">
                                <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                    <span class="pr-1" *ngFor="let item of antinantalRisk.maternalMedicalHistory" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.uterineRiskFactor" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.pregnancyComplications" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.fetusComplications" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.characteristics" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.details" [textContent]="item+','"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex align-items-baseline font-weight-medium w-100">
                            <h6 class="white-space-nowrap heading-color2 m-0 mb-1 d-inline line-height-1 mx-1">SGA Screening   : </h6>
                            <div class="w-100 mb-0 dashboard-break font-14" *ngIf="antinantalRisk && ( antinantalRisk.maternalmedical.length >  0|| antinantalRisk.comments.length > 0 || antinantalRisk.covidInfection.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.latePragnancy.length > 0 || antinantalRisk.meternall.length > 0 || antinantalRisk.noRisk.length  > 0 || antinantalRisk.previousPregency.length  > 0 )">
                                <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                    <span class="pr-1" *ngFor="let item of antinantalRisk.maternalmedical" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.comments" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.covidInfection" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.currentPragnancy" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.funtalFetaus" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.currentPragnancy" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.latePragnancy" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.maternall" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.noRisk" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.previousPregency" [textContent]="item+','"></span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex align-items-baseline font-weight-medium w-100">
                            <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">VTE Risk         : </h6>
                            <div class="w-100 mb-0 dashboard-break font-14" *ngIf="antinantalRisk && (antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0  )">
                                <div style="column-gap: 35px;" class="line-height-1 mb-0 text-black">
                                    <span class="pr-1" *ngFor="let item of antinantalRisk.highRisk" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.intermediateRisk" [textContent]="item+','"></span>

                                    <span class="pr-1" *ngFor="let item of antinantalRisk.lowRisk" [textContent]="item+','"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4" *ngIf="specialFeature && specialFeature.specialFeature && roleId != 63 && roleId != 82">
            <div class="d-flex border encounterWedgets pr-1">
                <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                    <img class="w-75" src="../../../../assets/images/timelineSvg/special-features-c1.svg" />
                </div>
                <div class="flex-grow-1" *ngIf="!specialFeature.specialFeature">
                    <h5 class="heading-color2 mb-0">Special Features:</h5>
                </div>
                <div class="flex-grow-1" *ngIf="specialFeature.specialFeature">
                    <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 white-space-nowrap">Special Features:</h5>
                    <div class="encounterWedgetsContent pr-1 text-black font-14" [textContent]="specialFeature.specialFeature"></div>

                </div>
            </div>
        </div>
    </div>
    </div>





